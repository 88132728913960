import { invariant } from "~/lib/invariant";
import type { MapPanel } from "../../panels";
import { getMapSubVisualization, usePairedTopics } from "../../panels";

export function useMapSubVisualization(panel: MapPanel): "gps" | "cartesian" {
  const pairedTopics = usePairedTopics(panel);

  let subVisualization: "gps" | "cartesian" | null = null;
  for (const { topic } of pairedTopics) {
    const currentSubVisualization = getMapSubVisualization(topic.typeName);

    if (subVisualization == null) {
      subVisualization = currentSubVisualization;
    } else {
      invariant(
        subVisualization === currentSubVisualization,
        "Encountered panel with mixed map sub-visualization types",
      );
    }
  }

  invariant(subVisualization != null, "Expected to find a sub-visualization");

  return subVisualization;
}
