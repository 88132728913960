import type React from "react";
import {
  FormHelperText,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import type { TaggingMode } from "~/domain/logs";
import { usePlayerActions, useTaggingMode } from "~/player";
import { TimestampSelectionForm } from "../../components/timestamp-selection";

export function TaggingModeControls() {
  const taggingMode = useTaggingMode();

  const playerActions = usePlayerActions();

  function handleTaggingModeChange(
    _: unknown,
    newTaggingMode: TaggingMode | null,
  ): void {
    if (newTaggingMode === null) {
      return;
    }

    playerActions.changeTaggingMode(newTaggingMode);
  }

  return (
    <Stack sx={{ marginBlockEnd: 3 }}>
      <Typography id="tagging-mode-title" gutterBottom>
        Tagging Mode
      </Typography>
      <ToggleButtonGroup
        aria-labelledby="tagging-mode-title"
        aria-describedby="tagging-mode-desc"
        fullWidth
        color="primary"
        size="small"
        exclusive
        value={taggingMode}
        onChange={handleTaggingModeChange}
      >
        <ToggleButton value="log">Log</ToggleButton>
        <ToggleButton value="instant">Instant</ToggleButton>
        <ToggleButton value="range">Range</ToggleButton>
      </ToggleButtonGroup>
      <FormHelperText id="tagging-mode-desc" sx={{ marginBlockEnd: 2 }}>
        {taggingMode === "log" && "Tag entire log"}
        {taggingMode === "instant" && "Tag instant in log"}
        {taggingMode === "range" && "Tag time range in log"}
      </FormHelperText>
      {taggingMode !== "log" && <TimestampSelectionForm mode={taggingMode} />}
    </Stack>
  );
}
