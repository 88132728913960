import { secondsToNanoseconds } from "~/lib/dates";
import type { TimeRange } from "~/types";
import type { TimestepValue } from "../types";
import { Timestep } from "../types";

const windowSizeLookup = {
  [Timestep.Second]: secondsToNanoseconds(30),
  [Timestep.HalfSecond]: secondsToNanoseconds(15),
  [Timestep.Decisecond]: secondsToNanoseconds(3),
};

export function getWindowSizeForTimestep(timestep: TimestepValue): bigint {
  return windowSizeLookup[timestep];
}

export function calculateWindowTicks(
  recordWindow: TimeRange,
  requestedIntervalLength: bigint,
  requestedWindowSize: bigint,
  playbackStartTime: bigint,
): Array<number> {
  const relativeWindow: TimeRange = {
    startTime: recordWindow.startTime - playbackStartTime,
    endTime: recordWindow.endTime - playbackStartTime,
  };

  const ticks = new Array<number>();
  if (relativeWindow.endTime - relativeWindow.startTime < requestedWindowSize) {
    // The record window's size is less than the requested window size,
    // indicating the playback bounds are less than the requested size. In this
    // case there won't be a sliding window effect since the window will always
    // cover the same data, so just calculate some static ticks.

    const tickCount = requestedWindowSize / requestedIntervalLength;
    const intervalLength =
      (relativeWindow.endTime - relativeWindow.startTime) / tickCount;

    for (
      let tick = relativeWindow.startTime + intervalLength;
      tick < relativeWindow.endTime;
      tick += intervalLength
    ) {
      ticks.push(Number(tick));
    }
  } else {
    // The record window's size equals the request window size, so the playback
    // bounds are at least as long as the requested window size. In this case
    // the record window will slide along as playback progresses, so the ticks
    // should be fixed to points in time, not positions within the window.

    // Floor to a multiple of the interval size
    let firstTick =
      (relativeWindow.startTime / requestedIntervalLength) *
      requestedIntervalLength;
    if (firstTick < relativeWindow.startTime) {
      firstTick += requestedIntervalLength;
    }

    for (
      let tick = firstTick;
      tick < relativeWindow.endTime;
      tick += requestedIntervalLength
    ) {
      ticks.push(Number(tick));
    }
  }

  return ticks;
}
