import type { ValueOf } from "type-fest";

export const VisualizationType = {
  Timeline: "timeline",
  Chart: "chart",
  Image: "image",
  Map: "map",
  ThreeD: "three-d",
} as const;
export type VisualizationType = ValueOf<typeof VisualizationType>;

export const SplitOrientation = {
  Horizontal: "horizontal",
  Vertical: "vertical",
} as const;
export type SplitOrientation = ValueOf<typeof SplitOrientation>;

export const RotationDirection = {
  Left: "left",
  Right: "right",
} as const;
export type RotationDirection = ValueOf<typeof RotationDirection>;

export const FlipDirection = {
  X: "x",
  Y: "y",
} as const;
export type FlipDirection = ValueOf<typeof FlipDirection>;

export const ClipInsetSide = {
  Top: "top",
  Right: "right",
  Bottom: "bottom",
  Left: "left",
} as const;
export type ClipInsetSide = ValueOf<typeof ClipInsetSide>;
