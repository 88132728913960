import React from "react";
import {
  Flip,
  NotInterested,
  Rotate90DegreesCcw,
  Rotate90DegreesCw,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  MenuItem,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { formatPercent } from "~/format";
import { usePlayerActions } from "../../../actions";
import type { ImagePanel } from "../../../panels";
import {
  constraints,
  FlipDirection,
  RotationDirection,
  usePairedTopics,
} from "../../../panels";

export function ImageTransformControls({ panel }: { panel: ImagePanel }) {
  const playerActions = usePlayerActions();

  const [{ descriptor: topicDescriptor }] = usePairedTopics(panel);

  function handleBrightnessAdjustment(
    _: unknown,
    value: number | Array<number>,
  ): void {
    playerActions.adjustImageBrightness(panel, value as number);
  }

  function handleContrastAdjustment(
    _: unknown,
    value: number | Array<number>,
  ): void {
    playerActions.adjustImageContrast(panel, value as number);
  }

  function handleToggleColorization(e: React.ChangeEvent<HTMLInputElement>) {
    playerActions.toggleImageColorization(panel, e.target.checked);
  }

  function createRotationHandler(direction: RotationDirection) {
    return function handleRotate() {
      playerActions.rotateImage(panel, direction);
    };
  }

  function handleChangeFlipDirection(e: React.ChangeEvent<HTMLInputElement>) {
    const flipDirection =
      e.target.value === "null" ? null : (e.target.value as FlipDirection);

    playerActions.flipImage(panel, flipDirection);
  }

  return (
    <>
      <Typography variant="h6" component="p" sx={{ mb: 2 }}>
        Image Effects
      </Typography>
      <Typography>Rotate</Typography>
      <ButtonGroup
        fullWidth
        disableElevation
        variant="contained"
        color="primary"
      >
        <Button
          startIcon={<Rotate90DegreesCcw />}
          onClick={createRotationHandler(RotationDirection.Left)}
        >
          Left
        </Button>
        <Button
          startIcon={<Rotate90DegreesCw />}
          onClick={createRotationHandler(RotationDirection.Right)}
        >
          Right
        </Button>
      </ButtonGroup>
      <TextField
        sx={{
          my: 2,
          "& .MuiStack-root": {
            alignItems: "center",
            gap: 0.5,
          },
        }}
        select
        SelectProps={{
          MenuProps: {
            sx: {
              "& .MuiStack-root": {
                alignItems: "center",
                gap: 0.5,
              },
            },
          },
        }}
        fullWidth
        label="Flip"
        value={String(topicDescriptor.flip)}
        onChange={handleChangeFlipDirection}
      >
        <MenuItem value="null">
          <Stack direction="row">
            <NotInterested />
            None
          </Stack>
        </MenuItem>
        <MenuItem value={FlipDirection.X}>
          <Stack direction="row">
            <Flip />
            Horizontal
          </Stack>
        </MenuItem>
        <MenuItem value={FlipDirection.Y}>
          <Stack direction="row">
            <Flip sx={{ rotate: "90deg" }} />
            Vertical
          </Stack>
        </MenuItem>
      </TextField>
      <Typography>Brightness</Typography>
      <Slider
        sx={{
          alignSelf: "center",
          width: (theme) => `calc(100% - ${theme.spacing(2.5)})`,
        }}
        min={constraints.imageBrightnessPct.min}
        max={constraints.imageBrightnessPct.max}
        step={constraints.imageBrightnessPct.step}
        value={topicDescriptor.brightnessPct}
        onChange={handleBrightnessAdjustment}
        valueLabelDisplay="auto"
        valueLabelFormat={formatPercent}
      />
      <Typography>Contrast</Typography>
      <Slider
        sx={{
          alignSelf: "center",
          width: (theme) => `calc(100% - ${theme.spacing(2.5)})`,
        }}
        min={constraints.imageContrastPct.min}
        max={constraints.imageContrastPct.max}
        step={constraints.imageContrastPct.step}
        value={topicDescriptor.contrastPct}
        onChange={handleContrastAdjustment}
        valueLabelDisplay="auto"
        valueLabelFormat={formatPercent}
      />
      <FormControlLabel
        sx={{ justifyContent: "space-between", ml: 0 }}
        control={
          <Switch
            checked={topicDescriptor.colorize}
            onChange={handleToggleColorization}
          />
        }
        label="Colorize"
        labelPlacement="start"
      />
    </>
  );
}
