import * as z from "zod";
import { v } from "~/domain/versioning";
import { invariant } from "~/lib/invariant";
import { assertNever } from "~/utils/assertions";
import { SplitOrientation, VisualizationType } from "../constants";
import { imageFlipDirectionSchema, imageRotationSchema } from "./common";
import * as legacy from "./models.legacy";
import type * as sdkTypes from "./types";
import { createMigratableSchema, versionSchema } from "./utils";

export const V_0_0_0 = v(0, 0, 0);

const panelNodeDescriptorSchema: z.ZodType<sdkTypes.PanelNodeDescriptor> =
  z.object({
    id: z.number(),
    size: z.number(),
    panelId: z.number(),
  });

const containerNodeDescriptorSchema: z.ZodType<sdkTypes.ContainerNodeDescriptor> =
  z.object({
    id: z.number(),
    size: z.number(),
    orientation: z.nativeEnum(SplitOrientation),
    children: z.tuple([
      z.lazy(() => layoutNodeDescriptorSchema),
      z.lazy(() => layoutNodeDescriptorSchema),
    ]),
  });

const layoutNodeDescriptorSchema: z.ZodType<sdkTypes.LayoutNodeDescriptor> =
  z.union([panelNodeDescriptorSchema, containerNodeDescriptorSchema]);

const baseTopicDescriptorSchema = z.object({
  name: z.string(),
});

const basePanelDescriptorSchema = z.object({
  id: z.number(),
});

type BasePanelDescriptor_0_0_0 = z.infer<typeof basePanelDescriptorSchema>;

const uninitializedPanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(null),
  topics: z.tuple([]),
});

const timelineTopicDescriptorSchema = baseTopicDescriptorSchema;

const timelinePanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(VisualizationType.Timeline),
  topics: z.tuple([timelineTopicDescriptorSchema]),
});

const chartTopicDescriptorSchema = baseTopicDescriptorSchema.extend({
  fields: z.array(z.string()).max(3),
});

const chartPanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(VisualizationType.Chart),
  topics: z.tuple([chartTopicDescriptorSchema]),
});

const imageTopicDescriptorSchema = baseTopicDescriptorSchema.extend({
  colorize: z.boolean(),
  brightnessPct: z.number().min(0).max(5),
  contrastPct: z.number().min(0).max(2),
  rotationDeg: imageRotationSchema,
  flip: imageFlipDirectionSchema.nullable(),
});

const imagePanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(VisualizationType.Image),
  topics: z.tuple([imageTopicDescriptorSchema]),
});

const mapTopicDescriptorSchema = baseTopicDescriptorSchema;

const mapPanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(VisualizationType.Map),
  topics: z.union([
    z.tuple([mapTopicDescriptorSchema]),
    z.tuple([mapTopicDescriptorSchema, mapTopicDescriptorSchema]),
  ]),
});

const pointCloudTopicDescriptorSchema = baseTopicDescriptorSchema.extend({
  size: z.number().min(0).max(0.1),
});

const threeDPanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(VisualizationType.ThreeD),
  topics: z.tuple([pointCloudTopicDescriptorSchema]),
});

const panelDescriptorSchema = z.union([
  uninitializedPanelDescriptorSchema,
  timelinePanelDescriptorSchema,
  chartPanelDescriptorSchema,
  imagePanelDescriptorSchema,
  mapPanelDescriptorSchema,
  threeDPanelDescriptorSchema,
]);

type PanelDescriptorSchema_0_0_0 = z.infer<typeof panelDescriptorSchema>;

const baseLayoutProfileDescriptorSchema = z.object({
  name: z.string(),
  version: versionSchema(V_0_0_0),
  layout: layoutNodeDescriptorSchema,
  panels: z.array(panelDescriptorSchema),
});

export type LayoutProfileDescriptor_0_0_0 = z.infer<
  typeof baseLayoutProfileDescriptorSchema
>;

export const layoutProfileDescriptorSchema_0_0_0 = createMigratableSchema({
  next: baseLayoutProfileDescriptorSchema,
  previous: legacy.storedLayoutProfileSchema,
  migrate(value) {
    const panels = new Array<PanelDescriptorSchema_0_0_0>();
    const layout = deserializeLayoutProfileNode(value.layout, panels);

    return {
      name: value.name,
      version: V_0_0_0.toString(),
      layout,
      panels,
    };
  },
});

function deserializeLayoutProfileNode(
  node: legacy.StoredLayoutNode,
  panels: Array<PanelDescriptorSchema_0_0_0>,
  parentId: number | null = null,
): sdkTypes.LayoutNodeDescriptor {
  invariant(
    node.parentId === parentId,
    `Node's recorded parent ID (${node.parentId}) doesn't match structural parent ID (${parentId})`,
  );

  if (node.type === "panel") {
    const { state } = node;

    let panel: PanelDescriptorSchema_0_0_0;
    if (state == null) {
      panel = {
        id: node.id,
        visualization: null,
        topics: [],
      };
    } else {
      const basePanel: BasePanelDescriptor_0_0_0 = {
        id: node.id,
      };

      const { tab: visualization } = state;

      switch (visualization) {
        case VisualizationType.Timeline: {
          panel = {
            ...basePanel,
            visualization,
            topics: [{ name: state.name }],
          };

          break;
        }
        case VisualizationType.Chart: {
          panel = {
            ...basePanel,
            visualization,
            topics: [{ name: state.name, fields: state.fields }],
          };

          break;
        }
        case VisualizationType.Image: {
          panel = {
            ...basePanel,
            visualization,
            topics: [
              {
                name: state.name,
                colorize: state.colorizeImage,
                brightnessPct: state.imageBrightnessPct,
                contrastPct: state.imageContrastPct,
                rotationDeg: state.imageRotationDeg,
                flip: state.imageFlipDirection,
              },
            ],
          };

          break;
        }
        case VisualizationType.Map: {
          panel = {
            ...basePanel,
            visualization,
            topics:
              state.supplementaryMapTopics.length > 0
                ? [
                    { name: state.name },
                    { name: state.supplementaryMapTopics[0] },
                  ]
                : [{ name: state.name }],
          };

          break;
        }
        case VisualizationType.ThreeD: {
          panel = {
            ...basePanel,
            visualization,
            topics: [{ name: state.name, size: state.pointCloudPointSize }],
          };

          break;
        }
        default: {
          assertNever(visualization);
        }
      }
    }

    panels.push(panel);

    return {
      id: node.id,
      size: node.flex,
      panelId: node.id,
    };
  } else {
    return {
      id: node.id,
      size: node.flex,
      orientation: node.orientation,
      children: [
        deserializeLayoutProfileNode(node.firstChild, panels, node.id),
        deserializeLayoutProfileNode(node.secondChild, panels, node.id),
      ],
    };
  }
}
