import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Cancel,
  CheckCircle,
  Clear,
  ExpandMore,
  KeyboardArrowRight,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionSummary,
  accordionSummaryClasses,
  Alert,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import * as z from "zod";
import { BreakableText } from "~/components/BreakableText";
import { TextField, useStudioForm } from "~/components/Form";
import { optionalObject, requiredText, requiredUuid } from "~/domain/common";
import { SidebarHeader, useLayoutStateContext } from "~/layout";
import { invariant } from "~/lib/invariant";
import { capitalize, find, sortBy } from "~/lib/std";
import type { Log, Topic } from "~/lqs";
import {
  ProcessType,
  useDefaultWorkflowId,
  WorkflowContextField,
  WorkflowSelect,
} from "~/lqs";
import {
  TopicTree,
  useFormatPlaybackTimestamp,
  usePlaybackSource,
  usePlayerActions,
  usePlayerParams,
  usePlayerTopics,
} from "~/player";
import { assertNever, getEventHandlerProps } from "~/utils";
import { TimestampSelectionForm } from "../../components/timestamp-selection";
import { useCreateDigestion } from "../../queries";
import type { DraftDigestionTopic } from "../../types";
import { DigestionListSection } from "./digestion-list-section";
import { useDigestionStateContext } from "./digestion-state-provider";
import { chooseNamingOption } from "./naming-utils";
import type { DraftDigestion } from "./use-draft-digestion";
import {
  draftSelectedTopics,
  listDigestions,
  removeDraftTopic,
  resetDraft,
  selectLayoutTopics,
  setSelectedTopics,
  showNewDigestion,
} from "./use-draft-digestion";

const defaultFormValues = {
  name: null,
  workflowId: null,
  workflowContext: null,
};

export function DigestionSidebar() {
  const {
    draftDigestion,
    naming,
    globalDisabled,
    createDigestion,
    workflowId,
    workflowIdFieldDisabled,
    form,
    completionStatuses,
    allSectionsCompleted,
    getSectionProps,
  } = useDigestionStateContext();

  const { logId } = usePlayerParams();

  const topicsQuery = usePlayerTopics();

  const playbackSource = usePlaybackSource();
  const playerActions = usePlayerActions();

  const loading = playbackSource.isLoading || !topicsQuery.isSuccess;
  const areRangeEndsEqual =
    !playbackSource.isLoading &&
    playbackSource.range.startTime === playbackSource.range.endTime;
  const hasSelectedTopics = draftDigestion.selectedTopicIds.length > 0;

  const listDigestionsHandlerProps = getEventHandlerProps(
    "onClick",
    !globalDisabled &&
      !loading &&
      draftDigestion.view === "edit" &&
      function handleListDigestions(): void {
        draftDigestion.dispatch(listDigestions());
      },
  );

  const selectLayoutTopicsHandlerProps = getEventHandlerProps(
    "onClick",
    !globalDisabled &&
      !loading &&
      !playbackSource.inRangeMode &&
      draftDigestion.canSelectLayoutTopics &&
      function handleSelectLayoutTopics() {
        draftDigestion.dispatch(selectLayoutTopics());
      },
  );

  // Must be memoized for <TopicTree />
  const { dispatch: draftDigestionDispatch } = draftDigestion;
  const { exitPlaybackRangeMode } = playerActions;
  const topicSelectionHandlerProps = useMemo(
    () =>
      getEventHandlerProps(
        "onSelect",
        !globalDisabled &&
          !loading &&
          function handleTopicSelection(newSelection: ReadonlyArray<Topic>) {
            if (playbackSource.inRangeMode && newSelection.length === 0) {
              exitPlaybackRangeMode();
            }
            draftDigestionDispatch(setSelectedTopics({ topics: newSelection }));
          },
      ),
    [
      globalDisabled,
      loading,
      playbackSource.inRangeMode,
      exitPlaybackRangeMode,
      draftDigestionDispatch,
    ],
  );

  const timeRangeSelectionHandlerProps = getEventHandlerProps(
    "onClick",
    !globalDisabled &&
      !loading &&
      hasSelectedTopics &&
      function handleSelectTimeRange(): void {
        playerActions.enterPlaybackRangeMode();
      },
  );

  const timeRangeConfirmationHandlerProps = getEventHandlerProps(
    "onClick",
    !globalDisabled &&
      !loading &&
      playbackSource.inRangeMode &&
      !areRangeEndsEqual &&
      hasSelectedTopics &&
      function handleConfirmTimeRange(): void {
        playerActions.exitPlaybackRangeMode();
        draftDigestion.dispatch(draftSelectedTopics());
      },
  );

  const cancelTimeRangeSelectionHandlerProps = getEventHandlerProps(
    "onClick",
    !globalDisabled &&
      !loading &&
      playbackSource.inRangeMode &&
      hasSelectedTopics &&
      function handleCancelTimeRangeSelection(): void {
        playerActions.exitPlaybackRangeMode();
      },
  );

  const createDigestionHandlerProps = getEventHandlerProps(
    "onClick",
    !globalDisabled &&
      !loading &&
      !playbackSource.inRangeMode &&
      allSectionsCompleted &&
      !createDigestion.isSuccess &&
      function handleCreateDigestion(): void {
        form.handleSubmit();
      },
  );

  const showNewDigestionHandlerProps = getEventHandlerProps(
    "onShow",
    !globalDisabled &&
      createDigestion.isSuccess &&
      function handleShowNewDigestion(): void {
        draftDigestion.dispatch(showNewDigestion());

        createDigestion.reset();
        form.reset(defaultFormValues);
      },
  );

  const resetHandlerProps = getEventHandlerProps(
    "onClick",
    !globalDisabled &&
      createDigestion.isSuccess &&
      function handleReset() {
        draftDigestion.dispatch(resetDraft());

        createDigestion.reset();
        form.reset(defaultFormValues);
      },
  );

  // Must be memoized for <TopicTree />
  const selectedTopics = useMemo(
    () =>
      topicsQuery.data?.filter((topic) =>
        draftDigestion.selectedTopicIds.includes(topic.id),
      ) ?? [],
    [topicsQuery.data, draftDigestion.selectedTopicIds],
  );

  switch (draftDigestion.view) {
    case "edit": {
      return (
        <>
          <SidebarHeader
            title={chooseNamingOption(naming, {
              digestion: "Create a Digestion",
              extraction: "Create an Extraction",
            })}
          />
          {renderOptionalAlert(logId, naming)}
          <Button
            color="primary"
            variant="text"
            fullWidth
            endIcon={<KeyboardArrowRight />}
            sx={{ mb: 2, justifyContent: "space-between" }}
            {...listDigestionsHandlerProps}
          >
            View your {naming}s
          </Button>
          <Stack>
            <DigestionSection title="Name" {...getSectionProps("name")}>
              <TextField
                control={form.control}
                name="name"
                required
                disabled={globalDisabled}
              />
            </DigestionSection>
            <DigestionSection title="Workflow" {...getSectionProps("workflow")}>
              <Stack spacing={2}>
                <WorkflowSelect
                  control={form.control}
                  name="workflowId"
                  required
                  disabled={globalDisabled || workflowIdFieldDisabled}
                  processType={ProcessType.Digestion}
                />
                <WorkflowContextField
                  key={workflowId}
                  workflowId={workflowId}
                  processType={ProcessType.Digestion}
                  control={form.control}
                  name="workflowContext"
                />
              </Stack>
            </DigestionSection>
            <DigestionSection title="Topics" {...getSectionProps("topics")}>
              <Stack spacing={5}>
                <div>
                  <SectionSubheader>Select Topics</SectionSubheader>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    {...selectLayoutTopicsHandlerProps}
                    sx={{ mb: 2 }}
                  >
                    Select Layout Topics
                  </Button>
                  <TopicTree
                    // Once topics are fetched, `topicsQuery.data` will always be
                    // used and TanStack Query memoizes query data so this won't
                    // break the tree's inner memoization when it matters, i.e. when
                    // there are actually topics to display
                    topics={topicsQuery.data ?? []}
                    multiple
                    selected={selectedTopics}
                    {...topicSelectionHandlerProps}
                  />
                </div>
                <div>
                  <SectionSubheader>Time Range</SectionSubheader>
                  {playbackSource.inRangeMode ? (
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        disableElevation
                        {...timeRangeConfirmationHandlerProps}
                      >
                        Confirm Selection
                      </Button>
                      <Button
                        color="primary"
                        variant="text"
                        fullWidth
                        {...cancelTimeRangeSelectionHandlerProps}
                        sx={{ my: 1 }}
                      >
                        Cancel
                      </Button>
                      {areRangeEndsEqual && (
                        <Typography>
                          Time range edges cannot be equal
                        </Typography>
                      )}
                      <Typography sx={{ marginBlockEnd: 2 }}>
                        Selected time range:
                      </Typography>
                      <TimestampSelectionForm mode="range" />
                    </>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        disableElevation
                        {...timeRangeSelectionHandlerProps}
                      >
                        Select Time Range
                      </Button>
                      {!hasSelectedTopics && (
                        <Typography variant="body2">
                          Select topics to select a time range
                        </Typography>
                      )}
                    </>
                  )}
                </div>
                <DraftDigestionList
                  draftDigestionTopics={draftDigestion.topics}
                  dispatch={draftDigestion.dispatch}
                />
              </Stack>
            </DigestionSection>
            <DigestionSection title="Summary" {...getSectionProps("summary")}>
              <SectionSubheader>Sections Completed</SectionSubheader>
              <List disablePadding>
                <ListItem>
                  <ListItemIcon>
                    {renderStatusIcon(completionStatuses.name)}
                  </ListItemIcon>
                  <ListItemText>Name</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {renderStatusIcon(completionStatuses.workflow)}
                  </ListItemIcon>
                  <ListItemText>Workflow</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {renderStatusIcon(completionStatuses.topics)}
                  </ListItemIcon>
                  <ListItemText>Topics</ListItemText>
                </ListItem>
              </List>
              <Stack sx={{ mt: 2, mb: 4 }}>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  fullWidth
                  disableElevation
                  loading={createDigestion.isLoading}
                  {...createDigestionHandlerProps}
                >
                  Create {capitalize(naming)}
                </LoadingButton>
                {!allSectionsCompleted && (
                  <Typography variant="body2">
                    Complete all sections to create{" "}
                    {chooseNamingOption(naming, {
                      digestion: "a digestion",
                      extraction: "an extraction",
                    })}
                  </Typography>
                )}
              </Stack>
              {createDigestion.isError && (
                <Alert severity="error" variant="filled">
                  Failed to create the {naming}
                </Alert>
              )}
              {createDigestion.isSuccess && (
                <>
                  <SuccessAlert
                    naming={naming}
                    onShow={showNewDigestionHandlerProps.onShow}
                  />
                  <Button
                    sx={{ mt: 2 }}
                    color="primary"
                    variant="text"
                    fullWidth
                    {...resetHandlerProps}
                  >
                    Start New {capitalize(naming)}
                  </Button>
                </>
              )}
            </DigestionSection>
          </Stack>
        </>
      );
    }
    case "list": {
      return <DigestionListSection />;
    }
    default: {
      assertNever(draftDigestion.view);
    }
  }
}

function SuccessAlert({
  naming,
  onShow,
}: {
  naming: "digestion" | "extraction";
  onShow: () => void;
}) {
  return (
    <Alert
      severity="success"
      variant="filled"
      action={
        <Button color="inherit" size="small" onClick={onShow}>
          Show
        </Button>
      }
    >
      {capitalize(naming)} Created
    </Alert>
  );
}

function DraftDigestionList({
  disableRemove = false,
  draftDigestionTopics,
  dispatch,
}: {
  disableRemove?: boolean;
  draftDigestionTopics: DraftDigestionTopic[];
  dispatch: DraftDigestion["dispatch"];
}) {
  const topicsQuery = usePlayerTopics();
  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  function makeRemoveDraftTopicHandler(draftTopic: DraftDigestionTopic) {
    return function handleRemoveDraftTopic() {
      dispatch(removeDraftTopic({ topic: draftTopic }));
    };
  }

  return (
    <div>
      <SectionSubheader>Added Topics</SectionSubheader>
      {draftDigestionTopics.length === 0 ? (
        <Typography>No topics added yet</Typography>
      ) : (
        <List disablePadding>
          {sortBy(
            Array.from(groupDrafts(draftDigestionTopics).entries()),
            "0",
          ).map(([topicId, drafts]) => {
            const topic = find(topicsQuery.data, { id: topicId });

            invariant(topic !== undefined, "Topic not found");

            return (
              <ListItem
                key={topicId}
                disablePadding
                sx={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <ListItemText>
                  <BreakableText separator={/(\/)/}>{topic.name}</BreakableText>
                </ListItemText>
                <List disablePadding sx={{ alignSelf: "stretch" }}>
                  {sortBy(drafts, "startTime").map((draft) => (
                    <ListItem
                      key={draft.startTime}
                      dense
                      secondaryAction={
                        disableRemove ? undefined : (
                          <Tooltip title="Remove">
                            <IconButton
                              onClick={makeRemoveDraftTopicHandler(draft)}
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    >
                      <ListItemText>
                        {formatPlaybackTimestamp(draft.startTime)}
                        {" - "}
                        {formatPlaybackTimestamp(draft.endTime)}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            );
          })}
        </List>
      )}
    </div>
  );
}

function groupDrafts(
  drafts: ReadonlyArray<DraftDigestionTopic>,
): Map<DraftDigestionTopic["topicId"], Array<DraftDigestionTopic>> {
  const draftMap = new Map<
    DraftDigestionTopic["topicId"],
    Array<DraftDigestionTopic>
  >();

  drafts.forEach((draft) => {
    if (!draftMap.has(draft.topicId)) {
      draftMap.set(draft.topicId, []);
    }

    draftMap.get(draft.topicId)!.push(draft);
  });

  return draftMap;
}

function renderOptionalAlert(
  logId: Log["id"] | null,
  naming: "digestion" | "extraction",
) {
  if (logId === null) {
    return (
      <Alert severity="info" variant="filled" sx={{ mb: 2 }}>
        Choose a log to create{" "}
        {chooseNamingOption(naming, {
          digestion: "a digestion",
          extraction: "an extraction",
        })}
      </Alert>
    );
  }
}

type SectionName = "name" | "workflow" | "topics" | "summary";

type SectionCompletionStatus = "incomplete" | "complete" | "error";

// TODO: Merge this with useDraftDigestion?
export function useDigestionFinalizer(
  draftDigestionTopics: ReadonlyArray<DraftDigestionTopic>,
  naming: "digestion" | "extraction",
) {
  const { logId } = usePlayerParams();

  const globalDisabled = logId == null;

  const { currentSidebarId } = useLayoutStateContext();

  const [expandedSections, setExpandedSections] = useState<
    Record<SectionName, boolean>
  >({
    name: true,
    workflow: true,
    topics: true,
    summary: true,
  });

  function createExpansionChangeHandler(
    section: SectionName,
  ): (expanded: boolean) => void {
    return function handleExpansionChange(expanded) {
      setExpandedSections((prev) => ({
        ...prev,
        [section]: expanded,
      }));
    };
  }

  // Since <DigestionStateProvider /> is always mounted even if not visible, there
  // needs to be a way to let the user know their digestion is done if they
  // close the drawer while the mutation is ongoing. This ref is used by the
  // mutation callbacks to show a snackbar if the mutation settles while the
  // drawer is closed.
  const isDrawerOpenRef = useRef(currentSidebarId === "digestions");
  useEffect(
    function updateDrawerOpenRef() {
      isDrawerOpenRef.current = currentSidebarId === "digestions";
    },
    [currentSidebarId],
  );

  const { enqueueSnackbar } = useSnackbar();

  const createDigestion = useCreateDigestion();

  const defaultWorkflowIdQuery = useDefaultWorkflowId(ProcessType.Digestion);

  const form = useStudioForm({
    schema: z.object({
      name: requiredText,
      workflowId: requiredUuid,
      workflowContext: optionalObject,
    }),
    defaultValues: defaultFormValues,
    values: {
      ...defaultFormValues,
      workflowId: globalDisabled ? null : defaultWorkflowIdQuery.data ?? null,
    },
    onSubmit(values) {
      invariant(!globalDisabled, "Log ID cannot be null");

      createDigestion.mutate(
        {
          logId,
          draftDigestionTopics,
          name: values.name,
          workflowId: values.workflowId,
          workflowContext: values.workflowContext,
        },
        {
          onSuccess() {
            if (isDrawerOpenRef.current) {
              return;
            }

            enqueueSnackbar(
              `${capitalize(naming)} created! Open the ${naming} sidebar for more details`,
              { variant: "success" },
            );
          },
          onError() {
            if (isDrawerOpenRef.current) {
              return;
            }

            enqueueSnackbar(
              `Couldn't create ${naming}. Open the ${naming} sidebar for more details`,
              { variant: "error" },
            );
          },
        },
      );
    },
  });

  // The workflow context field will need the selected workflow's ID to
  // fetch its context schema
  const { name, workflowId } = form.watch();
  const {
    formState: { errors },
  } = form;

  let nameCompletionStatus: SectionCompletionStatus;
  if (errors.name != null) {
    nameCompletionStatus = "error";
  } else if (name != null) {
    nameCompletionStatus = "complete";
  } else {
    nameCompletionStatus = "incomplete";
  }

  let workflowCompletionStatus: SectionCompletionStatus;
  if (errors.workflowId != null || errors.workflowContext != null) {
    workflowCompletionStatus = "error";
  } else if (workflowId != null) {
    workflowCompletionStatus = "complete";
  } else {
    workflowCompletionStatus = "incomplete";
  }

  let topicsCompletionStatus: SectionCompletionStatus;
  if (draftDigestionTopics.length > 0) {
    topicsCompletionStatus = "complete";
  } else {
    topicsCompletionStatus = "incomplete";
  }

  let summaryCompletionStatus: SectionCompletionStatus;
  if (createDigestion.isSuccess) {
    summaryCompletionStatus = "complete";
  } else if (createDigestion.isError) {
    summaryCompletionStatus = "error";
  } else {
    summaryCompletionStatus = "incomplete";
  }

  const completionStatuses: Record<SectionName, SectionCompletionStatus> = {
    name: nameCompletionStatus,
    workflow: workflowCompletionStatus,
    topics: topicsCompletionStatus,
    summary: summaryCompletionStatus,
  };

  const allSectionsCompleted =
    nameCompletionStatus === "complete" &&
    workflowCompletionStatus === "complete" &&
    topicsCompletionStatus === "complete";

  return {
    globalDisabled,
    createDigestion,
    workflowId,
    workflowIdFieldDisabled: defaultWorkflowIdQuery.isLoading,
    form,
    completionStatuses,
    allSectionsCompleted,
    getSectionProps(section: SectionName) {
      return {
        completionStatus: completionStatuses[section],
        expanded: expandedSections[section],
        onExpansionChange: createExpansionChangeHandler(section),
      };
    },
  };
}

const DigestionSectionRoot = styled(Accordion)(({ theme }) => ({
  background: "none",
  ["&:not(:last-of-type)"]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "&::before": {
    display: "none",
  },
  [`& .${accordionSummaryClasses.root}`]: {
    paddingInline: 0,
  },
  [`& .${accordionDetailsClasses.root}`]: {
    paddingInline: 0,
  },
}));

function DigestionSection({
  title,
  completionStatus,
  expanded,
  onExpansionChange,
  children,
}: {
  title: string;
  completionStatus: SectionCompletionStatus;
  expanded: boolean;
  onExpansionChange: (expanded: boolean) => void;
  children: React.ReactNode;
}) {
  function handleExpansionChange(_: unknown, isExpanded: boolean): void {
    onExpansionChange(isExpanded);
  }

  return (
    <DigestionSectionRoot
      expanded={expanded}
      onChange={handleExpansionChange}
      disableGutters
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
          {renderStatusIcon(completionStatus)}
          <Typography variant="h6" component="p">
            {title}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </DigestionSectionRoot>
  );
}

function SectionSubheader({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      variant="overline"
      component="p"
      sx={{ mb: 2, fontSize: (theme) => theme.typography.body2.fontSize }}
    >
      {children}
    </Typography>
  );
}

function renderStatusIcon(status: SectionCompletionStatus): React.JSX.Element {
  switch (status) {
    case "incomplete": {
      return <RadioButtonUnchecked />;
    }
    case "complete": {
      return <CheckCircle color="success" />;
    }
    case "error": {
      return <Cancel color="error" />;
    }
    default: {
      assertNever(status);
    }
  }
}
