import { useState } from "react";
import { TextField } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { getFieldLabel } from "~/domain/common";
import { DeprecableLabel } from "~/domain/versioning";
import { useField } from "./hooks";
import type { BaseInputProps, FieldPropsFromInputProps } from "./types";

interface ObjectInputProps extends BaseInputProps<object | null> {
  disabled?: boolean;
  helperText?: string;
}

export function ObjectInput({
  name,
  label = getFieldLabel(name),
  deprecated,
  required,
  disabled = false,
  helperText,
  value,
  onChange,
  errorMessage,
}: ObjectInputProps) {
  // The user's input shouldn't change as they're typing, so the display value
  // needs to be tracked separately from the stored value. However, when
  // initializing this state, if the value is not null then it can be
  // pretty-printed
  const [displayValue, setDisplayValue] = useState(() =>
    value === null ? "" : JSON.stringify(value, null, 2),
  );

  return (
    <TextField
      name={name}
      sx={{ "& textarea": { fontFamily: "monospace" } }}
      fullWidth
      multiline
      required={required}
      disabled={disabled}
      minRows={3}
      maxRows={6}
      label={<DeprecableLabel deprecated={deprecated}>{label}</DeprecableLabel>}
      error={errorMessage !== undefined}
      helperText={errorMessage ?? helperText ?? " "}
      value={displayValue}
      onChange={(e) => {
        const rawValue = e.target.value;

        setDisplayValue(rawValue);

        if (rawValue === "") {
          onChange(null);
        } else {
          try {
            onChange(JSON.parse(rawValue));
          } catch {
            onChange(rawValue);
          }
        }
      }}
    />
  );
}

export function ObjectField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, object | null>,
>({
  control,
  ...rest
}: FieldPropsFromInputProps<TFieldValues, TName, ObjectInputProps>) {
  const { value, onChange, errorMessage } = useField({
    control,
    name: rest.name,
  });

  return (
    <ObjectInput
      {...rest}
      value={value}
      onChange={onChange}
      errorMessage={errorMessage}
    />
  );
}
