import { useMemo } from "react";
import type { AsyncOperationSnapshot } from "~/types";
import { useSkipToFirstTimestamp, useUpdatePanelBuffering } from "../../hooks";
import type { PairedTopics, TimelinePanel } from "../../panels";
import { usePairedTopics, VisualizationType } from "../../panels";
import { useLoadedPlaybackSource, usePlaybackSettings } from "../../playback";
import type {
  LimitedRecordsRequest,
  PlayerRecord,
  RecordStore,
  StoreSnapshot,
} from "../../record-store";
import {
  getFrequencyForTimestep,
  NoRecordsError,
  useStoreSnapshot,
} from "../../record-store";
import type { TimestepValue } from "../../types";
import { useVisualizationStoreParams } from "../context";

interface StoreRequest {
  pairedTopics: PairedTopics<TimelinePanel["topics"]>;
  timestep: TimestepValue;
  count: number;
  timestamp: bigint;
}

type TimelineData = {
  topicId: string;
  timestep: TimestepValue;
  timestamp: bigint;
  records: Array<PlayerRecord<"default">>;
} & (
  | { current: PlayerRecord<"default">; firstTimestamp: null }
  | { current: null; firstTimestamp: bigint }
);

function createRecordRequest(
  pairedTopics: PairedTopics<TimelinePanel["topics"]>,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
): LimitedRecordsRequest<"default"> {
  const [{ topic }] = pairedTopics;

  return {
    recordType: "default",
    topicId: topic.id,
    frequency: getFrequencyForTimestep(timestep),
    timestamp,
    count,
  };
}

function subscribeToStore(
  pairedTopics: PairedTopics<TimelinePanel["topics"]>,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
  limit: number,
  prefetchBehind: number,
  prefetchAhead: number,
  store: RecordStore,
  notify: () => void,
): () => void {
  const recordsRequest = createRecordRequest(
    pairedTopics,
    timestep,
    count,
    timestamp,
  );

  const [{ topic }] = pairedTopics;

  return store.subscribe({
    ...recordsRequest,
    limit,
    topicStartTime: topic.startTime,
    topicEndTime: topic.endTime,
    prefetchBehind,
    prefetchAhead,
    notify,
  });
}

function getSnapshot(
  pairedTopics: PairedTopics<TimelinePanel["topics"]>,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
  store: RecordStore,
): AsyncOperationSnapshot<TimelineData> {
  const response = store.getRecords(
    createRecordRequest(pairedTopics, timestep, count, timestamp),
  );

  const [{ topic }] = pairedTopics;

  if (response.status !== "fulfilled") {
    return response;
  }

  const { value: records } = response;

  if (records.length === 0) {
    return {
      status: "rejected",
      reason: new NoRecordsError(),
    };
  }

  const mostRecentRecordIndex = records.findLastIndex(
    (record) => record.timestamp <= timestamp,
  );

  return {
    status: "fulfilled",
    value: {
      topicId: topic.id,
      timestep,
      timestamp,
      records,
      ...(mostRecentRecordIndex >= 0
        ? {
            current: records[mostRecentRecordIndex],
            firstTimestamp: null,
          }
        : {
            current: null,
            // This can't be `undefined` because we checked earlier that the list
            // of records isn't empty.
            firstTimestamp: records[0].timestamp,
          }),
    },
  };
}

export function useTimelineRecords({
  panel,
}: {
  panel: TimelinePanel;
}): [
  timelineRecordsSnapshot: StoreSnapshot<StoreRequest, TimelineData>,
  isPlaceholderSnapshot: boolean,
] {
  const { timestep } = usePlaybackSettings();
  const playbackSource = useLoadedPlaybackSource();

  const pairedTopics = usePairedTopics(panel);

  const { count, limit, prefetchBehind, prefetchAhead } =
    useVisualizationStoreParams(VisualizationType.Timeline);

  const storeSnapshotResult = useStoreSnapshot(
    useMemo(() => {
      const request: StoreRequest = {
        pairedTopics,
        timestep,
        count,
        timestamp: playbackSource.timestamp,
      };

      return {
        request,
        subscribe: subscribeToStore.bind(
          null,
          pairedTopics,
          timestep,
          count,
          playbackSource.timestamp,
          limit,
          prefetchBehind,
          prefetchAhead,
        ),
        getSnapshot: getSnapshot.bind(
          null,
          pairedTopics,
          timestep,
          count,
          playbackSource.timestamp,
        ),
      };
    }, [
      pairedTopics,
      timestep,
      count,
      playbackSource.timestamp,
      limit,
      prefetchBehind,
      prefetchAhead,
    ]),
  );

  useSkipToFirstTimestamp(
    panel,
    pairedTopics[0].topic.startTime,
    storeSnapshotResult.snapshot.status === "fulfilled",
  );

  useUpdatePanelBuffering(
    storeSnapshotResult.snapshot.status === "pending" ||
      storeSnapshotResult.isPlaceholder,
  );

  return [storeSnapshotResult.snapshot, storeSnapshotResult.isPlaceholder];
}
