import type { SampleFrequencyValue, TimestepValue } from "../types";
import { SampleFrequency, Timestep } from "../types";

export function normalizeToArray<TElement>(
  value: TElement | ReadonlyArray<TElement>,
): Array<TElement> {
  return new Array<TElement>().concat(value);
}

export function getFrequencyForTimestep(
  timestep: TimestepValue,
): SampleFrequencyValue {
  return {
    [Timestep.Second]: SampleFrequency.Second,
    [Timestep.HalfSecond]: SampleFrequency.HalfSecond,
    [Timestep.Decisecond]: SampleFrequency.Decisecond,
  }[timestep];
}

export function mergeSubscriptions(
  unsubscribeFns: ReadonlyArray<(() => void) | false>,
): () => void {
  const actualFns = unsubscribeFns.filter((fn) => typeof fn === "function");

  return () => {
    actualFns.forEach((fn) => fn());
  };
}
