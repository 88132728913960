import type { StudioErrorOptions } from "~/errors";
import { StudioError } from "~/errors";
import type { Topic } from "~/lqs";

interface RecordStoreErrorParams extends StudioErrorOptions {
  topic?: Topic;
}

export class RecordStoreError extends StudioError {
  readonly topic: Topic | undefined;

  constructor(params?: RecordStoreErrorParams) {
    super(params);

    this.topic = params?.topic;
  }
}

export class NoRecordsError extends RecordStoreError {}
