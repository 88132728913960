import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loading } from "~/components/Loading";
import { ErrorMessage } from "~/components/error-message";
import { assertNever } from "~/utils/assertions";
import { PanelLayout } from "../../components";
import type { MapPanel } from "../../panels";
import { useMapSubVisualization } from "./use-map-sub-visualization";

const LazyCartesianVisualization = React.lazy(
  () => import("./cartesian-visualization"),
);
const LazyGpsVisualization = React.lazy(() => import("./gps-visualization"));

export function MapVisualization({ panel }: { panel: MapPanel }) {
  const subVisualization = useMapSubVisualization(panel);

  switch (subVisualization) {
    case "gps": {
      return renderLazyVisualization(<LazyGpsVisualization panel={panel} />);
    }
    case "cartesian": {
      return renderLazyVisualization(
        <LazyCartesianVisualization panel={panel} />,
      );
    }
    default: {
      assertNever(subVisualization);
    }
  }
}

function renderLazyVisualization(visualization: React.JSX.Element) {
  return (
    <ErrorBoundary
      fallback={renderPanelFallback(
        <ErrorMessage>Could not initialize map visualization</ErrorMessage>,
      )}
    >
      <React.Suspense
        fallback={renderPanelFallback(<Loading type="circular" />)}
      >
        {visualization}
      </React.Suspense>
    </ErrorBoundary>
  );
}

function renderPanelFallback(children: React.ReactNode) {
  return <PanelLayout>{children}</PanelLayout>;
}
