import React, { useId } from "react";
import {
  FormControlLabel,
  FormHelperText,
  Slider,
  Switch,
  Typography,
} from "@mui/material";
import { invariant } from "~/lib/invariant";
import { getEventHandlerProps } from "~/utils/get-event-handler-props";
import { usePlayerActions } from "../../actions";
import { SourceTopicSelect, TopicName } from "../../components";
import { useLoadedPlayerTopics } from "../../hooks";
import type {
  PairedTopic,
  PointCloudTopicDescriptor,
  ThreeDPanel,
} from "../../panels";
import { constraints, usePairedTopics } from "../../panels";

export function ThreeDVisualizationControls({ panel }: { panel: ThreeDPanel }) {
  const id = useId();

  const topics = useLoadedPlayerTopics();

  const playerActions = usePlayerActions();

  const pairedTopics = usePairedTopics(panel);

  const tfStaticTopic =
    topics.find((topic) => topic.name === "/tf_static") ?? null;

  const toggleStaticTransformsHandlerProps = getEventHandlerProps(
    "onChange",
    tfStaticTopic != null &&
      function handleToggleStaticTransforms(): void {
        playerActions.toggleStaticTransforms(panel);
      },
  );

  function handleToggleRotationControls(): void {
    playerActions.toggleRotationControls(panel);
  }

  return (
    <>
      <FormControlLabel
        sx={{
          justifyContent: "space-between",
          marginInlineStart: 0,
        }}
        control={
          <Switch
            id={id}
            aria-describedby={`${id}-desc`}
            checked={
              toggleStaticTransformsHandlerProps.disabled
                ? false
                : panel.useStaticTransforms
            }
            {...toggleStaticTransformsHandlerProps}
          />
        }
        label="Use static transforms"
        labelPlacement="start"
      />
      <FormHelperText id={`${id}-desc`} sx={{ marginBlockEnd: 2 }}>
        {toggleStaticTransformsHandlerProps.disabled && (
          <>
            <TopicName monospace>/tf_static</TopicName> not in this log
          </>
        )}
      </FormHelperText>
      <FormControlLabel
        sx={{
          justifyContent: "space-between",
          marginInlineStart: 0,
          marginBlockEnd: 2,
        }}
        control={
          <Switch
            checked={panel.showRotationControls}
            onChange={handleToggleRotationControls}
          />
        }
        label="Rotation controls"
        labelPlacement="start"
      />
      <PointCloudTopicControls panel={panel} pair={pairedTopics[0]} />
    </>
  );
}

function PointCloudTopicControls({
  panel,
  pair,
}: {
  panel: ThreeDPanel;
  pair: PairedTopic<PointCloudTopicDescriptor>;
}) {
  const playerActions = usePlayerActions();

  function handlePointSizeChange(
    _: unknown,
    newPointSize: number | Array<number>,
  ): void {
    invariant(
      typeof newPointSize === "number",
      "Expected value to be a single number",
    );

    playerActions.setPointCloudPointSize(panel, newPointSize);
  }

  return (
    <>
      <SourceTopicSelect
        panel={panel}
        topic={pair.topic}
        sx={{ marginBlockEnd: 2 }}
      />
      <Typography>Point Size</Typography>
      <Slider
        sx={{
          alignSelf: "center",
          width: (theme) => `calc(100% - ${theme.spacing(2.5)})`,
        }}
        min={constraints.pointSize.min}
        max={constraints.pointSize.max}
        step={constraints.pointSize.step}
        value={pair.descriptor.size}
        onChange={handlePointSizeChange}
      />
    </>
  );
}
