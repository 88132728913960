import { FileDownload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { invariant } from "~/lib/invariant";
import { get } from "~/lib/std";
import type * as sdk from "~/lqs";
import { useDataStoreClients } from "~/lqs";
import { getEventHandlerProps } from "~/utils/get-event-handler-props";
import type { ImagePanel } from "../../../panels";
import { usePlaybackSource } from "../../../playback";
import { useImageFrames } from "../use-image-frames";

export function FullResDownload({
  panel,
  topic,
}: {
  panel: ImagePanel;
  topic: sdk.Topic;
}) {
  const playbackSource = usePlaybackSource();

  const [imageFramesSnapshot, isPlaceholderSnapshot] = useImageFrames({
    panel,
  });
  const imageTimestamp = imageFramesSnapshot.value?.current?.timestamp;

  const { enqueueSnackbar } = useSnackbar();

  const downloadFullResImageMutation = useDownloadFullResImage(topic.id);

  const downloadHandlerProps = getEventHandlerProps(
    "onClick",
    !playbackSource.isPlaying &&
      imageTimestamp != null &&
      !isPlaceholderSnapshot &&
      function handleDownload(): void {
        downloadFullResImageMutation.mutate(
          { timestamp: imageTimestamp },
          {
            onError() {
              enqueueSnackbar("Unable to download image", { variant: "error" });
            },
          },
        );
      },
  );

  return (
    <LoadingButton
      color="primary"
      variant="contained"
      fullWidth
      {...downloadHandlerProps}
      loading={downloadFullResImageMutation.isLoading}
      startIcon={<FileDownload />}
      sx={{ marginBlockEnd: 2 }}
    >
      Download Full-Res Image
    </LoadingButton>
  );
}

function useDownloadFullResImage(
  topicId: sdk.Topic["id"],
): UseMutationResult<void, unknown, { timestamp: sdk.Record["timestamp"] }> {
  const { topicApi } = useDataStoreClients();

  return useMutation({
    async mutationFn({ timestamp }) {
      const {
        data: { auxiliaryData },
      } = await topicApi.fetchRecord({
        topicId,
        timestamp,
        includeAuxiliaryData: true,
        auxiliaryContext: JSON.stringify({ full_res_image: true }),
      });

      const presignedUrl = get(auxiliaryData, "presigned_url");

      invariant(typeof presignedUrl === "string", "No presigned URL found");

      try {
        new URL(presignedUrl);
      } catch (e) {
        throw new Error("Invalid presigned URL", { cause: e });
      }

      // The back-end should set the `Content-Disposition: attachment` header
      // for the resource this points to which should initiate a download.
      window.location = presignedUrl;
    },
  });
}
