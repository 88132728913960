import { ButtonBase, Stack, styled } from "@mui/material";
import { signInWithGoogle } from "~/lib/auth";
import { assertNever } from "~/utils/assertions";
import SIGN_IN_WITH_GOOGLE_SRC from "./assets/sign-in-with-google-2x.png";
import SIGN_UP_WITH_GOOGLE_SRC from "./assets/sign-up-with-google-2x.png";

export function SocialAuth({ type }: { type: "sign-in" | "sign-up" }) {
  return (
    <Stack sx={{ alignItems: "center" }}>
      <GoogleButton type={type} />
    </Stack>
  );
}

const GoogleButtonRoot = styled(ButtonBase)({
  "& img": {
    maxWidth: "100%",
    maxHeight: "40px",
  },
});

// See Google sign in button branding guidelines:
// https://developers.google.com/identity/branding-guidelines
function GoogleButton({ type }: { type: "sign-in" | "sign-up" }) {
  let src;
  let alt;
  switch (type) {
    case "sign-in": {
      src = SIGN_IN_WITH_GOOGLE_SRC;
      alt = "Sign in with Google";

      break;
    }
    case "sign-up": {
      src = SIGN_UP_WITH_GOOGLE_SRC;
      alt = "Sign up with Google";

      break;
    }
    default: {
      assertNever(type);
    }
  }

  return (
    <GoogleButtonRoot type="button" onClick={signInWithGoogle}>
      <img src={src} alt={alt} />
    </GoogleButtonRoot>
  );
}
