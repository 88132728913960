import * as z from "zod";
import { assertNever } from "~/utils/assertions";
import { SplitOrientation } from "../constants";
import {
  imageFlipDirectionSchema,
  imageRotationSchema,
  topicNameSchema,
  topicTypeNameSchema,
  visualizationTypeSchema,
} from "./common";

const storedPanelStateV1Schema = z.object({
  // Existing v1 objects in storage may not have the version field set so it
  // needs to have a default
  version: z.literal("1").default("1"),
  name: topicNameSchema,
  messageTypeName: topicTypeNameSchema,
  fields: z.array(z.string()).max(3),
  tab: visualizationTypeSchema,
});

const storedPanelStateV2Schema = z.object({
  version: z.literal("2"),
  name: topicNameSchema,
  messageTypeName: topicTypeNameSchema,
  fields: z.array(z.string()).max(3),
  tab: visualizationTypeSchema,
  image: z.object({
    rotateDeg: imageRotationSchema,
  }), // Added
});

const storedPanelStateV3Schema = z.object({
  version: z.literal("3"),
  name: topicNameSchema,
  messageTypeName: topicTypeNameSchema,
  fields: z.array(z.string()).max(3),
  tab: visualizationTypeSchema,
  colorizeImage: z.boolean(), // Added
  imageRotationDeg: imageRotationSchema, // `image.rotateDeg` from v2
  imageFlipDirection: imageFlipDirectionSchema.nullable(), // Added
  pointCloudPointSize: z.number().min(0).max(0.1), // Added
});

const storedPanelStateV4Schema = z.object({
  version: z.literal("4"),
  name: topicNameSchema,
  messageTypeName: topicTypeNameSchema,
  fields: z.array(z.string()).max(3),
  tab: visualizationTypeSchema,
  colorizeImage: z.boolean(),
  imageRotationDeg: imageRotationSchema,
  imageFlipDirection: imageFlipDirectionSchema.nullable(),
  pointCloudPointSize: z.number().min(0).max(0.1),
  imageBrightnessPct: z.number().min(0).max(5), // Added
  imageContrastPct: z.number().min(0).max(2), // Added
});

const storedPanelStateV5Schema = z.object({
  version: z.literal("5"),
  name: topicNameSchema,
  messageTypeName: topicTypeNameSchema,
  fields: z.array(z.string()).max(3),
  tab: visualizationTypeSchema,
  colorizeImage: z.boolean(),
  imageRotationDeg: imageRotationSchema,
  imageFlipDirection: imageFlipDirectionSchema.nullable(),
  pointCloudPointSize: z.number().min(0).max(0.1),
  imageBrightnessPct: z.number().min(0).max(5),
  imageContrastPct: z.number().min(0).max(2),
  supplementaryMapTopics: z.array(topicNameSchema), // Added
});

const storedPanelStateSchema = z
  .union([
    storedPanelStateV5Schema,
    storedPanelStateV4Schema,
    storedPanelStateV3Schema,
    storedPanelStateV2Schema,
    storedPanelStateV1Schema,
  ])
  .transform((result): z.infer<typeof storedPanelStateV5Schema> => {
    /* eslint-disable no-fallthrough */
    switch (result.version) {
      // @ts-expect-error intentional fallthrough
      case "1": {
        const v2Result: z.infer<typeof storedPanelStateV2Schema> = {
          ...result,
          version: "2",
          image: {
            rotateDeg: 0,
          },
        };

        result = storedPanelStateV2Schema.parse(v2Result);
      }
      // @ts-expect-error intentional fallthrough
      case "2": {
        const v3Result: z.infer<typeof storedPanelStateV3Schema> = {
          ...result,
          version: "3",
          colorizeImage: false,
          imageRotationDeg: result.image.rotateDeg,
          imageFlipDirection: null,
          pointCloudPointSize: 0.05,
        };

        result = storedPanelStateV3Schema.parse(v3Result);
      }
      // @ts-expect-error intentional fallthrough
      case "3": {
        const v4Result: z.infer<typeof storedPanelStateV4Schema> = {
          ...result,
          version: "4",
          imageBrightnessPct: 1,
          imageContrastPct: 1,
        };

        result = storedPanelStateV4Schema.parse(v4Result);
      }
      // @ts-expect-error intentional fallthrough
      case "4": {
        const v5Result: z.infer<typeof storedPanelStateV5Schema> = {
          ...result,
          version: "5",
          supplementaryMapTopics: [],
        };

        result = storedPanelStateV5Schema.parse(v5Result);
      }
      case "5": {
        return result;
      }
      default: {
        assertNever(result);
      }
    }
    /* eslint-enable no-fallthrough */
  });

export type StoredPanelState = z.infer<typeof storedPanelStateSchema>;

const nodeIdSchema = z.number();
const flexSchema = z.number();

const storedPanelNodeSchema = z.object({
  type: z.literal("panel"),
  id: nodeIdSchema,
  parentId: nodeIdSchema.nullable(),
  flex: flexSchema,
  state: storedPanelStateSchema.nullable(),
});

const baseContainerNodeSchema = z.object({
  type: z.literal("container"),
  id: nodeIdSchema,
  parentId: nodeIdSchema.nullable(),
  flex: flexSchema,
  orientation: z.nativeEnum(SplitOrientation),
});

type StoredContainerNode = z.infer<typeof baseContainerNodeSchema> & {
  firstChild: StoredLayoutNode;
  secondChild: StoredLayoutNode;
};

export type StoredLayoutNode =
  | z.infer<typeof storedPanelNodeSchema>
  | StoredContainerNode;

const storedLayoutNodeSchema: z.ZodType<StoredLayoutNode, z.ZodTypeDef, any> =
  z.union([
    storedPanelNodeSchema,
    baseContainerNodeSchema.extend({
      firstChild: z.lazy(() => storedLayoutNodeSchema),
      secondChild: z.lazy(() => storedLayoutNodeSchema),
    }),
  ]);

export const storedLayoutProfileSchema = z.object({
  name: z.string(),
  layout: storedLayoutNodeSchema,
});

export type StoredLayoutProfile = z.infer<typeof storedLayoutProfileSchema>;
