export const chartFields = {
  max: 3,
} as const;

export const imageRotationDeg = {
  step: 90,
} as const;

export const imageBrightnessPct = {
  min: 0,
  max: 5,
  step: 0.05,
  default: 1,
} as const;

export const imageContrastPct = {
  min: 0,
  max: 2,
  step: 0.05,
  default: 1,
} as const;

export const imageOpacityPct = {
  min: 0,
  max: 1,
  step: 0.01,
  default: 1,
} as const;

export const pointSize = {
  min: 0,
  max: 0.75,
  step: 0.005,
  default: 0.05,
} as const;
