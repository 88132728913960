import React, { useEffect } from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { ClockCheckOutline } from "mdi-material-ui";
import * as z from "zod";
import { TextField, TimestampField, useStudioForm } from "~/components/Form";
import { requiredBigInt, requiredText } from "~/domain/common";
import {
  useFormatPlaybackTimestamp,
  useLoadedPlaybackSource,
  usePlaybackSettings,
  usePlaybackSource,
  usePlayerActions,
} from "~/player";
import { parseDuration } from "../sidebars";

interface Constraint {
  value: bigint;
  formatted: string;
  exclusive: boolean;
}

interface SelectionFieldProps {
  name: string;
  value: bigint;
  min: bigint;
  exclusiveMin?: boolean;
  max: bigint;
  exclusiveMax?: boolean;
  onSubmit: (newValue: bigint) => void;
}

function refineTimestamp(
  value: bigint,
  min: Constraint,
  max: Constraint,
  ctx: z.RefinementCtx,
): void {
  if (min.exclusive && !(value > min.value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Must be greater than ${min.formatted}`,
      fatal: true,
    });

    return;
  }

  if (!min.exclusive && !(value >= min.value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Cannot be less than ${min.formatted}`,
      fatal: true,
    });

    return;
  }

  if (max.exclusive && !(value < max.value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Must be less than ${max.formatted}`,
      fatal: true,
    });
  }

  if (!max.exclusive && !(value <= max.value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Cannot be greater than ${max.formatted}`,
      fatal: true,
    });

    return;
  }
}

function durationSchema(min: Constraint, max: Constraint) {
  return requiredText.superRefine((value, ctx) => {
    const parsedDuration = parseDuration(value);
    if (parsedDuration == null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid duration",
        fatal: true,
      });

      return;
    }

    refineTimestamp(parsedDuration, min, max, ctx);
  });
}

function timestampSchema(min: Constraint, max: Constraint) {
  return requiredBigInt.superRefine((value, ctx) => {
    refineTimestamp(value, min, max, ctx);
  });
}

export function TimestampSelectionForm({
  mode,
}: {
  mode: "instant" | "range";
}) {
  const { displayFormat } = usePlaybackSettings();

  const playbackSource = usePlaybackSource();

  const playerActions = usePlayerActions();

  if (playbackSource.isLoading) {
    return null;
  }

  const { bounds, timestamp, range } = playbackSource;

  function handleInstantSubmit(instant: bigint): void {
    playerActions.seek(instant);
  }

  function handleRangeStartSubmit(rangeStart: bigint): void {
    playerActions.setRange({
      startTime: rangeStart,
      endTime: range.endTime,
    });
    playerActions.seek(rangeStart);
  }

  function handleRangeEndSubmit(rangeEnd: bigint): void {
    playerActions.setRange({
      startTime: range.startTime,
      endTime: rangeEnd,
    });
    playerActions.seek(rangeEnd);
  }

  switch (true) {
    case displayFormat === "elapsed" && mode === "instant": {
      return (
        <DurationSelectionField
          name="instant"
          value={timestamp}
          min={bounds.startTime}
          max={bounds.endTime}
          onSubmit={handleInstantSubmit}
        />
      );
    }
    case displayFormat === "elapsed" && mode === "range": {
      return (
        <Stack spacing={1.5}>
          <DurationSelectionField
            name="rangeStart"
            value={range.startTime}
            min={bounds.startTime}
            max={range.endTime}
            exclusiveMax
            onSubmit={handleRangeStartSubmit}
          />
          <DurationSelectionField
            name="rangeEnd"
            value={range.endTime}
            min={range.startTime}
            exclusiveMin
            max={bounds.endTime}
            onSubmit={handleRangeEndSubmit}
          />
        </Stack>
      );
    }
    case displayFormat === "original" && mode === "instant": {
      return (
        <TimestampSelectionField
          name="instant"
          value={timestamp}
          min={bounds.startTime}
          max={bounds.endTime}
          onSubmit={handleInstantSubmit}
        />
      );
    }
    case displayFormat === "original" && mode === "range": {
      return (
        <Stack spacing={1.5}>
          <TimestampSelectionField
            name="rangeStart"
            value={range.startTime}
            min={bounds.startTime}
            max={range.endTime}
            exclusiveMax
            onSubmit={handleRangeStartSubmit}
          />
          <TimestampSelectionField
            name="rangeEnd"
            value={range.endTime}
            min={range.startTime}
            exclusiveMin
            max={bounds.endTime}
            onSubmit={handleRangeEndSubmit}
          />
        </Stack>
      );
    }
    default: {
      throw new Error("Unexpected mode and/or display format");
    }
  }
}

function DurationSelectionField({
  name,
  value,
  min,
  exclusiveMin = false,
  max,
  exclusiveMax = false,
  onSubmit,
}: SelectionFieldProps) {
  const playbackSource = useLoadedPlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const form = useStudioForm({
    schema: z.object({
      [name]: durationSchema(
        {
          value: min - playbackSource.bounds.startTime,
          formatted: formatPlaybackTimestamp(min, "elapsed"),
          exclusive: exclusiveMin,
        },
        {
          value: max - playbackSource.bounds.startTime,
          formatted: formatPlaybackTimestamp(max, "elapsed"),
          exclusive: exclusiveMax,
        },
      ),
    }),
    values: {
      [name]: formatPlaybackTimestamp(value, "elapsed"),
    },
    onSubmit(values) {
      onSubmit(parseDuration(values[name])! + playbackSource.bounds.startTime);
    },
  });

  useResetSubmittedForm(form);

  const isDirty = form.formState.dirtyFields[name] ?? false;

  return (
    <form noValidate onSubmit={form.handleSubmit}>
      <TextField
        control={form.control}
        name={name}
        size="small"
        endAdornment={
          <Tooltip title="Update">
            <span>
              <IconButton
                type="submit"
                disabled={!isDirty}
                edge="end"
                size="small"
              >
                <ClockCheckOutline fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        }
        helperText={
          isDirty ? (
            <span>
              Press{" "}
              <Typography
                variant="inherit"
                component="span"
                sx={{ fontWeight: "bold" }}
              >
                Update (
                <ClockCheckOutline
                  sx={{ fontSize: "1.25em", verticalAlign: "text-bottom" }}
                />
                )
              </Typography>{" "}
              to set time
            </span>
          ) : undefined
        }
      />
    </form>
  );
}

function TimestampSelectionField({
  name,
  value,
  min,
  exclusiveMin = false,
  max,
  exclusiveMax = false,
  onSubmit,
}: SelectionFieldProps) {
  const form = useStudioForm({
    schema: z.object({
      [name]: timestampSchema(
        { value: min, formatted: String(min), exclusive: exclusiveMin },
        { value: max, formatted: String(max), exclusive: exclusiveMax },
      ),
    }),
    values: {
      [name]: value,
    },
    onSubmit(values) {
      onSubmit(values[name]);
    },
  });

  useResetSubmittedForm(form);

  const [fieldValue] = form.watch([name]);

  const isDirty = form.formState.dirtyFields[name] ?? false;

  return (
    <form noValidate onSubmit={form.handleSubmit}>
      <TimestampField
        control={form.control}
        name={name}
        size="small"
        endAdornment={
          <TimestampEndAdornment value={fieldValue} isDirty={isDirty} />
        }
        helperText={
          isDirty ? (
            <span>
              Press{" "}
              <Typography
                variant="inherit"
                component="span"
                sx={{ fontWeight: "bold" }}
              >
                Update (
                <ClockCheckOutline
                  sx={{ fontSize: "1.25em", verticalAlign: "text-bottom" }}
                />
                )
              </Typography>{" "}
              to set time
            </span>
          ) : undefined
        }
      />
    </form>
  );
}

function TimestampEndAdornment({
  value,
  isDirty,
}: {
  value: unknown;
  isDirty: boolean;
}) {
  const { bounds } = useLoadedPlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  return (
    <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
      {typeof value === "bigint" &&
        bounds.startTime <= value &&
        value <= bounds.endTime && (
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            ({formatPlaybackTimestamp(value, "elapsed")})
          </Typography>
        )}
      <Tooltip title="Update">
        <span>
          <IconButton type="submit" disabled={!isDirty} edge="end" size="small">
            <ClockCheckOutline fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  );
}

function useResetSubmittedForm(form: {
  reset: () => void;
  formState: { isSubmitSuccessful: boolean };
}): void {
  const {
    reset,
    formState: { isSubmitSuccessful },
  } = form;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);
}
