import { useMemo } from "react";
import type { Topic } from "~/lqs";
import type { AsyncOperationSnapshot } from "~/types";
import {
  useLoadedPlayerTopics,
  useSkipToFirstTimestamp,
  useUpdatePanelBuffering,
} from "../../hooks";
import type { PairedTopics, ThreeDPanel } from "../../panels";
import { usePairedTopics, VisualizationType } from "../../panels";
import { useLoadedPlaybackSource, usePlaybackSettings } from "../../playback";
import type {
  LimitedRecordsRequest,
  PlayerRecord,
  RecordStore,
  StoreSnapshot,
} from "../../record-store";
import {
  getFrequencyForTimestep,
  mergeSubscriptions,
  NoRecordsError,
  RecordStoreError,
  useStoreSnapshot,
} from "../../record-store";
import type { TimestepValue } from "../../types";
import { useVisualizationStoreParams } from "../context";
import type { TransformNode } from "./transforms";
import { buildTransformTree } from "./transforms";

interface StoreRequest {
  pairedTopics: PairedTopics<ThreeDPanel["topics"]>;
  tfStaticTopic: Topic | null;
  showRotationControls: boolean;
  timestep: TimestepValue;
  count: number;
  timestamp: bigint;
}

export interface PointCloudObject {
  id: string;
  type: "point-cloud";
  data: PlayerRecord<"threeD">["data"];
  size: number;
}

export type ThreeDObject = PointCloudObject;

export interface ThreeDRecords {
  showRotationControls: boolean;
  objects: ReadonlyArray<ThreeDObject>;
  transforms: ReadonlyArray<TransformNode> | null;
  firstTimestamp: bigint | null;
}

export type ThreeDStoreSnapshot = StoreSnapshot<StoreRequest, ThreeDRecords>;

function createRecordRequest(
  pairedTopics: PairedTopics<ThreeDPanel["topics"]>,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
): LimitedRecordsRequest<"threeD"> {
  const [{ topic }] = pairedTopics;

  return {
    recordType: "threeD",
    topicId: topic.id,
    frequency: getFrequencyForTimestep(timestep),
    timestamp,
    count,
  };
}

function subscribeToStore(
  pairedTopics: PairedTopics<ThreeDPanel["topics"]>,
  tfStaticTopic: Topic | null,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
  limit: number,
  prefetchBehind: number,
  prefetchAhead: number,
  store: RecordStore,
  notify: () => void,
): () => void {
  const recordsRequest = createRecordRequest(
    pairedTopics,
    timestep,
    count,
    timestamp,
  );

  const [{ topic }] = pairedTopics;

  return mergeSubscriptions([
    store.subscribe({
      ...recordsRequest,
      limit,
      topicStartTime: topic.startTime,
      topicEndTime: topic.endTime,
      prefetchBehind,
      prefetchAhead,
      notify,
    }),
    tfStaticTopic != null &&
      store.subscribe({
        recordType: "default",
        topicId: tfStaticTopic.id,
        all: true,
        frequency: null,
        topicStartTime: tfStaticTopic.startTime,
        topicEndTime: tfStaticTopic.endTime,
        limit,
        notify,
      }),
  ]);
}

function getSnapshot(
  pairedTopics: PairedTopics<ThreeDPanel["topics"]>,
  tfStaticTopic: Topic | null,
  showRotationControls: boolean,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
  store: RecordStore,
): AsyncOperationSnapshot<ThreeDRecords> {
  const response = store.getRecords(
    createRecordRequest(pairedTopics, timestep, count, timestamp),
  );

  if (response.status === "rejected") {
    return response;
  }

  const tfStaticResponse =
    tfStaticTopic != null
      ? store.getRecords({
          recordType: "default",
          topicId: tfStaticTopic.id,
          all: true,
          frequency: null,
        })
      : null;

  if (tfStaticResponse?.status === "rejected") {
    if (tfStaticResponse.reason instanceof RecordStoreError) {
      return tfStaticResponse;
    } else {
      return {
        status: "rejected",
        reason: new RecordStoreError({
          cause: tfStaticResponse.reason,
          topic: tfStaticTopic!,
        }),
      };
    }
  }

  if (response.status === "pending") {
    return response;
  }

  if (tfStaticResponse?.status === "pending") {
    return tfStaticResponse;
  }

  const [{ topic, descriptor }] = pairedTopics;

  const { value: records } = response;

  if (records.length === 0) {
    return {
      status: "rejected",
      reason: new NoRecordsError(),
    };
  }

  let transforms = null;
  if (tfStaticResponse != null) {
    try {
      transforms = buildTransformTree(tfStaticResponse.value);
    } catch (e) {
      return {
        status: "rejected",
        reason: new RecordStoreError({
          cause: e,
          topic: tfStaticTopic!,
        }),
      };
    }
  }

  const objects = new Array<ThreeDObject>();
  let firstTimestamp: bigint | null = null;

  const mostRecentRecordIndex = records.findLastIndex(
    (record) => record.timestamp <= timestamp,
  );

  if (mostRecentRecordIndex >= 0) {
    objects.push({
      id: topic.id,
      type: "point-cloud",
      data: records[mostRecentRecordIndex].data,
      size: descriptor.size,
    });
  } else {
    firstTimestamp = records[0].timestamp;
  }

  return {
    status: "fulfilled",
    value: {
      showRotationControls,
      objects,
      transforms,
      firstTimestamp,
    },
  };
}

export function useThreeDRecords({
  panel,
}: {
  panel: ThreeDPanel;
}): [threeDSnapshot: ThreeDStoreSnapshot, isPlaceholderSnapshot: boolean] {
  const { timestep } = usePlaybackSettings();
  const playbackSource = useLoadedPlaybackSource();

  const pairedTopics = usePairedTopics(panel);

  const { count, limit, prefetchBehind, prefetchAhead } =
    useVisualizationStoreParams(VisualizationType.ThreeD);

  const topics = useLoadedPlayerTopics();
  const tfStaticTopic = !panel.useStaticTransforms
    ? null
    : topics.find((topic) => topic.name === "/tf_static") ?? null;

  const storeSnapshotResult = useStoreSnapshot(
    useMemo(() => {
      const request: StoreRequest = {
        pairedTopics,
        tfStaticTopic,
        showRotationControls: panel.showRotationControls,
        timestep,
        count,
        timestamp: playbackSource.timestamp,
      };

      return {
        request,
        subscribe: subscribeToStore.bind(
          null,
          pairedTopics,
          tfStaticTopic,
          timestep,
          count,
          playbackSource.timestamp,
          limit,
          prefetchBehind,
          prefetchAhead,
        ),
        getSnapshot: getSnapshot.bind(
          null,
          pairedTopics,
          tfStaticTopic,
          panel.showRotationControls,
          timestep,
          count,
          playbackSource.timestamp,
        ),
      };
    }, [
      pairedTopics,
      tfStaticTopic,
      panel.showRotationControls,
      timestep,
      count,
      playbackSource.timestamp,
      limit,
      prefetchBehind,
      prefetchAhead,
    ]),
  );

  useSkipToFirstTimestamp(
    panel,
    pairedTopics[0].topic.startTime,
    storeSnapshotResult.snapshot.status === "fulfilled",
  );

  useUpdatePanelBuffering(
    storeSnapshotResult.snapshot.status === "pending" ||
      storeSnapshotResult.isPlaceholder,
  );

  return [storeSnapshotResult.snapshot, storeSnapshotResult.isPlaceholder];
}
