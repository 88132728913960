import React from "react";
import { Circle } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { Topic } from "~/lqs";
import type { Maybe } from "~/types";
import { pluralize } from "~/utils";
import { usePlayerActions } from "../../actions";
import type {
  ImagePanelWithInference,
  InferenceTopicDescriptor,
} from "../../panels";
import { usePairedTopics } from "../../panels";
import type { InferenceFrames } from "../types";
import type { Detection, DetectionInferenceResults } from "./schemas";
import { useClassifyDetectionsQuality } from "./use-classify-detections-quality";
import { computeDetectionClassColor } from "./visualization";

export function DetectionResultsControls({
  panel,
  results,
  classificationControls,
}: {
  panel: ImagePanelWithInference;
  results: Maybe<DetectionInferenceResults>;
  classificationControls?: React.ReactNode;
}) {
  const [, { descriptor: topicDescriptor }] = usePairedTopics(panel);

  const playerActions = usePlayerActions();

  function handleTransformLockChange(e: React.ChangeEvent<HTMLInputElement>) {
    playerActions.toggleInferenceTransformLock(panel, e.target.checked);
  }

  function handleBoundingBoxVisibilityChange(
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    playerActions.toggleInferenceBoundingBoxes(panel, e.target.checked);
  }

  function handleClassNamesVisibilityChange(
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    playerActions.toggleInferenceClassNames(panel, e.target.checked);
  }

  function createClassNameVisibilityChangeHandler(
    className: Detection["className"],
  ) {
    return function handleChangeClassNameVisibility(
      e: React.ChangeEvent<HTMLInputElement>,
    ) {
      playerActions.toggleObjectClass(panel, className, e.target.checked);
    };
  }

  function renderDetectedClassesList() {
    const listItems = prepareDetectedClassesListItems(results, topicDescriptor);

    return (
      <List
        dense
        subheader={
          <ListSubheader disableGutters disableSticky sx={{ fontWeight: 600 }}>
            Detected Classes
          </ListSubheader>
        }
        sx={{
          "& .MuiListItemIcon-root": {
            minWidth: "auto",
            mr: 1,
          },
        }}
      >
        {listItems.length === 0 ? (
          <ListItem disablePadding>
            <ListItemText>No classes for this image</ListItemText>
          </ListItem>
        ) : (
          listItems.map((listItem) => (
            <ListItem
              key={listItem.className}
              secondaryAction={<Circle sx={{ color: listItem.color }} />}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={listItem.checked}
                  onChange={createClassNameVisibilityChangeHandler(
                    listItem.className,
                  )}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                secondary={pluralize(listItem.count, "object")}
                secondaryTypographyProps={{ noWrap: true }}
              >
                {listItem.className}
              </ListItemText>
            </ListItem>
          ))
        )}
      </List>
    );
  }

  return (
    <>
      <FormControlLabel
        sx={{ justifyContent: "space-between", ml: 0 }}
        control={
          <Switch
            checked={topicDescriptor.lockTransforms}
            onChange={handleTransformLockChange}
          />
        }
        label="Lock inference results"
        labelPlacement="start"
      />
      <FormControlLabel
        sx={{ justifyContent: "space-between", ml: 0 }}
        control={
          <Switch
            checked={topicDescriptor.showDetectionBoundingBoxes}
            onChange={handleBoundingBoxVisibilityChange}
          />
        }
        label="Bounding boxes"
        labelPlacement="start"
      />
      <FormControlLabel
        sx={{ justifyContent: "space-between", ml: 0 }}
        control={
          <Switch
            checked={topicDescriptor.showDetectionClassNames}
            onChange={handleClassNamesVisibilityChange}
          />
        }
        label="Class names"
        labelPlacement="start"
      />
      {classificationControls}
      {renderDetectedClassesList()}
    </>
  );
}

export function DetectionClassificationControls({
  topic,
  timestamp,
  inferenceFrames,
}: {
  topic: Topic;
  timestamp: bigint;
  inferenceFrames: InferenceFrames;
}) {
  const disableClassifying = inferenceFrames.current === null;

  const classifyDetectionsQuality = useClassifyDetectionsQuality({ topic });

  const { enqueueSnackbar } = useSnackbar();

  function createDetectionsQualityClassificationHandler(
    classification: "accept" | "reject",
  ) {
    return function handleDetectionQualityClassification() {
      if (disableClassifying || classifyDetectionsQuality.isLoading) {
        return;
      }

      classifyDetectionsQuality.mutate(
        {
          detectionTopicId: inferenceFrames.topicId,
          timestamp,
          classification,
        },
        {
          onSuccess() {
            enqueueSnackbar("Detections classified", {
              variant: "success",
            });
          },
          onError() {
            enqueueSnackbar("Error classifying detections", {
              variant: "error",
            });
          },
        },
      );
    };
  }

  return (
    <Box sx={{ my: 2 }}>
      <Typography>Detections Quality</Typography>
      {/* <LoadingButton /> doesn't yet inherit props from
        <ButtonGroup /> but should soon */}
      <ButtonGroup
        fullWidth
        disableElevation
        variant="contained"
        color="primary"
      >
        <LoadingButton
          variant="contained"
          color="primary"
          loading={classifyDetectionsQuality.isLoading}
          disabled={disableClassifying}
          onClick={createDetectionsQualityClassificationHandler("accept")}
        >
          Accept
        </LoadingButton>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={classifyDetectionsQuality.isLoading}
          disabled={disableClassifying}
          onClick={createDetectionsQualityClassificationHandler("reject")}
        >
          Reject
        </LoadingButton>
      </ButtonGroup>
    </Box>
  );
}

interface DetectedClassListItem {
  checked: boolean;
  className: Detection["className"];
  count: number;
  color: string;
}

function prepareDetectedClassesListItems(
  detectionResults: Maybe<DetectionInferenceResults>,
  topicDescriptor: InferenceTopicDescriptor,
): Array<DetectedClassListItem> {
  const listItemsMap = new Map<Detection["className"], DetectedClassListItem>();

  detectionResults?.detections.forEach((detection) => {
    let listItem = listItemsMap.get(detection.className);
    if (listItem === undefined) {
      listItem = {
        checked: !topicDescriptor.hiddenObjectClassNames.includes(
          detection.className,
        ),
        className: detection.className,
        count: 1,
        color: computeDetectionClassColor(detection),
      };

      listItemsMap.set(detection.className, listItem);
    } else {
      listItem.count++;
    }
  });

  return Array.from(listItemsMap.values());
}
