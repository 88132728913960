import React from "react";
import { Divider } from "@mui/material";
import { SourceTopicSelect } from "../../../components";
import { useInferenceFrameControls } from "../../../inference";
import type { ImagePanel } from "../../../panels";
import { usePairedTopics } from "../../../panels";
import { FullResDownload } from "./full-res-download";
import { ImageTransformControls } from "./image-transform-controls";

export function ImageControls({ panel }: { panel: ImagePanel }) {
  const inferenceFrameControls = useInferenceFrameControls({ strict: false });

  const [imagePair] = usePairedTopics(panel);

  return (
    <>
      <SourceTopicSelect
        panel={panel}
        topic={imagePair.topic}
        sx={{ marginBlockEnd: 2 }}
      />
      <FullResDownload panel={panel} topic={imagePair.topic} />
      <ImageTransformControls panel={panel} />
      {inferenceFrameControls != null && (
        <>
          <Divider />
          {inferenceFrameControls}
        </>
      )}
    </>
  );
}
