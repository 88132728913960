import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import { invariant } from "~/lib/invariant";
import type { KeyFactory } from "~/types";
import { scheduleQueriesRemoval } from "~/utils";
import { useDataStoreClients } from "../context";
import type {
  Hook,
  HookForWorkflowCreateRequest,
  HookDataResponse,
  HookListResponse,
  HookUpdateRequest,
  ListHooksForWorkflowRequest,
  ListWorkflowsRequest,
  Workflow,
  WorkflowCreateRequest,
  WorkflowDataResponse,
  WorkflowListResponse,
  WorkflowUpdateRequest,
} from "../sdk";
import type { LqsQueryOptions } from "./utils";
import { createResourceCrudHooks, getInitialDetailsData } from "./utils";

export const {
  queryKeyFactory: workflowKeys,
  useList: useWorkflows,
  useFetch: useWorkflow,
  useCreate: useCreateWorkflow,
  useUpdate: useUpdateWorkflow,
  useDelete: useDeleteWorkflow,
} = createResourceCrudHooks({
  baseQueryKey: "workflows",
  getIdentifier(workflow: Workflow) {
    return workflow.id;
  },
  listResource({ signal }, { workflowApi }, request: ListWorkflowsRequest) {
    return workflowApi.listWorkflows(request, { signal });
  },
  fetchResource({ signal }, { workflowApi }, workflowId: Workflow["id"]) {
    return workflowApi.fetchWorkflow({ workflowId }, { signal });
  },
  createResource({ workflowApi }, request: WorkflowCreateRequest) {
    return workflowApi.createWorkflow({ workflowCreateRequest: request });
  },
  updateResource(
    { workflowApi },
    workflowId: Workflow["id"],
    updates: WorkflowUpdateRequest,
  ) {
    return workflowApi.updateWorkflow({
      workflowId,
      workflowUpdateRequest: updates,
    });
  },
  deleteResource({ workflowApi }, workflowId: Workflow["id"]) {
    return workflowApi.deleteWorkflow({ workflowId });
  },
});

export function useWorkflowsQueryOptionsFactory(): (
  request: ListWorkflowsRequest,
) => LqsQueryOptions<WorkflowListResponse> {
  const { workflowApi } = useDataStoreClients();

  return (request) => ({
    queryKey: workflowKeys.list(request),
    queryFn({ signal }) {
      return workflowApi.listWorkflows(request, { signal });
    },
  });
}

export function useWorkflowQueryOptionsFactory(): (
  workflowId: Workflow["id"] | null,
) => LqsQueryOptions<WorkflowDataResponse, "enabled"> {
  const { workflowApi } = useDataStoreClients();

  return (workflowId) => {
    const enabled = workflowId !== null;

    return {
      queryKey: workflowKeys.fetch(workflowId),
      queryFn({ signal }) {
        invariant(enabled, "Workflow ID not provided");

        return workflowApi.fetchWorkflow({ workflowId }, { signal });
      },
      enabled,
    };
  };
}

export type WorkflowHooks = KeyFactory<typeof workflowKeys>;

export const workflowHookKeys = {
  all: (workflowId: Workflow["id"]) =>
    [...workflowKeys.fetch(workflowId), "hooks"] as const,
  lists: (workflowId: Workflow["id"]) =>
    [...workflowHookKeys.all(workflowId), "list"] as const,
  list: (
    workflowId: Workflow["id"],
    request: StrictOmit<ListHooksForWorkflowRequest, "workflowId">,
  ) => [...workflowHookKeys.lists(workflowId), request] as const,
  fetches: (workflowId: Workflow["id"]) =>
    [...workflowHookKeys.all(workflowId), "fetch"] as const,
  fetch: (workflowId: Workflow["id"], hookId: Hook["id"]) =>
    [...workflowHookKeys.fetches(workflowId), hookId] as const,
};

export type WorkflowHookKeys = KeyFactory<typeof workflowHookKeys>;

export function useWorkflowHooks<TData = HookListResponse>(
  workflowId: Workflow["id"],
  request: StrictOmit<ListHooksForWorkflowRequest, "workflowId">,
  options?: UseQueryOptions<
    HookListResponse,
    unknown,
    TData,
    WorkflowHookKeys["list"]
  >,
): UseQueryResult<TData> {
  const { workflowApi } = useDataStoreClients();

  return useQuery({
    queryKey: workflowHookKeys.list(workflowId, request),
    queryFn({ signal }) {
      return workflowApi.listHooksForWorkflow(
        { workflowId, ...request },
        { signal },
      );
    },
    ...options,
  });
}

export function useWorkflowHook<TData = HookDataResponse>(
  workflowId: Workflow["id"],
  hookId: Hook["id"],
  options?: StrictOmit<
    UseQueryOptions<
      HookDataResponse,
      unknown,
      TData,
      WorkflowHookKeys["fetch"]
    >,
    "initialData"
  >,
): UseQueryResult<TData> {
  const queryClient = useQueryClient();

  const { workflowApi } = useDataStoreClients();

  return useQuery({
    queryKey: workflowHookKeys.fetch(workflowId, hookId),
    queryFn({ signal }) {
      return workflowApi.fetchHookForWorkflow(
        { workflowId, hookId },
        { signal },
      );
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        workflowHookKeys.lists(workflowId),
        (workflowHook: Hook) =>
          workflowHook.workflowId === workflowId && workflowHook.id === hookId,
      );
    },
  });
}

export function useCreateWorkflowHook(
  workflowId: Workflow["id"],
): UseMutationResult<HookDataResponse, unknown, HookForWorkflowCreateRequest> {
  const queryClient = useQueryClient();

  const { workflowApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return workflowApi.createHookForWorkflow({
        workflowId,
        hookForWorkflowCreateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.removeQueries({
        queryKey: workflowHookKeys.lists(workflowId),
      });
      queryClient.setQueryData<HookDataResponse>(
        workflowHookKeys.fetch(workflowId, response.data.id),
        response,
      );
    },
  });
}

export function useUpdateWorkflowHook(
  workflowId: Workflow["id"],
  hookId: Hook["id"],
): UseMutationResult<HookDataResponse, unknown, HookUpdateRequest> {
  const queryClient = useQueryClient();

  const { workflowApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return workflowApi.updateHookForWorkflow({
        workflowId,
        hookId,
        hookUpdateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.removeQueries({
        queryKey: workflowHookKeys.lists(workflowId),
      });
      queryClient.setQueryData<HookDataResponse>(
        workflowHookKeys.fetch(workflowId, hookId),
        response,
      );
    },
  });
}

export function useDeleteWorkflowHook(
  workflowId: Workflow["id"],
  hookId: Hook["id"],
): UseMutationResult<void, unknown, void> {
  const queryClient = useQueryClient();

  const { workflowApi } = useDataStoreClients();

  return useMutation({
    mutationFn() {
      return workflowApi.deleteHookForWorkflow({ workflowId, hookId });
    },
    onSuccess() {
      queryClient.removeQueries({
        queryKey: workflowHookKeys.lists(workflowId),
      });
      scheduleQueriesRemoval(() => {
        queryClient.removeQueries({
          queryKey: workflowHookKeys.fetch(workflowId, hookId),
        });
      });
    },
  });
}
