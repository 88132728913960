import React from "react";
import { TextField } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { getFieldLabel } from "~/domain/common";
import { DeprecableLabel } from "~/domain/versioning";
import { useField } from "./hooks";
import type { BaseFieldProps } from "./types";

interface TimestampFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, bigint | null>,
> extends BaseFieldProps<TFieldValues, TName> {
  helperText?: React.ReactNode;
  endAdornment?: React.ReactNode;
  size?: "small" | "medium";
}

export function TimestampField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, bigint | null>,
>({
  control,
  name,
  label = getFieldLabel(name),
  deprecated,
  required,
  helperText = " ",
  endAdornment,
  size = "medium",
}: TimestampFieldProps<TFieldValues, TName>) {
  const { value, onChange, errorMessage } = useField({ control, name });

  return (
    <TextField
      fullWidth
      required={required}
      label={<DeprecableLabel deprecated={deprecated}>{label}</DeprecableLabel>}
      InputProps={{
        inputMode: "numeric",
        endAdornment,
      }}
      error={errorMessage !== undefined}
      helperText={errorMessage ?? helperText}
      size={size}
      value={value === null ? "" : String(value)}
      onChange={(e) => {
        const rawValue = e.target.value.trim();

        if (rawValue === "") {
          onChange(null);
        } else {
          try {
            onChange(BigInt(rawValue));
          } catch {
            onChange(rawValue);
          }
        }
      }}
    />
  );
}
