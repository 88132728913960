/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TypeEncoding } from "./TypeEncoding";
import {
  TypeEncodingFromJSON,
  TypeEncodingFromJSONTyped,
  TypeEncodingToJSON,
} from "./TypeEncoding";

export interface TopicCreateRequest {
  locked?: boolean;
  logId: string;
  name: string;
  note?: string | null;
  baseTimestamp?: bigint | null;
  context?: object | null;
  associatedTopicId?: string | null;
  strict?: boolean;
  typeName?: string | null;
  typeEncoding?: TypeEncoding | null;
  typeData?: string | null;
  typeSchema?: object | null;
}

export function TopicCreateRequestFromJSON(json: any): TopicCreateRequest {
  return TopicCreateRequestFromJSONTyped(json);
}

export function TopicCreateRequestFromJSONTyped(json: any): TopicCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    logId: json["log_id"],
    name: json["name"],
    note: json["note"],
    baseTimestamp:
      json["base_timestamp"] === undefined
        ? undefined
        : json["base_timestamp"] == null
          ? json["base_timestamp"]
          : BigInt(json["base_timestamp"]),
    context: json["context"],
    associatedTopicId: json["associated_topic_id"],
    strict: json["strict"],
    typeName: json["type_name"],
    typeEncoding:
      json["type_encoding"] === undefined
        ? undefined
        : TypeEncodingFromJSON(json["type_encoding"]),
    typeData: json["type_data"],
    typeSchema: json["type_schema"],
  };
}

export function TopicCreateRequestToJSON(
  value: TopicCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    log_id: value.logId,
    name: value.name,
    note: value.note,
    base_timestamp:
      value.baseTimestamp === undefined
        ? undefined
        : value.baseTimestamp === null
          ? null
          : value.baseTimestamp.toString(),
    context: value.context,
    associated_topic_id: value.associatedTopicId,
    strict: value.strict,
    type_name: value.typeName,
    type_encoding: TypeEncodingToJSON(value.typeEncoding),
    type_data: value.typeData,
    type_schema: value.typeSchema,
  };
}
