import { minutesToSeconds } from "date-fns";
import { invariant } from "~/lib/invariant";

export {
  millisecondsToSeconds,
  secondsToMilliseconds,
  fromUnixTime,
  minutesToMilliseconds,
  startOfTomorrow,
} from "date-fns";

const NS_IN_S_NUMBER = 1e9;
const NS_IN_S_BIGINT = BigInt(NS_IN_S_NUMBER);

const NS_IN_MS_NUMBER = 1e6;
const NS_IN_MS_BIGINT = BigInt(NS_IN_MS_NUMBER);

interface NanosecondsToSecondsOptions {
  preserveFraction?: boolean;
}

export function nanosecondsToSeconds(
  nanoseconds: bigint,
  { preserveFraction = true }: NanosecondsToSecondsOptions = {},
): number {
  const wholeSeconds = nanoseconds / NS_IN_S_BIGINT;

  if (!preserveFraction) {
    return Number(wholeSeconds);
  } else {
    const fractionalSeconds =
      Number(nanoseconds % NS_IN_S_BIGINT) / NS_IN_S_NUMBER;

    return Number(wholeSeconds) + fractionalSeconds;
  }
}

export function secondsToNanoseconds(seconds: number): bigint {
  return BigInt(seconds) * NS_IN_S_BIGINT;
}

export function minutesToNanoseconds(minutes: number): bigint {
  return BigInt(minutesToSeconds(minutes)) * NS_IN_S_BIGINT;
}

export function hoursToNanoseconds(hours: number): bigint {
  return BigInt(minutesToNanoseconds(hours * 60));
}

function daysToNanoseconds(days: number): bigint {
  return BigInt(hoursToNanoseconds(days * 24));
}

export function toNanoseconds({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
}: {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}): bigint {
  let nanoseconds = 0n;

  nanoseconds += daysToNanoseconds(days);
  nanoseconds += hoursToNanoseconds(hours);
  nanoseconds += minutesToNanoseconds(minutes);
  nanoseconds += secondsToNanoseconds(seconds);
  nanoseconds += millisecondsToNanoseconds(milliseconds);

  return nanoseconds;
}

interface NanosecondsToMillisecondsOptions {
  preserveFraction?: boolean;
}

export function nanosecondsToMilliseconds(
  nanoseconds: bigint,
  { preserveFraction = true }: NanosecondsToMillisecondsOptions = {},
): number {
  const wholeMilliseconds = nanoseconds / NS_IN_MS_BIGINT;

  if (!preserveFraction) {
    return Number(wholeMilliseconds);
  } else {
    const fractionalMilliseconds =
      Number(nanoseconds % NS_IN_MS_BIGINT) / NS_IN_MS_NUMBER;

    return Number(wholeMilliseconds) + fractionalMilliseconds;
  }
}

export function millisecondsToNanoseconds(milliseconds: number): bigint {
  return BigInt(milliseconds) * NS_IN_MS_BIGINT;
}

export function utcToRelativeNanoseconds(
  utcNanoseconds: bigint,
  referenceUtcNanoseconds: bigint,
): number {
  const relativeNanoseconds = Number(utcNanoseconds - referenceUtcNanoseconds);

  invariant(
    Number.isSafeInteger(relativeNanoseconds),
    "Relative nanoseconds are not a safe integer",
  );

  return relativeNanoseconds;
}

export function relativeToUtcNanoseconds(
  relativeNanoseconds: number,
  referenceUtcNanoseconds: bigint,
): bigint {
  return BigInt(relativeNanoseconds) + referenceUtcNanoseconds;
}

export function nanosecondsToDate(nanoseconds: bigint): Date {
  return new Date(
    nanosecondsToMilliseconds(nanoseconds, { preserveFraction: false }),
  );
}
