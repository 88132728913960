import { useMemo } from "react";
import { millisecondsToNanoseconds, secondsToNanoseconds } from "~/lib/dates";
import type { AsyncOperationSnapshot } from "~/types";
import { useSkipToFirstTimestamp, useUpdatePanelBuffering } from "../../hooks";
import type { InferenceFrames } from "../../inference";
import { getInferenceRecordType, getInferenceType } from "../../inference";
import type { ImagePanel, PairedTopics } from "../../panels";
import { usePairedTopics, VisualizationType } from "../../panels";
import { useLoadedPlaybackSource, usePlaybackSettings } from "../../playback";
import type {
  LimitedRecordsRequest,
  LimitedRecordsTopicSubscription,
  PlayerRecord,
  RecordStore,
} from "../../record-store";
import {
  getFrequencyForTimestep,
  mergeSubscriptions,
  useStoreSnapshot,
} from "../../record-store";
import type { TimestepValue } from "../../types";
import { Timestep } from "../../types";
import { floorTimestampForTimestep } from "../../utils";
import { useVisualizationStoreParams } from "../context";
import type {
  ImageFrame,
  ImageFrames,
  ImageFramesRequest,
  ImageFramesSnapshot,
} from "./types";

function createRecordsRequests(
  pairedTopics: PairedTopics<ImagePanel["topics"]>,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
): {
  imageRequest: LimitedRecordsRequest<"image">;
  inferenceRequest: LimitedRecordsRequest<
    "image" | "detections" | "segmentations"
  > | null;
} {
  const [imagePair, inferencePair] = pairedTopics;

  const frequency = getFrequencyForTimestep(timestep);

  const inferenceRecordType = getInferenceRecordType(inferencePair?.topic);
  const hasInference = inferencePair != null && inferenceRecordType != null;

  return {
    imageRequest: {
      recordType: "image",
      topicId: imagePair.topic.id,
      frequency,
      count,
      timestamp,
    },
    inferenceRequest: hasInference
      ? {
          recordType: inferenceRecordType,
          topicId: inferencePair.topic.id,
          frequency,
          count,
          timestamp,
        }
      : null,
  };
}

function subscribeToStore(
  pairedTopics: PairedTopics<ImagePanel["topics"]>,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
  limit: number,
  prefetchBehind: number,
  prefetchAhead: number,
  store: RecordStore,
  notify: () => void,
): () => void {
  const { imageRequest, inferenceRequest } = createRecordsRequests(
    pairedTopics,
    timestep,
    count,
    timestamp,
  );

  const sharedSubscriptionFields: Pick<
    LimitedRecordsTopicSubscription<"image">,
    "limit" | "prefetchBehind" | "prefetchAhead" | "notify"
  > = {
    limit,
    prefetchBehind,
    prefetchAhead,
    notify,
  };

  const [imagePair, inferencePair] = pairedTopics;

  return mergeSubscriptions([
    store.subscribe({
      ...imageRequest,
      ...sharedSubscriptionFields,
      topicStartTime: imagePair.topic.startTime,
      topicEndTime: imagePair.topic.endTime,
    }),
    inferencePair != null &&
      inferenceRequest != null &&
      store.subscribe({
        ...inferenceRequest,
        ...sharedSubscriptionFields,
        topicStartTime: inferencePair.topic.startTime,
        topicEndTime: inferencePair.topic.endTime,
      }),
  ]);
}

function getStoreSnapshot(
  pairedTopics: PairedTopics<ImagePanel["topics"]>,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
  store: RecordStore,
): AsyncOperationSnapshot<ImageFrames> {
  const { imageRequest, inferenceRequest } = createRecordsRequests(
    pairedTopics,
    timestep,
    count,
    timestamp,
  );

  const [imagePair, inferencePair] = pairedTopics;

  const imageRecordsSnapshot = store.getRecords(imageRequest);

  const inferenceRecordsSnapshot =
    inferenceRequest == null ? null : store.getRecords(inferenceRequest);

  if (imageRecordsSnapshot.status === "rejected") {
    return imageRecordsSnapshot;
  }

  if (inferenceRecordsSnapshot?.status === "rejected") {
    return inferenceRecordsSnapshot;
  }

  if (imageRecordsSnapshot.status === "pending") {
    return imageRecordsSnapshot;
  }

  if (inferenceRecordsSnapshot?.status === "pending") {
    return inferenceRecordsSnapshot;
  }

  const { value: imageRecords } = imageRecordsSnapshot;

  const playbackFrameTimestamp = floorTimestampForTimestep(timestamp, timestep);

  const mostRecentRecordIndex = imageRecords.findLastIndex(
    (record) =>
      floorTimestampForTimestep(record.timestamp, timestep) <=
      playbackFrameTimestamp,
  );

  const mostRecentRecord: PlayerRecord<"image"> | undefined =
    imageRecords[mostRecentRecordIndex];

  let currentImageFrame: ImageFrame | null = null;
  if (mostRecentRecord !== undefined) {
    const freshDuration = {
      [Timestep.Second]: secondsToNanoseconds(2),
      [Timestep.HalfSecond]: secondsToNanoseconds(1),
      [Timestep.Decisecond]: millisecondsToNanoseconds(200),
    }[timestep];

    const isStale =
      playbackFrameTimestamp -
        floorTimestampForTimestep(mostRecentRecord.timestamp, timestep) >
      freshDuration;

    let inferenceFrames: InferenceFrames | null = null;
    if (inferencePair != null && inferenceRecordsSnapshot != null) {
      const inferenceRecord = inferenceRecordsSnapshot.value.find(
        (record) => record.timestamp === mostRecentRecord.timestamp,
      );

      const {
        descriptor: { name, ...inferenceFrameConfig },
      } = inferencePair;

      inferenceFrames = {
        // The inference topic snapshot should only be defined if both the
        // inference topic is defined AND it has a known inference type, so this
        // non-null assertion should be safe.
        type: getInferenceType(inferencePair.topic)!,
        topicId: inferencePair.topic.id,
        current: inferenceRecord?.data ?? null,
        config: inferenceFrameConfig,
      } as InferenceFrames;
    }

    currentImageFrame = {
      timestamp: mostRecentRecord.timestamp,
      isStale,
      data: mostRecentRecord.data,
      inferenceFrames,
    };
  }

  const {
    descriptor: { name, ...imageFrameConfig },
  } = imagePair;

  return {
    status: "fulfilled",
    value: {
      current: currentImageFrame,
      nextTimestamp: imageRecords[mostRecentRecordIndex + 1]?.timestamp ?? null,
      config: imageFrameConfig,
    },
  };
}

export function useImageFrames({
  panel,
}: {
  panel: ImagePanel;
}): [imageFramesSnapshot: ImageFramesSnapshot, isPlaceholderSnapshot: boolean] {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = useLoadedPlaybackSource();

  const { count, limit, prefetchBehind, prefetchAhead } =
    useVisualizationStoreParams(VisualizationType.Image);

  const pairedTopics = usePairedTopics(panel);

  const storeSnapshotResult = useStoreSnapshot(
    useMemo(() => {
      const request: ImageFramesRequest = {
        pairedTopics,
        timestep: playbackSettings.timestep,
        count,
        timestamp: playbackSource.timestamp,
      };

      return {
        request,
        subscribe: subscribeToStore.bind(
          null,
          pairedTopics,
          playbackSettings.timestep,
          count,
          playbackSource.timestamp,
          limit,
          prefetchBehind,
          prefetchAhead,
        ),
        getSnapshot: getStoreSnapshot.bind(
          null,
          pairedTopics,
          playbackSettings.timestep,
          count,
          playbackSource.timestamp,
        ),
      };
    }, [
      pairedTopics,
      playbackSource.timestamp,
      playbackSettings.timestep,
      count,
      limit,
      prefetchBehind,
      prefetchAhead,
    ]),
  );

  useSkipToFirstTimestamp(
    panel,
    pairedTopics[0].topic.startTime,
    storeSnapshotResult.snapshot.status === "fulfilled",
  );

  useUpdatePanelBuffering(
    storeSnapshotResult.snapshot.status === "pending" ||
      storeSnapshotResult.isPlaceholder,
  );

  return [storeSnapshotResult.snapshot, storeSnapshotResult.isPlaceholder];
}
