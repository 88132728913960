import React from "react";
import { DoNotDisturb } from "@mui/icons-material";
import {
  Button,
  Divider,
  dividerClasses,
  Stack,
  Typography,
} from "@mui/material";
import * as z from "zod";
import { TextField, useStudioForm } from "~/components/Form";
import { Loading } from "~/components/Loading";
import { ErrorMessage } from "~/components/error-message";
import { optionalText } from "~/domain/common";
import { SidebarHeader } from "~/layout";
import { invariant } from "~/lib/invariant";
import type { InitializedPanel, Panel } from "~/player";
import {
  ChartVisualizationControls,
  checkIsBoundedTopic,
  checkIsPairedTopics,
  checkIsPanelInitialized,
  editPanelTitle,
  ImageControls,
  MapVisualizationControls,
  PanelProvider,
  TagsControl,
  ThreeDVisualizationControls,
  TimelineVisualizationControls,
  TopicName,
  useControlledPanelContext,
  usePairedTopics,
  usePanelLayoutContext,
  usePlaybackSource,
  usePlayerTopics,
  VisualizationType,
} from "~/player";
import { assertNever } from "~/utils/assertions";

export function PanelControlsSidebar() {
  const controlledPanelContext = useControlledPanelContext();

  const { panels } = usePanelLayoutContext();

  const controlledPanel = panels.find(
    (panel) => panel.id === controlledPanelContext.panelId,
  );
  invariant(controlledPanel != null, "Expected to find panel");

  const playbackSource = usePlaybackSource();

  const playerTopicsQuery = usePlayerTopics();

  let content;
  if (playbackSource.isLoading || playerTopicsQuery.isLoading) {
    content = <Loading type="circular" />;
  } else if (playerTopicsQuery.isError) {
    content = <ErrorMessage>Error loading topics</ErrorMessage>;
  } else if (!checkIsPanelInitialized(controlledPanel)) {
    content = (
      <>
        <PanelTitleField panel={controlledPanel} />
        <Divider />
      </>
    );
  } else {
    content = <InitializedPanelControls panel={controlledPanel} />;
  }

  return (
    <>
      <SidebarHeader title="Panel Controls" />
      <Stack
        sx={{
          [`& .${dividerClasses.root}`]: {
            my: 2,
          },
        }}
      >
        <PanelProvider panel={controlledPanel}>{content}</PanelProvider>
      </Stack>
    </>
  );
}

function InitializedPanelControls({ panel }: { panel: InitializedPanel }) {
  const pairedTopics = usePairedTopics(panel, { strict: false });

  if (!checkIsPairedTopics(pairedTopics)) {
    return (
      <ErrorMessage
        disableTypography
        icon={<DoNotDisturb fontSize="large" color="error" />}
      >
        <ErrorMessage.Paragraph>
          Some topics are not in this log:
        </ErrorMessage.Paragraph>
        <ul>
          {pairedTopics.map(({ descriptor, topic }) => {
            if (topic != null) {
              return null;
            }

            return (
              <li key={descriptor.name}>
                <TopicName monospace>{descriptor.name}</TopicName>
              </li>
            );
          })}
        </ul>
      </ErrorMessage>
    );
  }

  if (!pairedTopics.every(({ topic }) => checkIsBoundedTopic(topic))) {
    return (
      <ErrorMessage
        disableTypography
        icon={<DoNotDisturb fontSize="large" color="error" />}
      >
        <ErrorMessage.Paragraph>
          Some topics do not have any records:
        </ErrorMessage.Paragraph>
        <ul>
          {pairedTopics.map(({ descriptor, topic }) => {
            if (checkIsBoundedTopic(topic)) {
              return null;
            }

            return (
              <li key={descriptor.name}>
                <TopicName monospace>{descriptor.name}</TopicName>
              </li>
            );
          })}
        </ul>
      </ErrorMessage>
    );
  }

  let controls;
  switch (panel.visualization) {
    case VisualizationType.Timeline: {
      controls = <TimelineVisualizationControls panel={panel} />;

      break;
    }
    case VisualizationType.Chart: {
      controls = <ChartVisualizationControls panel={panel} />;

      break;
    }
    case VisualizationType.Image: {
      controls = <ImageControls panel={panel} />;

      break;
    }
    case VisualizationType.Map: {
      controls = <MapVisualizationControls panel={panel} />;

      break;
    }
    case VisualizationType.ThreeD: {
      controls = <ThreeDVisualizationControls panel={panel} />;

      break;
    }
    default: {
      assertNever(panel);
    }
  }

  return (
    <>
      <PanelTitleField panel={panel} />
      <TagsControl topic={pairedTopics[0].topic} />
      <Divider />
      {controls}
    </>
  );
}

function PanelTitleField({ panel }: { panel: Panel }) {
  const { dispatch } = usePanelLayoutContext();

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: z.object({
      title: optionalText,
    }),
    values: {
      title: panel.title,
    },
    onSubmit(values) {
      dispatch(
        editPanelTitle({
          panelId: panel.id,
          title: values.title,
        }),
      );
    },
  });

  function handleCancel(): void {
    reset({ title: panel.title });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <TextField
        control={control}
        name="title"
        label="Panel Title"
        helperText={
          dirtyFields.title ? (
            <>
              Press{" "}
              <Typography
                component="span"
                variant="inherit"
                sx={{ fontWeight: "bold" }}
              >
                Save
              </Typography>{" "}
              to update title
            </>
          ) : undefined
        }
      />
      <Stack
        direction="row"
        spacing={1}
        sx={{ marginBlockStart: 0.5, marginBlockEnd: 2 }}
      >
        <Button type="submit" color="primary" variant="outlined" size="small">
          Save
        </Button>
        <Button
          color="secondary"
          variant="text"
          size="small"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Stack>
    </form>
  );
}
