import type {
  GroupProps,
  PointsMaterialProps,
  PointsProps,
} from "@react-three/fiber";

declare module "react" {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      group: GroupProps;
      points: PointsProps;
      pointsMaterial: PointsMaterialProps;
    }
  }
}

import CameraControls from "camera-controls";
const { ACTION } = CameraControls;
export { ACTION as CameraActions };

export { Group, Matrix4, Quaternion, Vector3 } from "three";

export type { ThreeEvent } from "@react-three/fiber";
export { Canvas, useThree } from "@react-three/fiber";

export { CameraControls } from "@react-three/drei/core/CameraControls";
export { TransformControls } from "@react-three/drei/core/TransformControls";
