import type { StrictOmit } from "ts-essentials";
import * as z from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import {
  boolean,
  commonResourceFilterSchemas,
  filterArray,
  filterBigInt,
  filterBoolean,
  filterNumber,
  filterText,
  filterUuid,
  optionalBigInt,
  optionalObject,
  optionalText,
  optionalUuid,
  requiredText,
  requiredUuid,
} from "~/domain/common";
import type {
  ListLogsRequest,
  ListQueriesForLogRequest,
  ListTagsForLogRequest,
  LogCreateRequest,
  LogUpdateRequest,
  QueryForLogCreateRequest,
  QueryUpdateRequest,
  TagForLogCreateRequest,
  TagUpdateRequest,
} from "~/lqs";

export const listLogsSchema = z.object({
  id: filterUuid,
  name: filterText,
  nameLike: filterText,
  groupId: filterUuid,
  locked: filterBoolean,
  noteLike: filterText,
  defaultWorkflowId: filterUuid,
  defaultWorkflowIdNull: filterBoolean,
  startTimeGte: filterBigInt,
  startTimeLte: filterBigInt,
  startTimeNull: filterBoolean,
  endTimeGte: filterBigInt,
  endTimeLte: filterBigInt,
  endTimeNull: filterBoolean,
  recordSizeGte: filterNumber,
  recordSizeLte: filterNumber,
  recordCountGte: filterNumber,
  recordCountLte: filterNumber,
  objectSizeGte: filterNumber,
  objectSizeLte: filterNumber,
  objectCountGte: filterNumber,
  objectCountLte: filterNumber,
  tagLabelIdsIncludes: filterArray(requiredUuid),
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<ListLogsRequest, "groupIdIn" | "contextFilter" | "includeCount">
>);
export const listLogsAliases = {
  tagLabelIdsIncludes: "labelIds",
} as const;

export const createLogSchema = z.object({
  name: requiredText,
  groupId: requiredUuid,
  defaultWorkflowId: optionalUuid,
  baseTimestamp: optionalBigInt,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<LogCreateRequest>);

export const editLogSchema = z.object({
  name: requiredText,
  groupId: requiredUuid,
  defaultWorkflowId: optionalUuid,
  baseTimestamp: optionalBigInt,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<LogUpdateRequest>);

export const listTagsSchema = z.object({
  id: filterUuid,
  labelId: filterUuid,
  topicId: filterUuid,
  note: filterText,
  noteLike: filterText,
  startTimeGte: filterBigInt,
  startTimeLte: filterBigInt,
  startTimeNull: filterBoolean,
  endTimeGte: filterBigInt,
  endTimeLte: filterBigInt,
  endTimeNull: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<ListTagsForLogRequest, "logId" | "contextFilter" | "includeCount">
>);

export const createTagSchema = z.object({
  labelId: requiredUuid,
  topicId: optionalUuid,
  note: optionalText,
  startTime: optionalBigInt,
  endTime: optionalBigInt,
  context: optionalObject,
} satisfies SchemaShape<TagForLogCreateRequest>);

export const editTagSchema = z.object({
  labelId: requiredUuid,
  topicId: optionalUuid,
  note: optionalText,
  startTime: optionalBigInt,
  endTime: optionalBigInt,
  context: optionalObject,
} satisfies SchemaShape<TagUpdateRequest>);

export const listLogQueriesSchema = z.object({
  id: filterUuid,
  name: filterText,
  nameLike: filterText,
  noteLike: filterText,
  statement: filterText,
  statementLike: filterText,
  workflowId: filterUuid,
  workflowIdNull: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<
    ListQueriesForLogRequest,
    "logId" | "workflowContextFilter" | "contextFilter" | "includeCount"
  >
>);

export const createLogQuerySchema = z.object({
  name: optionalText,
  statement: optionalText,
  parameters: optionalObject,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<QueryForLogCreateRequest>);

export const editLogQuerySchema = z.object({
  name: optionalText,
  statement: optionalText,
  parameters: optionalObject,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<QueryUpdateRequest>);
