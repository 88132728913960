import { useMemo } from "react";
import type { StudioErrorOptions } from "~/errors";
import { StudioError } from "~/errors";
import type { AsyncOperationSnapshot } from "../../../types";
import { useSkipToFirstTimestamp, useUpdatePanelBuffering } from "../../hooks";
import type { ChartPanel, PairedTopics } from "../../panels";
import { usePairedTopics, VisualizationType } from "../../panels";
import { useLoadedPlaybackSource, usePlaybackSettings } from "../../playback";
import type {
  AllRecordsRequest,
  LimitedRecordsRequest,
  PlayerRecord,
  RecordStore,
  StoreSnapshot,
} from "../../record-store";
import {
  getFrequencyForTimestep,
  mergeSubscriptions,
  useStoreSnapshot,
} from "../../record-store";
import type { TimestepValue } from "../../types";
import { SampleFrequency } from "../../types";
import { useVisualizationStoreParams } from "../context";
import lookupFieldUnit from "./lookup-field-unit";
import { getFieldStroke } from "./utils";

interface ChartErrorParameters extends StudioErrorOptions {
  source: "window" | "overview";
}

export class ChartError extends StudioError {
  readonly source: "window" | "overview";

  constructor({ message, cause, source }: ChartErrorParameters) {
    super({ message, cause });

    this.source = source;
  }
}

interface StoreRequest {
  pairedTopics: PairedTopics<ChartPanel["topics"]>;
  showOverview: boolean;
  timestep: TimestepValue;
  count: number;
  timestamp: bigint;
}

export interface ChartSource {
  id: string;
  field: string;
  unit: string | null;
  color: string;
  window: ReadonlyArray<PlayerRecord<"default">>;
  overview: ReadonlyArray<PlayerRecord<"default">> | null;
}

export interface ChartRecords {
  timestep: TimestepValue;
  timestamp: bigint;
  showOverview: boolean;
  sources: ReadonlyArray<ChartSource>;
}

export type ChartStoreSnapshot = StoreSnapshot<StoreRequest, ChartRecords>;

function createRecordsRequests(
  pairedTopics: PairedTopics<ChartPanel["topics"]>,
  showOverview: boolean,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
): {
  windowRequest: LimitedRecordsRequest<"default">;
  overviewRequest: AllRecordsRequest<"default"> | null;
} {
  const [{ topic }] = pairedTopics;

  return {
    windowRequest: {
      recordType: "default",
      topicId: topic.id,
      frequency: getFrequencyForTimestep(timestep),
      timestamp,
      count,
    },
    overviewRequest: showOverview
      ? {
          recordType: "default",
          topicId: topic.id,
          frequency: SampleFrequency.Second,
          all: true,
        }
      : null,
  };
}

function subscribeToStore(
  pairedTopics: PairedTopics<ChartPanel["topics"]>,
  showOverview: boolean,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
  limit: number,
  prefetchBehind: number,
  prefetchAhead: number,
  store: RecordStore,
  notify: () => void,
): () => void {
  const { windowRequest, overviewRequest } = createRecordsRequests(
    pairedTopics,
    showOverview,
    timestep,
    count,
    timestamp,
  );

  const [{ topic }] = pairedTopics;

  return mergeSubscriptions([
    store.subscribe({
      ...windowRequest,
      limit,
      topicStartTime: topic.startTime,
      topicEndTime: topic.endTime,
      prefetchBehind,
      prefetchAhead,
      notify,
    }),
    overviewRequest != null &&
      store.subscribe({
        ...overviewRequest,
        limit: 100,
        topicStartTime: topic.startTime,
        topicEndTime: topic.endTime,
        notify,
      }),
  ]);
}

function getSnapshot(
  pairedTopics: PairedTopics<ChartPanel["topics"]>,
  showOverview: boolean,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
  store: RecordStore,
): AsyncOperationSnapshot<ChartRecords> {
  const { windowRequest, overviewRequest } = createRecordsRequests(
    pairedTopics,
    showOverview,
    timestep,
    count,
    timestamp,
  );

  const [{ topic, descriptor }] = pairedTopics;

  const windowResponse = store.getRecords(windowRequest);
  const overviewResponse =
    overviewRequest == null ? null : store.getRecords(overviewRequest);

  if (windowResponse.status === "rejected") {
    return {
      status: "rejected",
      reason: new ChartError({
        cause: windowResponse.reason,
        source: "window",
      }),
    };
  }

  if (overviewResponse?.status === "rejected") {
    return {
      status: "rejected",
      reason: new ChartError({
        cause: overviewResponse.reason,
        source: "overview",
      }),
    };
  }

  if (windowResponse.status === "pending") {
    return windowResponse;
  }

  if (overviewResponse?.status === "pending") {
    return overviewResponse;
  }

  const sources = new Array<ChartSource>();

  for (const field of descriptor.fields) {
    sources.push({
      id: `${topic.id}:${field}`,
      field,
      unit: lookupFieldUnit(topic.typeName, field),
      color: getFieldStroke(sources.length),
      window: windowResponse.value,
      overview: overviewResponse?.value ?? null,
    });
  }

  return {
    status: "fulfilled",
    value: {
      timestep,
      timestamp,
      showOverview,
      sources,
    },
  };
}

export function useChartRecords({
  panel,
}: {
  panel: ChartPanel;
}): [
  chartRecordsSnapshot: StoreSnapshot<StoreRequest, ChartRecords>,
  isPlaceholderSnapshot: boolean,
] {
  const { timestep } = usePlaybackSettings();
  const playbackSource = useLoadedPlaybackSource();

  const { count, limit, prefetchBehind, prefetchAhead } =
    useVisualizationStoreParams(VisualizationType.Chart);

  const pairedTopics = usePairedTopics(panel);

  const showOverview = panel.showOverview;

  const storeSnapshotResult = useStoreSnapshot(
    useMemo(() => {
      const request: StoreRequest = {
        pairedTopics,
        showOverview,
        timestep,
        count,
        timestamp: playbackSource.timestamp,
      };

      return {
        request,
        subscribe: subscribeToStore.bind(
          null,
          pairedTopics,
          showOverview,
          timestep,
          count,
          playbackSource.timestamp,
          limit,
          prefetchBehind,
          prefetchAhead,
        ),
        getSnapshot: getSnapshot.bind(
          null,
          pairedTopics,
          showOverview,
          timestep,
          count,
          playbackSource.timestamp,
        ),
      };
    }, [
      pairedTopics,
      showOverview,
      timestep,
      count,
      playbackSource.timestamp,
      limit,
      prefetchBehind,
      prefetchAhead,
    ]),
  );

  useSkipToFirstTimestamp(
    panel,
    pairedTopics[0].topic.startTime,
    storeSnapshotResult.snapshot.status === "fulfilled",
  );

  useUpdatePanelBuffering(
    storeSnapshotResult.snapshot.status === "pending" ||
      storeSnapshotResult.isPlaceholder,
  );

  return [storeSnapshotResult.snapshot, storeSnapshotResult.isPlaceholder];
}
