import React from "react";
import { Typography } from "@mui/material";
import { invariant } from "~/lib/invariant";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../actions";
import { TopicSelect } from "../components";
import { useLoadedPlayerTopics } from "../hooks";
import {
  checkIsImagePanelWithInference,
  useInitializedPanel,
  usePairedTopics,
  VisualizationType,
} from "../panels";
import { useImageFrames } from "../visualizations";
import {
  DetectionClassificationControls,
  DetectionResultsControls,
} from "./detections";
import { ImageResultsControls } from "./images";
import { isInferenceTopic } from "./inference-config";
import { ImageSegmentationResultsControls } from "./segmentations";

export function InferenceFrameControls() {
  const playerTopics = useLoadedPlayerTopics();

  const panel = useInitializedPanel();

  invariant(
    panel.visualization === VisualizationType.Image,
    "Panel is not of type image",
  );

  const playerActions = usePlayerActions();

  const [imageFramesSnapshot] = useImageFrames({ panel });
  const imageFrame = imageFramesSnapshot.value?.current;

  const [imagePair, inferencePair] = usePairedTopics(panel);

  const inferenceTopics = filterInferenceTopics(imagePair.topic, playerTopics);

  const handleInferenceTopicChange = (newValue: Topic | null) => {
    playerActions.setInferenceTopic(panel, newValue);
  };

  let typeSpecificControls = null;
  if (
    inferencePair != null &&
    imageFrame != null &&
    checkIsImagePanelWithInference(panel)
  ) {
    const { inferenceFrames } = imageFrame;

    switch (inferenceFrames?.type) {
      case "object-detection":
      case "zero-shot-object-detection": {
        typeSpecificControls = (
          <DetectionResultsControls
            panel={panel}
            results={inferenceFrames.current}
            classificationControls={
              <DetectionClassificationControls
                topic={imagePair.topic}
                timestamp={imageFrame.timestamp}
                inferenceFrames={inferenceFrames}
              />
            }
          />
        );
        break;
      }
      case "image-segmentation": {
        typeSpecificControls = (
          <ImageSegmentationResultsControls
            panel={panel}
            results={inferenceFrames.current}
          />
        );
        break;
      }
      case "depth-estimation": {
        typeSpecificControls = <ImageResultsControls panel={panel} />;
        break;
      }
    }
  }

  return (
    <>
      <Typography variant="h6" component="p" sx={{ mb: 2 }}>
        Inference Tasks
      </Typography>
      <TopicSelect
        sx={{ mb: 1 }}
        inputLabel="Inference Topic"
        value={inferencePair?.topic ?? null}
        topics={inferenceTopics}
        onChange={handleInferenceTopicChange}
      />
      {typeSpecificControls}
    </>
  );
}

function filterInferenceTopics(
  baseTopic: Topic,
  playerTopics: ReadonlyArray<Topic>,
): Array<Topic> {
  return playerTopics.filter(
    (topic) =>
      topic.associatedTopicId === baseTopic.id && isInferenceTopic(topic),
  );
}
