import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type {
  DigestionCreateRequest,
  DigestionDataResponse,
  ListTopicsRequest,
  Log,
  TopicKeys,
  TopicListResponse,
} from "~/lqs";
import {
  circumventPagination,
  digestionKeys,
  ProcessState,
  topicKeys,
  useDataStoreClients,
} from "~/lqs";
import type { DraftDigestionTopic } from "./types";

const API_MAX_LIMIT = 100;

interface UseCreateDigestionParams
  extends Pick<
    DigestionCreateRequest,
    "name" | "workflowId" | "workflowContext"
  > {
  logId: Log["id"];
  draftDigestionTopics: ReadonlyArray<DraftDigestionTopic>;
}

export function useTopics<TData = TopicListResponse>(
  request: ListTopicsRequest,
  options?: UseQueryOptions<
    TopicListResponse,
    unknown,
    TData,
    TopicKeys["list"]
  >,
) {
  const { topicApi } = useDataStoreClients();

  return useQuery({
    queryKey: topicKeys.list(request),
    queryFn(context) {
      return request.limit === -1
        ? circumventPagination(
            topicApi.listTopics.bind(topicApi),
            API_MAX_LIMIT,
            request,
            context,
          )
        : topicApi.listTopics(request, context);
    },
    ...options,
  });
}

export function useCreateDigestion() {
  const queryClient = useQueryClient();

  const { digestionApi } = useDataStoreClients();

  return useMutation({
    async mutationFn({
      logId,
      draftDigestionTopics,
      name,
      workflowId,
      workflowContext,
    }: UseCreateDigestionParams) {
      const newDigestion = await digestionApi.createDigestion({
        digestionCreateRequest: {
          logId,
          name,
          workflowId,
          workflowContext,
        },
      });

      await Promise.all(
        draftDigestionTopics.map((draftDigestionTopic) =>
          digestionApi.createDigestionTopicForDigestion({
            digestionId: newDigestion.data.id,
            digestionTopicForDigestionCreateRequest: draftDigestionTopic,
          }),
        ),
      );

      return digestionApi.updateDigestion({
        digestionId: newDigestion.data.id,
        digestionUpdateRequest: {
          state: ProcessState.Queued,
        },
      });
    },
    onSuccess(response) {
      queryClient.removeQueries({
        queryKey: digestionKeys.lists(),
        type: "inactive",
      });
      queryClient.setQueryData<DigestionDataResponse>(
        digestionKeys.fetch(response.data.id),
        response,
      );
      return queryClient.invalidateQueries({ queryKey: digestionKeys.lists() });
    },
  });
}
