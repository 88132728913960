import * as z from "zod";
import type { PlayerRecord } from "../../record-store";

const rawTransformSchema = z.object({
  header: z.object({
    frame_id: z.string(),
  }),
  transform: z.object({
    rotation: z.object({
      x: z.number(),
      y: z.number(),
      z: z.number(),
      w: z.number(),
    }),
    translation: z.object({
      x: z.number(),
      y: z.number(),
      z: z.number(),
    }),
  }),
  child_frame_id: z.string(),
});

type RawTransform = z.infer<typeof rawTransformSchema>;

const rawTransformsSchema = z.object({
  transforms: z.array(rawTransformSchema),
});

export interface TransformNode {
  frameId: string;
  rotation: RawTransform["transform"]["rotation"];
  translation: RawTransform["transform"]["translation"];
  children: ReadonlyArray<TransformNode>;
}

export function buildTransformTree(
  records: ReadonlyArray<Pick<PlayerRecord<"default">, "data">>,
): ReadonlyArray<TransformNode> {
  const cache = new Map<
    string,
    { isRoot: boolean; children: Array<TransformNode> }
  >();

  for (const record of records) {
    const { transforms: rawTransforms } = rawTransformsSchema.parse(
      record.data,
    );

    for (const rawTransform of rawTransforms) {
      const {
        header: { frame_id: parentFrameId },
        transform: { rotation, translation },
        child_frame_id: frameId,
      } = rawTransform;

      let entry = cache.get(frameId);
      if (entry == null) {
        entry = {
          isRoot: false,
          children: [],
        };

        cache.set(frameId, entry);
      } else {
        entry.isRoot = false;
      }

      let parentEntry = cache.get(parentFrameId);
      if (parentEntry == null) {
        parentEntry = {
          isRoot: true,
          children: [],
        };

        cache.set(parentFrameId, parentEntry);
      }

      // It's possible for duplicate static transforms to exist but they're
      // assumed to represent the same information (because they're static), so
      // ignore frames that already exist.
      if (!parentEntry.children.some((child) => child.frameId === frameId)) {
        parentEntry.children.push({
          frameId,
          rotation,
          translation,
          children: entry.children,
        });
      }
    }
  }

  const rootNodes = new Array<TransformNode>();
  for (const { isRoot, children } of cache.values()) {
    if (!isRoot) {
      continue;
    }

    rootNodes.push(...children);
  }

  return rootNodes;
}
