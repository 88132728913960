import React from "react";
import { Box, Button, Divider, Stack, styled, Typography } from "@mui/material";
import {
  ChartContainer,
  ChartsGrid,
  ChartsReferenceLine,
  ChartsXAxis,
  ScatterPlot,
} from "@mui/x-charts";
import { TimerSand } from "mdi-material-ui";
import useResizeObserver from "use-resize-observer";
import { Center } from "~/components/Center";
import { JsonTree } from "~/components/JsonTree";
import { ErrorMessage } from "~/components/error-message";
import { utcToRelativeNanoseconds } from "~/lib/dates";
import { usePlayerActions } from "../../actions";
import { LoadingFeedback, PanelLayout } from "../../components";
import type { TimelinePanel } from "../../panels";
import {
  useFormatPlaybackTimestamp,
  useLoadedPlaybackSource,
} from "../../playback";
import type { PlayerRecord } from "../../record-store";
import type { TimestepValue } from "../../types";
import { calculateRecordWindow } from "../../utils";
import { calculateWindowTicks, getWindowSizeForTimestep } from "../utils";
import { useTimelineRecords } from "./use-timeline-records";

export function TimelineVisualization({ panel }: { panel: TimelinePanel }) {
  const { ref, width } = useResizeObserver();

  const [timelineRecordsSnapshot, isPlaceholderSnapshot] = useTimelineRecords({
    panel,
  });

  const playerActions = usePlayerActions();

  let content;
  if (timelineRecordsSnapshot.status === "pending") {
    content = <LoadingFeedback description="data points" />;
  } else if (timelineRecordsSnapshot.status === "rejected") {
    content = (
      <ErrorMessage>An error occurred. Couldn't get timeline data</ErrorMessage>
    );
  } else {
    const { value } = timelineRecordsSnapshot;

    let body;
    if (value.current == null) {
      body = (
        <Center>
          <TimerSand fontSize="large" />
          <Typography variant="h5" component="p" paragraph>
            No recent message
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => playerActions.seek(value.firstTimestamp)}
          >
            Skip to First Message
          </Button>
        </Center>
      );
    } else {
      body = (
        <Box sx={{ paddingInline: 2 }}>
          <JsonTree key={value.topicId} src={value.current.data} />
        </Box>
      );
    }

    content = width != null && (
      <Stack
        spacing={2}
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <TimelineChart
          width={width}
          records={value.records}
          timestep={value.timestep}
          timestamp={value.timestamp}
          currentRecord={value.current}
        />
        <Divider />
        <Box sx={{ flex: 1, minHeight: 0, overflowY: "auto" }}>{body}</Box>
        {isPlaceholderSnapshot && <LoadingFeedback description="data points" />}
      </Stack>
    );
  }

  return <PanelLayout contentRef={ref}>{content}</PanelLayout>;
}

const G = styled("g")({
  "& circle": {
    stroke: "white",
    strokeWidth: 1,
  },
});

function TimelineChart({
  width,
  records,
  timestep,
  timestamp,
  currentRecord,
}: {
  width: number;
  records: ReadonlyArray<PlayerRecord<"default">>;
  timestep: TimestepValue;
  timestamp: bigint;
  currentRecord: PlayerRecord<"default"> | null;
}) {
  const windowSize = getWindowSizeForTimestep(timestep);

  const playbackSource = useLoadedPlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const recordWindow = calculateRecordWindow(
    windowSize,
    timestamp,
    playbackSource.bounds,
  );

  const scatterData = records.map((record) => ({
    x: utcToRelativeNanoseconds(
      record.timestamp,
      playbackSource.bounds.startTime,
    ),
    y: record.timestamp === currentRecord?.timestamp ? 0.8 : 0.4,
    id: String(record.timestamp),
  }));

  return (
    <ChartContainer
      width={width}
      height={85}
      xAxis={[
        {
          valueFormatter(value) {
            return formatPlaybackTimestamp(value);
          },
          min: utcToRelativeNanoseconds(
            recordWindow.startTime,
            playbackSource.bounds.startTime,
          ),
          max: utcToRelativeNanoseconds(
            recordWindow.endTime,
            playbackSource.bounds.startTime,
          ),
          tickInterval: calculateWindowTicks(
            recordWindow,
            windowSize / 6n,
            windowSize,
            playbackSource.bounds.startTime,
          ),
        },
      ]}
      series={[
        {
          type: "scatter",
          data: scatterData,
          markerSize: 7,
        },
      ]}
      yAxis={[
        {
          min: 0,
          max: 1,
          colorMap: {
            type: "piecewise",
            thresholds: [0.8],
            colors: ["darkgrey", "tomato"],
          },
        },
      ]}
      margin={{
        top: 30,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <ChartsXAxis position="top" />
      <ChartsGrid vertical />
      <G>
        <ScatterPlot />
      </G>
      <ChartsReferenceLine
        x={utcToRelativeNanoseconds(timestamp, playbackSource.bounds.startTime)}
      />
    </ChartContainer>
  );
}
