import * as z from "zod";
import { ApiVersion } from "~/domain/versioning";
import { layoutProfileVersion } from "./common";
import { layoutProfileDescriptorSchema_0_2_0 } from "./models.0-2-0";
import type * as sdkTypes from "./types";

const futureLayoutProfileDescriptorSchema = z.object({
  name: z.string(),
  version: z.string().transform((value, ctx) => {
    const parseResult = ApiVersion.safeParse(value);
    if (!parseResult.success) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Not a valid semantic version",
        fatal: true,
      });

      return z.NEVER;
    }

    const { version } = parseResult;

    if (!version.gt(layoutProfileVersion)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Version ${version} is not > ${layoutProfileVersion}`,
        fatal: true,
      });

      return z.NEVER;
    }

    return version;
  }),
});

const layoutProfileDescriptorSchema = z.union([
  layoutProfileDescriptorSchema_0_2_0,
  futureLayoutProfileDescriptorSchema,
]);

export function deserializeLayoutProfileDescriptors(
  input: unknown,
): ReadonlyArray<sdkTypes.FoundLayoutProfileDescriptor> {
  return z.array(layoutProfileDescriptorSchema).parse(input);
}

export function serializeLayoutProfileDescriptors(
  profiles:
    | sdkTypes.LayoutProfileDescriptor
    | ReadonlyArray<sdkTypes.LayoutProfileDescriptor>,
): ReadonlyArray<object> {
  if (!Array.isArray(profiles)) {
    profiles = [profiles as sdkTypes.LayoutProfileDescriptor];
  }

  return profiles;
}
