import {
  EditLqsResourceForm,
  typeEncodingOptions,
  useTopic,
  useUpdateTopic,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editTopicSchema } from "../schemas";

export function TopicEditForm() {
  const { topicId } = useTypedParams(lqsRoutePaths.TOPIC_EDIT);

  const lqsNavigator = useLqsNavigator({ toTopicDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="topic"
      registryKey="topic"
      query={useTopic(topicId, { select: selectData })}
      schema={editTopicSchema}
      descriptors={(topic) => [
        { name: "name", type: "text", required: true },
        {
          name: "associatedTopicId",
          type: "foreign-key",
          resourceType: "topic",
          additionalProps: { logId: topic?.logId },
        },
        { name: "baseTimestamp", type: "timestamp" },
        { name: "strict", type: "boolean" },
        { name: "typeName", type: "text" },
        { name: "typeEncoding", type: "select", options: typeEncodingOptions },
        { name: "typeData", type: "text", multiline: true, monospace: true },
        { name: "typeSchema", type: "json" },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateTopic(topicId)}
      detailsLocation={lqsNavigator.toTopicDetails({ topicId })}
    />
  );
}
