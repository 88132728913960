import * as z from "zod";
import { v } from "~/domain/versioning";
import { SplitOrientation, VisualizationType } from "../constants";
import { imageFlipDirectionSchema, imageRotationSchema } from "./common";
import { layoutProfileDescriptorSchema_0_0_0 } from "./models.0-0-0";
import type * as sdkTypes from "./types";
import { createMigratableSchema, versionSchema } from "./utils";

export const V_0_1_0 = v(0, 1, 0);

const panelNodeDescriptorSchema: z.ZodType<sdkTypes.PanelNodeDescriptor> =
  z.object({
    id: z.number(),
    size: z.number(),
    panelId: z.number(),
  });

const containerNodeDescriptorSchema: z.ZodType<sdkTypes.ContainerNodeDescriptor> =
  z.object({
    id: z.number(),
    size: z.number(),
    orientation: z.nativeEnum(SplitOrientation),
    children: z.tuple([
      z.lazy(() => layoutNodeDescriptorSchema),
      z.lazy(() => layoutNodeDescriptorSchema),
    ]),
  });

const layoutNodeDescriptorSchema: z.ZodType<sdkTypes.LayoutNodeDescriptor> =
  z.union([panelNodeDescriptorSchema, containerNodeDescriptorSchema]);

const baseTopicDescriptorSchema = z.object({
  name: z.string(),
});

const basePanelDescriptorSchema = z.object({
  id: z.number(),
  title: z.string().nullable(),
});

const uninitializedPanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(null),
  topics: z.tuple([]),
});

const timelineTopicDescriptorSchema = baseTopicDescriptorSchema;

const timelinePanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(VisualizationType.Timeline),
  topics: z.tuple([timelineTopicDescriptorSchema]),
});

const chartTopicDescriptorSchema = baseTopicDescriptorSchema.extend({
  fields: z.array(z.string()).max(3),
});

const chartPanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(VisualizationType.Chart),
  topics: z.tuple([chartTopicDescriptorSchema]),
});

const imageTopicDescriptorSchema = baseTopicDescriptorSchema.extend({
  colorize: z.boolean(),
  brightnessPct: z.number().min(0).max(5),
  contrastPct: z.number().min(0).max(2),
  rotationDeg: imageRotationSchema,
  flip: imageFlipDirectionSchema.nullable(),
});

const imagePanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(VisualizationType.Image),
  topics: z.tuple([imageTopicDescriptorSchema]),
});

const mapTopicDescriptorSchema = baseTopicDescriptorSchema;

const mapPanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(VisualizationType.Map),
  topics: z.union([
    z.tuple([mapTopicDescriptorSchema]),
    z.tuple([mapTopicDescriptorSchema, mapTopicDescriptorSchema]),
  ]),
});

const pointCloudTopicDescriptorSchema = baseTopicDescriptorSchema.extend({
  size: z.number().min(0).max(0.1),
});

const threeDPanelDescriptorSchema = basePanelDescriptorSchema.extend({
  visualization: z.literal(VisualizationType.ThreeD),
  topics: z.tuple([pointCloudTopicDescriptorSchema]),
});

const panelDescriptorSchema = z.union([
  uninitializedPanelDescriptorSchema,
  timelinePanelDescriptorSchema,
  chartPanelDescriptorSchema,
  imagePanelDescriptorSchema,
  mapPanelDescriptorSchema,
  threeDPanelDescriptorSchema,
]);

const baseLayoutProfileDescriptorSchema = z.object({
  name: z.string(),
  version: versionSchema(V_0_1_0),
  layout: layoutNodeDescriptorSchema,
  panels: z.array(panelDescriptorSchema),
});

export type LayoutProfileDescriptor_0_1_0 = z.infer<
  typeof baseLayoutProfileDescriptorSchema
>;

export const layoutProfileDescriptorSchema_0_1_0 = createMigratableSchema({
  next: baseLayoutProfileDescriptorSchema,
  previous: layoutProfileDescriptorSchema_0_0_0,
  migrate(value) {
    return {
      ...value,
      version: V_0_1_0.toString(),
      panels: value.panels.map((panel) => ({
        ...panel,
        title: panel.visualization === null ? null : panel.topics[0].name,
      })),
    };
  },
});
