import { SourceTopicSelect } from "../../components";
import type { TimelinePanel } from "../../panels";
import { usePairedTopics } from "../../panels";

export function TimelineVisualizationControls({
  panel,
}: {
  panel: TimelinePanel;
}) {
  const pairedTopics = usePairedTopics(panel);
  const [primaryPair] = pairedTopics;

  return <SourceTopicSelect panel={panel} topic={primaryPair.topic} />;
}
