import * as z from "zod";
import { SplitOrientation, VisualizationType } from "../constants";
import * as constraints from "../constraints";
import {
  imageFlipDirectionSchema,
  imageRotationSchema,
  layoutProfileVersion,
} from "./common";
import { layoutProfileDescriptorSchema_0_1_0 } from "./models.0-1-0";
import type * as sdkTypes from "./types";
import { createMigratableSchema, versionSchema } from "./utils";

const panelNodeDescriptorSchema: z.ZodType<sdkTypes.PanelNodeDescriptor> =
  z.object({
    id: z.number(),
    size: z.number(),
    panelId: z.number(),
  });

const containerNodeDescriptorSchema: z.ZodType<sdkTypes.ContainerNodeDescriptor> =
  z.object({
    id: z.number(),
    size: z.number(),
    orientation: z.nativeEnum(SplitOrientation),
    children: z.tuple([
      z.lazy(() => layoutNodeDescriptorSchema),
      z.lazy(() => layoutNodeDescriptorSchema),
    ]),
  });

const layoutNodeDescriptorSchema: z.ZodType<sdkTypes.LayoutNodeDescriptor> =
  z.union([panelNodeDescriptorSchema, containerNodeDescriptorSchema]);

const baseTopicDescriptorSchema = z.object({
  name: z.string(),
});

const basePanelDescriptorSchema = z.object({
  id: z.number(),
  title: z.string().nullable(),
});

const uninitializedPanelDescriptorSchema: z.ZodType<sdkTypes.UninitializedPanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(null),
    topics: z.tuple([]),
  });

const timelineTopicDescriptorSchema: z.ZodType<sdkTypes.TimelineTopicDescriptor> =
  baseTopicDescriptorSchema;

const timelinePanelDescriptorSchema: z.ZodType<sdkTypes.TimelinePanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(VisualizationType.Timeline),
    topics: z.tuple([timelineTopicDescriptorSchema]),
  });

const chartTopicDescriptorSchema: z.ZodType<sdkTypes.ChartTopicDescriptor> =
  baseTopicDescriptorSchema.extend({
    fields: z.array(z.string()).max(constraints.chartFields.max),
  });

const chartPanelDescriptorSchema: z.ZodType<sdkTypes.ChartPanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(VisualizationType.Chart),
    topics: z.tuple([chartTopicDescriptorSchema]),
  });

const imageTopicDescriptorSchema: z.ZodType<sdkTypes.ImageTopicDescriptor> =
  baseTopicDescriptorSchema.extend({
    colorize: z.boolean(),
    brightnessPct: z
      .number()
      .min(constraints.imageBrightnessPct.min)
      .max(constraints.imageBrightnessPct.max),
    contrastPct: z
      .number()
      .min(constraints.imageContrastPct.min)
      .max(constraints.imageContrastPct.max),
    rotationDeg: imageRotationSchema,
    flip: imageFlipDirectionSchema.nullable(),
  });

const imagePanelDescriptorSchema: z.ZodType<sdkTypes.ImagePanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(VisualizationType.Image),
    topics: z.tuple([imageTopicDescriptorSchema]),
  });

const mapTopicDescriptorSchema: z.ZodType<sdkTypes.MapTopicDescriptor> =
  baseTopicDescriptorSchema;

const mapPanelDescriptorSchema: z.ZodType<sdkTypes.MapPanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(VisualizationType.Map),
    topics: z.union([
      z.tuple([mapTopicDescriptorSchema]),
      z.tuple([mapTopicDescriptorSchema, mapTopicDescriptorSchema]),
    ]),
  });

const pointCloudTopicDescriptorSchema: z.ZodType<sdkTypes.PointCloudTopicDescriptor> =
  baseTopicDescriptorSchema.extend({
    size: z
      .number()
      .min(constraints.pointSize.min)
      .max(constraints.pointSize.max),
  });

const threeDPanelDescriptorSchema: z.ZodType<sdkTypes.ThreeDPanelDescriptor> =
  basePanelDescriptorSchema.extend({
    visualization: z.literal(VisualizationType.ThreeD),
    topics: z.tuple([pointCloudTopicDescriptorSchema]),
  });

const panelDescriptorSchema: z.ZodType<sdkTypes.PanelDescriptor> = z.union([
  uninitializedPanelDescriptorSchema,
  timelinePanelDescriptorSchema,
  chartPanelDescriptorSchema,
  imagePanelDescriptorSchema,
  mapPanelDescriptorSchema,
  threeDPanelDescriptorSchema,
]);

const baseLayoutProfileDescriptorSchema = z.object({
  name: z.string(),
  version: versionSchema(layoutProfileVersion),
  layout: layoutNodeDescriptorSchema,
  panels: z.array(panelDescriptorSchema),
}) satisfies z.ZodType<sdkTypes.LayoutProfileDescriptor, z.ZodTypeDef, unknown>;

export type LayoutProfileDescriptor_0_2_0 = z.infer<
  typeof baseLayoutProfileDescriptorSchema
>;

export const layoutProfileDescriptorSchema_0_2_0 = createMigratableSchema({
  next: baseLayoutProfileDescriptorSchema,
  previous: layoutProfileDescriptorSchema_0_1_0,
  migrate(value) {
    return {
      ...value,
      version: layoutProfileVersion.toString(),
    };
  },
});
