import { useMemo } from "react";
import type { AsyncOperationSnapshot } from "~/types";
import { useUpdatePanelBuffering } from "../../hooks";
import type { ChartTopicDescriptor, PairedTopic } from "../../panels";
import { useLoadedPlaybackSource, usePlaybackSettings } from "../../playback";
import type {
  LimitedRecordsRequest,
  RecordStore,
  StoreSnapshot,
} from "../../record-store";
import {
  getFrequencyForTimestep,
  NoRecordsError,
  useStoreSnapshot,
} from "../../record-store";
import type { TimestepValue } from "../../types";

interface StoreRequest {
  pair: PairedTopic<ChartTopicDescriptor>;
  timestep: TimestepValue;
  timestamp: bigint;
}

export interface ChartSelection {
  fields: ReadonlyArray<string>;
  data: object | null;
}

function createRecordRequest(
  pair: PairedTopic<ChartTopicDescriptor>,
  timestep: TimestepValue,
  timestamp: bigint,
): LimitedRecordsRequest<"default"> {
  return {
    recordType: "default",
    topicId: pair.topic.id,
    frequency: getFrequencyForTimestep(timestep),
    timestamp,
    count: 1,
  };
}

function subscribeToStore(
  pair: PairedTopic<ChartTopicDescriptor>,
  timestep: TimestepValue,
  timestamp: bigint,
  store: RecordStore,
  notify: () => void,
): () => void {
  return store.subscribe({
    ...createRecordRequest(pair, timestep, timestamp),
    limit: 1,
    topicStartTime: pair.topic.startTime,
    topicEndTime: pair.topic.endTime,
    prefetchBehind: 0,
    prefetchAhead: 0,
    notify,
  });
}

function getSnapshot(
  pair: PairedTopic<ChartTopicDescriptor>,
  timestep: TimestepValue,
  timestamp: bigint,
  store: RecordStore,
): AsyncOperationSnapshot<ChartSelection> {
  const response = store.getRecords(
    createRecordRequest(pair, timestep, timestamp),
  );

  if (response.status !== "fulfilled") {
    return response;
  }

  if (response.value.length === 0) {
    return {
      status: "rejected",
      reason: new NoRecordsError(),
    };
  }

  return {
    status: "fulfilled",
    value: {
      fields: pair.descriptor.fields,
      data: response.value[0].data,
    },
  };
}

export function useSelectionRecord({
  pair,
}: {
  pair: PairedTopic<ChartTopicDescriptor>;
}): [
  snapshot: StoreSnapshot<StoreRequest, ChartSelection>,
  isPlaceholderSnapshot: boolean,
] {
  const { timestep } = usePlaybackSettings();
  const playbackSource = useLoadedPlaybackSource();

  const storeSnapshotResult = useStoreSnapshot(
    useMemo(() => {
      const request: StoreRequest = {
        pair,
        timestep,
        timestamp: playbackSource.timestamp,
      };

      return {
        request,
        subscribe: subscribeToStore.bind(
          null,
          pair,
          timestep,
          playbackSource.timestamp,
        ),
        getSnapshot: getSnapshot.bind(
          null,
          pair,
          timestep,
          playbackSource.timestamp,
        ),
      };
    }, [pair, timestep, playbackSource.timestamp]),
  );

  useUpdatePanelBuffering(
    storeSnapshotResult.snapshot.status === "pending" ||
      storeSnapshotResult.isPlaceholder,
  );

  return [storeSnapshotResult.snapshot, storeSnapshotResult.isPlaceholder];
}
