import { useMemo } from "react";
import { invariant } from "~/lib/invariant";
import type { Topic } from "~/lqs";
import { requireNonNullish } from "~/utils/assertions";
import { useLoadedPlayerTopics } from "../hooks";
import { usePanelContext } from "./nodes";
import type { AnyTopicDescriptor, InitializedPanel } from "./types";
import { checkIsPanelInitialized } from "./utils";

export function useInitializedPanel(): InitializedPanel {
  const panel = usePanelContext();

  invariant(checkIsPanelInitialized(panel), "Panel is not initialized");

  return panel;
}

export interface PairedTopic<TDescriptor extends AnyTopicDescriptor> {
  descriptor: TDescriptor;
  topic: Topic;
}

interface NullablePairedTopic<TDescriptor extends AnyTopicDescriptor> {
  descriptor: TDescriptor;
  topic: Topic | null;
}

export type PairedTopics<TDescriptors extends InitializedPanel["topics"]> = {
  [Index in keyof TDescriptors]: PairedTopic<TDescriptors[Index]>;
};

export type NullablePairedTopics<
  TDescriptors extends InitializedPanel["topics"],
> = {
  [Index in keyof TDescriptors]: NullablePairedTopic<TDescriptors[Index]>;
};

export function usePairedTopics<TPanel extends InitializedPanel>(
  panel: TPanel,
): PairedTopics<TPanel["topics"]>;
export function usePairedTopics<TPanel extends InitializedPanel>(
  panel: TPanel,
  options: { strict: false },
): NullablePairedTopics<TPanel["topics"]>;
export function usePairedTopics<TPanel extends InitializedPanel>(
  panel: TPanel,
  { strict = true } = {},
): NullablePairedTopics<TPanel["topics"]> | PairedTopics<TPanel["topics"]> {
  const playerTopics = useLoadedPlayerTopics();

  return useMemo(
    () =>
      panel.topics.map((descriptor) => {
        const topic =
          playerTopics.find((topic) => topic.name === descriptor.name) ?? null;

        return {
          descriptor,
          topic: !strict ? topic : requireNonNullish(topic),
        };
      }) as any,
    [panel.topics, playerTopics, strict],
  );
}

export function checkIsPairedTopics<
  TDescriptors extends InitializedPanel["topics"],
>(
  nullablePairedTopics: NullablePairedTopics<TDescriptors>,
): nullablePairedTopics is PairedTopics<TDescriptors> {
  return nullablePairedTopics.every(({ topic }) => topic != null);
}
