import React from "react";
import { DoNotDisturb } from "@mui/icons-material";
import { Loading } from "~/components/Loading";
import { ErrorMessage } from "~/components/error-message";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils";
import { PanelLayout, TopicName } from "../components";
import type { InitializedPanel } from "../panels";
import {
  checkIsPairedTopics,
  checkIsPanelInitialized,
  usePairedTopics,
  usePanelContext,
  VisualizationType,
} from "../panels";
import { usePlaybackSource } from "../playback";
import { checkIsBoundedTopic } from "../utils";
import { ChartVisualization } from "./chart-visualization";
import { ImageVisualization } from "./image-visualization";
import { MapVisualization } from "./map-visualization";
import { ThreeDVisualization } from "./three-d-visualization";
import { TimelineVisualization } from "./timeline-visualization";
import { TopicSelector } from "./topic-selector";

export function VisualizationSwitch({
  topics,
}: {
  topics: ReadonlyArray<Topic>;
}) {
  const playbackSource = usePlaybackSource();

  const panel = usePanelContext();

  if (playbackSource.isLoading) {
    // Visualizations expect playback source to be loaded when they're rendered
    return <Loading type="circular" />;
  } else if (!checkIsPanelInitialized(panel)) {
    return <TopicSelector panel={panel} topics={topics} />;
  } else {
    return <InitializedPanelSwitch panel={panel} />;
  }
}

function InitializedPanelSwitch({ panel }: { panel: InitializedPanel }) {
  const pairedTopics = usePairedTopics(panel, { strict: false });

  if (!checkIsPairedTopics(pairedTopics)) {
    return (
      <PanelLayout>
        <ErrorMessage
          disableTypography
          icon={<DoNotDisturb fontSize="large" color="error" />}
        >
          <ErrorMessage.Paragraph>
            Some topics are not in this log:
          </ErrorMessage.Paragraph>
          <ul>
            {pairedTopics.map(({ descriptor, topic }) => {
              if (topic != null) {
                return null;
              }

              return (
                <li key={descriptor.name}>
                  <TopicName monospace>{descriptor.name}</TopicName>
                </li>
              );
            })}
          </ul>
        </ErrorMessage>
      </PanelLayout>
    );
  }

  if (!pairedTopics.every(({ topic }) => checkIsBoundedTopic(topic))) {
    return (
      <PanelLayout>
        <ErrorMessage
          disableTypography
          icon={<DoNotDisturb fontSize="large" color="error" />}
        >
          <ErrorMessage.Paragraph>
            Some topics do not have any records:
          </ErrorMessage.Paragraph>
          <ul>
            {pairedTopics.map(({ descriptor, topic }) => {
              if (checkIsBoundedTopic(topic)) {
                return null;
              }

              return (
                <li key={descriptor.name}>
                  <TopicName monospace>{descriptor.name}</TopicName>
                </li>
              );
            })}
          </ul>
        </ErrorMessage>
      </PanelLayout>
    );
  }

  switch (panel.visualization) {
    case VisualizationType.Timeline: {
      return <TimelineVisualization panel={panel} />;
    }
    case VisualizationType.Chart: {
      return <ChartVisualization panel={panel} />;
    }
    case VisualizationType.Image: {
      return <ImageVisualization panel={panel} />;
    }
    case VisualizationType.Map: {
      return <MapVisualization panel={panel} />;
    }
    case VisualizationType.ThreeD: {
      return <ThreeDVisualization panel={panel} />;
    }
    default: {
      assertNever(panel);
    }
  }
}
