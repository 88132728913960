/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  HTTPValidationError,
  IngestionCreateRequest,
  IngestionDataResponse,
  IngestionListResponse,
  IngestionPartDataResponse,
  IngestionPartForIngestionCreateRequest,
  IngestionPartListResponse,
  IngestionPartUpdateRequest,
  IngestionUpdateRequest,
} from "../models";
import {
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  IngestionCreateRequestFromJSON,
  IngestionCreateRequestToJSON,
  IngestionDataResponseFromJSON,
  IngestionDataResponseToJSON,
  IngestionListResponseFromJSON,
  IngestionListResponseToJSON,
  IngestionPartDataResponseFromJSON,
  IngestionPartDataResponseToJSON,
  IngestionPartForIngestionCreateRequestFromJSON,
  IngestionPartForIngestionCreateRequestToJSON,
  IngestionPartListResponseFromJSON,
  IngestionPartListResponseToJSON,
  IngestionPartUpdateRequestFromJSON,
  IngestionPartUpdateRequestToJSON,
  IngestionUpdateRequestFromJSON,
  IngestionUpdateRequestToJSON,
} from "../models";

export interface CreateIngestionRequest {
  ingestionCreateRequest: IngestionCreateRequest;
  lockToken?: string | null;
}

export interface CreateIngestionPartForIngestionRequest {
  ingestionId: string;
  ingestionPartForIngestionCreateRequest: IngestionPartForIngestionCreateRequest;
  lockToken?: string | null;
}

export interface DeleteIngestionRequest {
  ingestionId: string;
  lockToken?: string | null;
}

export interface DeleteIngestionPartForIngestionRequest {
  ingestionPartId: string;
  ingestionId: string | null;
  lockToken?: string | null;
}

export interface FetchIngestionRequest {
  ingestionId: string;
}

export interface FetchIngestionPartForIngestionRequest {
  ingestionPartId: string;
  ingestionId: string | null;
}

export interface ListIngestionPartsForIngestionRequest {
  ingestionId: string | null;
  id?: string | null;
  groupId?: string | null;
  logId?: string | null;
  sequence?: number | null;
  source?: string | null;
  workflowId?: string | null;
  workflowIdNull?: boolean | null;
  state?: string | null;
  includeCount?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface ListIngestionsRequest {
  id?: string | null;
  groupId?: string | null;
  logId?: string | null;
  objectStoreId?: string | null;
  name?: string | null;
  nameLike?: string | null;
  objectKey?: string | null;
  objectKeyLike?: string | null;
  workflowId?: string | null;
  workflowIdNull?: boolean | null;
  workflowContextFilter?: string | null;
  state?: string | null;
  progressNull?: boolean | null;
  progressGte?: number | null;
  progressLte?: number | null;
  errorFilter?: string | null;
  errorPayloadFilter?: string | null;
  noteLike?: string | null;
  contextFilter?: string | null;
  includeCount?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateIngestionRequest {
  ingestionId: string;
  ingestionUpdateRequest: IngestionUpdateRequest;
  lockToken?: string | null;
}

export interface UpdateIngestionPartForIngestionRequest {
  ingestionPartId: string;
  ingestionId: string | null;
  ingestionPartUpdateRequest: IngestionPartUpdateRequest;
  lockToken?: string | null;
}

export class IngestionApi extends runtime.BaseAPI {
  async createIngestion(
    this: IngestionApi,
    requestParameters: CreateIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IngestionDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.ingestionCreateRequest,
      "ingestionCreateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/ingestions`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: IngestionCreateRequestToJSON(
          requestParameters.ingestionCreateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return IngestionDataResponseFromJSON(jsonValue);
  }

  async createIngestionPartForIngestion(
    this: IngestionApi,
    requestParameters: CreateIngestionPartForIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IngestionPartDataResponse> {
    runtime.assertParamIsPresent(requestParameters.ingestionId, "ingestionId");

    runtime.assertParamIsPresent(
      requestParameters.ingestionPartForIngestionCreateRequest,
      "ingestionPartForIngestionCreateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/ingestions/{ingestion_id}/parts`.replace(
          `{${"ingestion_id"}}`,
          encodeURIComponent(String(requestParameters.ingestionId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: IngestionPartForIngestionCreateRequestToJSON(
          requestParameters.ingestionPartForIngestionCreateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return IngestionPartDataResponseFromJSON(jsonValue);
  }

  async deleteIngestion(
    this: IngestionApi,
    requestParameters: DeleteIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.ingestionId, "ingestionId");

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/ingestions/{ingestion_id}`.replace(
          `{${"ingestion_id"}}`,
          encodeURIComponent(String(requestParameters.ingestionId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async deleteIngestionPartForIngestion(
    this: IngestionApi,
    requestParameters: DeleteIngestionPartForIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(
      requestParameters.ingestionPartId,
      "ingestionPartId",
    );

    runtime.assertParamIsPresent(requestParameters.ingestionId, "ingestionId");

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/ingestions/{ingestion_id}/parts/{ingestion_part_id}`
          .replace(
            `{${"ingestion_part_id"}}`,
            encodeURIComponent(String(requestParameters.ingestionPartId)),
          )
          .replace(
            `{${"ingestion_id"}}`,
            encodeURIComponent(String(requestParameters.ingestionId)),
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchIngestion(
    this: IngestionApi,
    requestParameters: FetchIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IngestionDataResponse> {
    runtime.assertParamIsPresent(requestParameters.ingestionId, "ingestionId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/ingestions/{ingestion_id}`.replace(
          `{${"ingestion_id"}}`,
          encodeURIComponent(String(requestParameters.ingestionId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return IngestionDataResponseFromJSON(jsonValue);
  }

  async fetchIngestionPartForIngestion(
    this: IngestionApi,
    requestParameters: FetchIngestionPartForIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IngestionPartDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.ingestionPartId,
      "ingestionPartId",
    );

    runtime.assertParamIsPresent(requestParameters.ingestionId, "ingestionId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/ingestions/{ingestion_id}/parts/{ingestion_part_id}`
          .replace(
            `{${"ingestion_part_id"}}`,
            encodeURIComponent(String(requestParameters.ingestionPartId)),
          )
          .replace(
            `{${"ingestion_id"}}`,
            encodeURIComponent(String(requestParameters.ingestionId)),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return IngestionPartDataResponseFromJSON(jsonValue);
  }

  async listIngestionPartsForIngestion(
    this: IngestionApi,
    requestParameters: ListIngestionPartsForIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IngestionPartListResponse> {
    runtime.assertParamIsPresent(requestParameters.ingestionId, "ingestionId");

    const queryParameters: any = {};

    if (requestParameters.id != null) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.groupId != null) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.logId != null) {
      queryParameters["log_id"] = requestParameters.logId;
    }

    if (requestParameters.sequence != null) {
      queryParameters["sequence"] = requestParameters.sequence;
    }

    if (requestParameters.source != null) {
      queryParameters["source"] = requestParameters.source;
    }

    if (requestParameters.workflowId != null) {
      queryParameters["workflow_id"] = requestParameters.workflowId;
    }

    if (requestParameters.workflowIdNull != null) {
      queryParameters["workflow_id_null"] = requestParameters.workflowIdNull;
    }

    if (requestParameters.state != null) {
      queryParameters["state"] = requestParameters.state;
    }

    if (requestParameters.includeCount != null) {
      queryParameters["include_count"] = requestParameters.includeCount;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/ingestions/{ingestion_id}/parts`.replace(
          `{${"ingestion_id"}}`,
          encodeURIComponent(String(requestParameters.ingestionId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return IngestionPartListResponseFromJSON(jsonValue);
  }

  async listIngestions(
    this: IngestionApi,
    requestParameters: ListIngestionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IngestionListResponse> {
    const queryParameters: any = {};

    if (requestParameters.id != null) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.groupId != null) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.logId != null) {
      queryParameters["log_id"] = requestParameters.logId;
    }

    if (requestParameters.objectStoreId != null) {
      queryParameters["object_store_id"] = requestParameters.objectStoreId;
    }

    if (requestParameters.name != null) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike != null) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.objectKey != null) {
      queryParameters["object_key"] = requestParameters.objectKey;
    }

    if (requestParameters.objectKeyLike != null) {
      queryParameters["object_key_like"] = requestParameters.objectKeyLike;
    }

    if (requestParameters.workflowId != null) {
      queryParameters["workflow_id"] = requestParameters.workflowId;
    }

    if (requestParameters.workflowIdNull != null) {
      queryParameters["workflow_id_null"] = requestParameters.workflowIdNull;
    }

    if (requestParameters.workflowContextFilter != null) {
      queryParameters["workflow_context_filter"] =
        requestParameters.workflowContextFilter;
    }

    if (requestParameters.state != null) {
      queryParameters["state"] = requestParameters.state;
    }

    if (requestParameters.progressNull != null) {
      queryParameters["progress_null"] = requestParameters.progressNull;
    }

    if (requestParameters.progressGte != null) {
      queryParameters["progress_gte"] = requestParameters.progressGte;
    }

    if (requestParameters.progressLte != null) {
      queryParameters["progress_lte"] = requestParameters.progressLte;
    }

    if (requestParameters.errorFilter != null) {
      queryParameters["error_filter"] = requestParameters.errorFilter;
    }

    if (requestParameters.errorPayloadFilter != null) {
      queryParameters["error_payload_filter"] =
        requestParameters.errorPayloadFilter;
    }

    if (requestParameters.noteLike != null) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.contextFilter != null) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.includeCount != null) {
      queryParameters["include_count"] = requestParameters.includeCount;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/ingestions`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return IngestionListResponseFromJSON(jsonValue);
  }

  async updateIngestion(
    this: IngestionApi,
    requestParameters: UpdateIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IngestionDataResponse> {
    runtime.assertParamIsPresent(requestParameters.ingestionId, "ingestionId");

    runtime.assertParamIsPresent(
      requestParameters.ingestionUpdateRequest,
      "ingestionUpdateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/ingestions/{ingestion_id}`.replace(
          `{${"ingestion_id"}}`,
          encodeURIComponent(String(requestParameters.ingestionId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: IngestionUpdateRequestToJSON(
          requestParameters.ingestionUpdateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return IngestionDataResponseFromJSON(jsonValue);
  }

  async updateIngestionPartForIngestion(
    this: IngestionApi,
    requestParameters: UpdateIngestionPartForIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IngestionPartDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.ingestionPartId,
      "ingestionPartId",
    );

    runtime.assertParamIsPresent(requestParameters.ingestionId, "ingestionId");

    runtime.assertParamIsPresent(
      requestParameters.ingestionPartUpdateRequest,
      "ingestionPartUpdateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/ingestions/{ingestion_id}/parts/{ingestion_part_id}`
          .replace(
            `{${"ingestion_part_id"}}`,
            encodeURIComponent(String(requestParameters.ingestionPartId)),
          )
          .replace(
            `{${"ingestion_id"}}`,
            encodeURIComponent(String(requestParameters.ingestionId)),
          ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: IngestionPartUpdateRequestToJSON(
          requestParameters.ingestionPartUpdateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return IngestionPartDataResponseFromJSON(jsonValue);
  }
}
