import React from "react";
import type { ValueOf } from "type-fest";
import { createSafeContext } from "~/contexts";
import type { VisualizationType } from "../panels";
import type { LimitedRecordsTopicSubscription } from "../record-store";

export type VisualizationStoreParams = {
  [Type in Exclude<VisualizationType, "map">]: Pick<
    LimitedRecordsTopicSubscription<never>,
    "count" | "limit" | "prefetchBehind" | "prefetchAhead"
  >;
};

const [useVisualizationStoreParamsContext, VisualizationStoreParamsContext] =
  createSafeContext<VisualizationStoreParams>("VisualizationStoreParams");

export function useVisualizationStoreParams(
  visualization: keyof VisualizationStoreParams,
): ValueOf<VisualizationStoreParams> {
  const params = useVisualizationStoreParamsContext();

  return params[visualization];
}

export function VisualizationStoreParamsProvider({
  params,
  children,
}: {
  params: VisualizationStoreParams;
  children: React.ReactNode;
}) {
  return (
    <VisualizationStoreParamsContext.Provider value={params}>
      {children}
    </VisualizationStoreParamsContext.Provider>
  );
}
