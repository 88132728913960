import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import type {
  LogInstantTag,
  LogLevelTag,
  LogRangeTag,
  TaggingMode,
} from "~/domain/logs";
import { useLabelColors } from "~/domain/logs";
import { useInsecureHash } from "~/hooks/use-insecure-hash";
import type { Label, Log, Tag, TagForLogCreateRequest } from "~/lqs";
import { selectLabelColor, useCreateTag, useDeleteTag } from "~/lqs";
import type { PlaybackSource } from "~/player";
import { usePlaybackSource, useTaggingMode } from "~/player";
import { assertNever } from "~/utils";
import { getEventHandlerProps } from "~/utils/get-event-handler-props";

export function LabelListItem({
  log,
  label,
  logLevelTagsQuery,
  logInstantTagsQuery,
  logRangeTagsQuery,
}: {
  log: Log;
  label: Label;
  logLevelTagsQuery: UseQueryResult<ReadonlyArray<LogLevelTag>>;
  logInstantTagsQuery: UseQueryResult<ReadonlyArray<LogInstantTag>>;
  logRangeTagsQuery: UseQueryResult<ReadonlyArray<LogRangeTag>>;
}) {
  const labelColorQuery = useInsecureHash(label.value, {
    select: selectLabelColor,
  });

  const labelColors = useLabelColors(label, labelColorQuery.data);

  const playbackSource = usePlaybackSource();

  const taggingMode = useTaggingMode();

  const appliedTagSearchResult = findAppliedTag({
    label,
    playbackSource,
    taggingMode,
    logLevelTagsQuery,
    logInstantTagsQuery,
    logRangeTagsQuery,
  });

  const { enqueueSnackbar } = useSnackbar();

  const createTagMutation = useCreateTag(log.id);
  const deleteTagMutation = useDeleteTag(log.id);

  const toggleTagEnabled =
    appliedTagSearchResult.status !== "pending" &&
    !createTagMutation.isLoading &&
    !deleteTagMutation.isLoading;

  const buttonHandlerProps = getEventHandlerProps(
    "onClick",
    toggleTagEnabled &&
      function handleToggleTag() {
        if (appliedTagSearchResult.status === "found") {
          // Delete existing tag
          deleteTagMutation.mutate(appliedTagSearchResult.tag.id, {
            onError() {
              enqueueSnackbar("Unable to remove tag", { variant: "error" });
            },
          });

          createTagMutation.reset();
        } else {
          // Create new tag
          createTagMutation.mutate(appliedTagSearchResult.request, {
            onError() {
              enqueueSnackbar("Unable to tag log", { variant: "error" });
            },
          });

          deleteTagMutation.reset();
        }
      },
  );

  return (
    <ListItem key={label.id} disablePadding>
      <ListItemButton {...buttonHandlerProps} disableGutters>
        <ListItemIcon>
          <Box sx={{ alignSelf: "center", position: "relative" }}>
            <Checkbox
              tabIndex={-1}
              checked={appliedTagSearchResult.status === "found"}
              disabled={buttonHandlerProps.disabled}
            />
            {(createTagMutation.isLoading || deleteTagMutation.isLoading) && (
              <CircularProgress
                size="2rem"
                sx={{ position: "absolute", inset: 0, margin: "auto" }}
              />
            )}
          </Box>
        </ListItemIcon>
        <Chip
          label={label.value}
          sx={{
            ...(labelColors !== null && {
              color: labelColors.text,
              backgroundColor: labelColors.background,
            }),
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}

type AppliedTagSearchResult =
  | { status: "pending" }
  | { status: "found"; tag: Tag }
  | { status: "not-found"; request: TagForLogCreateRequest };

function findAppliedTag({
  label,
  playbackSource,
  taggingMode,
  logLevelTagsQuery,
  logInstantTagsQuery,
  logRangeTagsQuery,
}: {
  label: Label;
  playbackSource: PlaybackSource;
  taggingMode: TaggingMode;
  logLevelTagsQuery: UseQueryResult<ReadonlyArray<LogLevelTag>>;
  logInstantTagsQuery: UseQueryResult<ReadonlyArray<LogInstantTag>>;
  logRangeTagsQuery: UseQueryResult<ReadonlyArray<LogRangeTag>>;
}): AppliedTagSearchResult {
  if (playbackSource.isLoading) {
    return { status: "pending" };
  }

  switch (taggingMode) {
    case "log": {
      if (!logLevelTagsQuery.isSuccess) {
        return { status: "pending" };
      }

      const foundTag = logLevelTagsQuery.data.find(
        (tag) => tag.labelId === label.id,
      );

      if (foundTag !== undefined) {
        return { status: "found", tag: foundTag };
      } else {
        return {
          status: "not-found",
          request: {
            labelId: label.id,
          },
        };
      }
    }
    case "instant": {
      if (!logInstantTagsQuery.isSuccess) {
        return { status: "pending" };
      }

      const foundTag = logInstantTagsQuery.data.find(
        (tag) =>
          tag.labelId === label.id &&
          tag.startTime === playbackSource.timestamp,
      );

      if (foundTag !== undefined) {
        return { status: "found", tag: foundTag };
      } else {
        return {
          status: "not-found",
          request: {
            labelId: label.id,
            startTime: playbackSource.timestamp,
          },
        };
      }
    }
    case "range": {
      if (!logRangeTagsQuery.isSuccess) {
        return { status: "pending" };
      }

      const foundTag = logRangeTagsQuery.data.find(
        (tag) =>
          tag.labelId === label.id &&
          tag.startTime === playbackSource.range.startTime &&
          tag.endTime === playbackSource.range.endTime,
      );

      if (foundTag !== undefined) {
        return { status: "found", tag: foundTag };
      } else {
        return {
          status: "not-found",
          request: {
            labelId: label.id,
            startTime: playbackSource.range.startTime,
            endTime: playbackSource.range.endTime,
          },
        };
      }
    }
    default: {
      assertNever(taggingMode);
    }
  }
}
