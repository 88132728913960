import * as z from "zod";
import type { ApiVersion } from "~/domain/versioning";
import type {
  FoundLayoutProfileDescriptor,
  FutureLayoutProfileDescriptor,
  LayoutProfileDescriptor,
} from "./types";

export function checkIsFutureLayoutProfileDescriptor(
  descriptor: FoundLayoutProfileDescriptor,
): descriptor is FutureLayoutProfileDescriptor {
  return !("layout" in descriptor);
}

export function checkIsLayoutProfileDescriptor(
  descriptor: FoundLayoutProfileDescriptor,
): descriptor is LayoutProfileDescriptor {
  return "layout" in descriptor;
}

export function versionSchema(
  version: ApiVersion,
): z.ZodType<ApiVersion, z.ZodTypeDef, string> {
  return z.literal(version.toString()).transform(() => version);
}

export function createMigratableSchema<
  TNext extends z.ZodType,
  TPrevious extends z.ZodType,
>({
  next,
  previous,
  migrate,
}: {
  next: TNext;
  previous: TPrevious;
  migrate: (value: z.output<NoInfer<TPrevious>>) => z.input<NoInfer<TNext>>;
}): z.ZodType<z.output<TNext>, z.ZodTypeDef, z.input<TNext>> {
  return z.union([
    next,
    previous.transform((value, ctx) => {
      let result;
      try {
        result = migrate(value);
      } catch (e) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: String(e),
          fatal: true,
        });

        return z.NEVER;
      }

      return next.parse(result);
    }),
  ]);
}
