import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { createSafeContext } from "~/contexts";
import {
  AuthenticationGuard,
  ResetPasswordForm,
  SignInForm,
  SignUpForm,
} from "~/domain/auth";
import { ProfileForm, SuppressProfileBannerProvider } from "~/domain/profile";
import { assertNever } from "~/utils/assertions";

type AuthDialogState =
  | { type: "sign-in"; from?: "forgot-password" }
  | { type: "sign-up" }
  | { type: "forgot-password" }
  | { type: "complete-profile" };

export function AuthDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [authDialogState, setAuthDialogState] = useState<AuthDialogState>({
    type: "sign-in",
  });

  const [shouldPreventClosing, setShouldPreventClosing] = useState(false);

  function handlePreventClosing(): void {
    setShouldPreventClosing(true);
  }

  function handleAllowClosing(): void {
    setShouldPreventClosing(false);
  }

  function handleClose(): void {
    if (shouldPreventClosing) {
      return;
    }

    onClose();
  }

  function handleToSignUp(): void {
    setAuthDialogState({ type: "sign-up" });
  }

  function handleSignUpComplete(): void {
    setAuthDialogState({ type: "complete-profile" });
  }

  function handleProfileUpdateComplete(): void {
    onClose();
  }

  function handleToSignIn(): void {
    setAuthDialogState({ type: "sign-in" });
  }

  function handleSignInComplete(): void {
    onClose();
  }

  function handleToForgotPassword(): void {
    setAuthDialogState({ type: "forgot-password" });
  }

  function handlePasswordResetComplete(): void {
    setAuthDialogState({ type: "sign-in", from: "forgot-password" });
  }

  function handleExited(): void {
    if (
      authDialogState.type === "sign-in" &&
      authDialogState.from === "forgot-password"
    ) {
      setAuthDialogState({ type: "sign-in" });
    }
  }

  let title;
  let content;
  switch (authDialogState.type) {
    case "sign-in": {
      title = "Sign In";
      content = (
        <SignInForm
          onLoadingStart={handlePreventClosing}
          onLoadingEnd={handleAllowClosing}
          onSignInComplete={handleSignInComplete}
          message={
            <>
              {authDialogState.from === "forgot-password" && (
                <Alert variant="outlined" severity="success">
                  <AlertTitle>Password Reset</AlertTitle>
                  You can now sign in with your new password
                </Alert>
              )}
              <Typography>
                Sign in to your LogQS account to create and download extractions
                and more!
              </Typography>
            </>
          }
          forgotPassword={
            <Button
              color="primary"
              variant="text"
              size="small"
              onClick={handleToForgotPassword}
              sx={{ alignSelf: "baseline", mt: -1 }}
            >
              Forgot Password?
            </Button>
          }
          footer={
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
              <Typography>Don't have an account?</Typography>
              <Button
                color="primary"
                variant="text"
                size="small"
                onClick={handleToSignUp}
              >
                Sign up
              </Button>
            </Stack>
          }
        />
      );

      break;
    }
    case "sign-up": {
      title = "Sign Up";
      content = (
        <SignUpForm
          onLoadingStart={handlePreventClosing}
          onLoadingEnd={handleAllowClosing}
          onSignUpComplete={handleSignUpComplete}
          message={
            <Typography>
              With a LogQS account you can create and download extractions and
              more!
            </Typography>
          }
          footer={
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
              <Typography>Already have an account?</Typography>
              <Button
                color="primary"
                variant="text"
                size="small"
                onClick={handleToSignIn}
              >
                Sign in
              </Button>
            </Stack>
          }
        />
      );

      break;
    }
    case "forgot-password": {
      title = "Forgot Password";
      content = (
        <ResetPasswordForm
          onLoadingStart={handlePreventClosing}
          onLoadingEnd={handleAllowClosing}
          onResetComplete={handlePasswordResetComplete}
          footer={
            <Button
              color="primary"
              variant="text"
              size="small"
              onClick={handleToSignIn}
              sx={{ alignSelf: "baseline" }}
            >
              Return To Sign in
            </Button>
          }
        />
      );

      break;
    }
    case "complete-profile": {
      title = "Complete Your Profile";
      content = (
        <ProfileForm
          inDialog
          closeText="Skip for Now"
          onClose={handleClose}
          onLoadingStart={handlePreventClosing}
          onLoadingEnd={handleAllowClosing}
          onUpdateComplete={handleProfileUpdateComplete}
        />
      );

      break;
    }
    default: {
      assertNever(authDialogState);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="auth-dialog-title"
      fullWidth
      maxWidth="sm"
      TransitionProps={{
        onExited: handleExited,
      }}
    >
      <DialogTitle id="auth-dialog-title">{title}</DialogTitle>
      <Tooltip title="Close">
        <IconButton
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            top: theme.spacing(1),
            right: theme.spacing(1),
          })}
        >
          <Close />
        </IconButton>
      </Tooltip>
      <DialogContent sx={{ pt: 0 }}>{content}</DialogContent>
    </Dialog>
  );
}

const [useAuthDialogContext, AuthDialogContext] = createSafeContext<{
  open: () => void;
  close: () => void;
}>("AuthDialog");

export { useAuthDialogContext as useAuthDialogControls };

export function AuthDialogProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [showDialog, setShowDialog] = useState(false);

  function handleOpenDialog(): void {
    setShowDialog(true);
  }

  function handleCloseDialog(): void {
    setShowDialog(false);
  }

  return (
    <AuthDialogContext.Provider
      value={{
        open: handleOpenDialog,
        close: handleCloseDialog,
      }}
    >
      <SuppressProfileBannerProvider suppress={showDialog}>
        {children}
      </SuppressProfileBannerProvider>
      <AuthenticationGuard
        unauthenticatedFallback={
          <AuthDialog open={showDialog} onClose={handleCloseDialog} />
        }
      />
    </AuthDialogContext.Provider>
  );
}
