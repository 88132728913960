import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { Label, Log, Tag, TagDataResponse } from "~/lqs";
import { tagKeys, useDataStoreClients } from "~/lqs";
import { assertNever, scheduleQueriesRemoval } from "~/utils";

export function useBulkManageTags(
  { id: logId }: Log,
  selectedLabelIds: ReadonlyArray<Label["id"]>,
): UseMutationResult<
  // Don't intend to use this in the calling component. Just need to type the
  // data passed to the `onSuccess` handler for doing cache management.
  ReadonlyArray<PromiseFulfilledResult<TagDataResponse | void>>,
  unknown,
  { appliedTags: ReadonlyArray<Tag>; operation: "create" | "delete" }
> {
  const queryClient = useQueryClient();

  const { logApi } = useDataStoreClients();

  return useMutation({
    async mutationFn({ appliedTags, operation }) {
      let results: ReadonlyArray<PromiseSettledResult<TagDataResponse | void>>;
      switch (operation) {
        case "create": {
          const missingLabelIds = selectedLabelIds.filter(
            (labelId) => !appliedTags.some((tag) => labelId === tag.labelId),
          );

          results = await Promise.allSettled(
            missingLabelIds.map((labelId) =>
              logApi.createTagForLog({
                logId,
                tagForLogCreateRequest: { labelId },
              }),
            ),
          );

          break;
        }
        case "delete": {
          results = await Promise.allSettled(
            appliedTags.map((tag) =>
              logApi.deleteTagForLog({ logId, tagId: tag.id }),
            ),
          );

          break;
        }
        default: {
          assertNever(operation);
        }
      }

      if (!results.every((result) => result.status === "fulfilled")) {
        throw new Error("Not all operations succeeded");
      }

      return results;
    },
    onSuccess(results, { appliedTags, operation }) {
      switch (operation) {
        case "create": {
          results.forEach((result) => {
            const response = result.value as TagDataResponse;

            queryClient.setQueryData<TagDataResponse>(
              tagKeys.fetch(logId, response.data.id),
              response,
            );
          });

          break;
        }
        case "delete": {
          scheduleQueriesRemoval(() => {
            appliedTags.forEach((tag) => {
              queryClient.removeQueries({
                queryKey: tagKeys.fetch(logId, tag.id),
              });
            });
          });

          break;
        }
        default: {
          assertNever(operation);
        }
      }

      queryClient.removeQueries({
        queryKey: tagKeys.lists(logId),
        type: "inactive",
      });

      return queryClient.invalidateQueries({ queryKey: tagKeys.lists(logId) });
    },
  });
}
