/* eslint-disable react/no-unknown-property --
 * Lint rule errors on react-three-fiber primitives. Disabling solely for this
 * file. TypeScript should still catch an invalid props.
 */

import React, { useRef } from "react";
import { Button, styled, Typography } from "@mui/material";
import { TimerSand } from "mdi-material-ui";
import { Center } from "~/components/Center";
import { ErrorMessage } from "~/components/error-message";
import type { Group, ThreeEvent } from "~/lib/three";
import {
  CameraActions,
  CameraControls,
  Canvas,
  Matrix4,
  Quaternion,
  TransformControls,
  useThree,
} from "~/lib/three";
import { usePlayerActions } from "../../actions";
import { LoadingFeedback, PanelLayout, TopicName } from "../../components";
import { usePlayerConfig } from "../../config";
import type { ThreeDPanel } from "../../panels";
import { RecordStoreError } from "../../record-store";
import type { TransformNode } from "./transforms";
import type { ThreeDObject, ThreeDRecords } from "./use-three-d-records";
import { useThreeDRecords } from "./use-three-d-records";

const StyledCanvas = styled(Canvas)({
  touchAction: "none",
  cursor: "grab",
  "&:active": {
    cursor: "grabbing",
  },
});

export function ThreeDVisualization({ panel }: { panel: ThreeDPanel }) {
  const { threeDInteractivityIndicator } = usePlayerConfig();

  const playerActions = usePlayerActions();

  const [threeDSnapshot, isPlaceholderSnapshot] = useThreeDRecords({ panel });

  function handleDisableStaticTransforms(): void {
    playerActions.toggleStaticTransforms(panel, false);
  }

  let content;
  if (threeDSnapshot.status === "pending") {
    content = <LoadingFeedback description="3D data" />;
  } else if (threeDSnapshot.status === "rejected") {
    if (
      threeDSnapshot.reason instanceof RecordStoreError &&
      threeDSnapshot.reason.topic?.name === "/tf_static"
    ) {
      content = (
        <ErrorMessage disableTypography>
          <ErrorMessage.Paragraph>
            Unable to load <TopicName monospace>/tf_static</TopicName> records
          </ErrorMessage.Paragraph>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleDisableStaticTransforms}
          >
            Disable static transforms
          </Button>
        </ErrorMessage>
      );
    } else {
      content = <ErrorMessage>An error occurred</ErrorMessage>;
    }
  } else {
    const { value } = threeDSnapshot;

    content = (
      <>
        <StyledCanvas
          frameloop="demand"
          camera={{
            // Position camera behind and above origin, looking towards
            // positive Z.
            position: [-5, 5, -5],
          }}
          raycaster={{
            // Only trying to set the `Points` property here but the types say
            // I need to provide everything. `Points` is the only non-default
            // value.
            params: {
              Points: {
                // TODO: Figure out how to make this work with multiple objects
                //  of different sizes.
                threshold: value.objects.at(0)?.size ?? 0,
              },
              Mesh: {},
              Line: {
                threshold: 1,
              },
              LOD: {},
              Sprite: {},
            },
          }}
        >
          <Scene value={value} />
        </StyledCanvas>
        {value.firstTimestamp != null && (
          <Center sx={{ position: "absolute", left: 0, top: 0 }}>
            <TimerSand fontSize="large" />
            <Typography variant="h5" component="p" paragraph>
              No recent point cloud
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => playerActions.seek(value.firstTimestamp!)}
            >
              Skip to First Point Cloud
            </Button>
          </Center>
        )}
      </>
    );
  }

  return (
    <PanelLayout>
      {content}
      {isPlaceholderSnapshot ? (
        <LoadingFeedback description="3D data" />
      ) : threeDSnapshot.status === "fulfilled" ? (
        threeDInteractivityIndicator
      ) : null}
    </PanelLayout>
  );
}

type CameraControlsRefTarget = React.ComponentRef<typeof CameraControls>;

// Describes the rotation from Three's X-left, Y-up, Z-forward coordinates
// to ROS' conventional X-forward, Y-left, Z-up coordinates.
// prettier-ignore
const threeToRosTransform = new Matrix4(
  0, 1, 0, 0,
  0, 0, 1, 0,
  1, 0, 0, 0,
  0, 0, 0, 1,
);
const threeToRosRotation = new Quaternion().setFromRotationMatrix(
  threeToRosTransform,
);

function Scene({ value }: { value: ThreeDRecords }) {
  const invalidate = useThree((state) => state.invalidate);

  const { showRotationControls, objects, transforms } = value;

  const controlsRef = useRef<CameraControlsRefTarget>(null);
  const groupRef = useRef<Group>(null);

  function handleDoubleClick(e: ThreeEvent<MouseEvent>): void {
    // Only want to run this handler for the first intersected point.
    e.stopPropagation();

    if (groupRef.current == null || controlsRef.current == null) {
      return;
    }

    // Calculate the translation from the clicked point (which needs to be
    // converted to the <group>'s local coordinates) to the origin and negate
    // it to give the translation that must be applied to move that point in the
    // <group> to the world's origin.
    const translation = groupRef.current.worldToLocal(e.point.clone()).negate();

    groupRef.current.position.x = translation.x;
    groupRef.current.position.y = translation.y;
    groupRef.current.position.z = translation.z;

    // Look back at the world's origin.
    controlsRef.current.setFocalOffset(0, 0, 0);

    invalidate();
  }

  const sceneHasObjects = objects.length > 0;

  return (
    <>
      <TransformControls
        mode="rotate"
        showX={showRotationControls && sceneHasObjects}
        showY={showRotationControls && sceneHasObjects}
        showZ={showRotationControls && sceneHasObjects}
      >
        <group ref={groupRef} onDoubleClick={handleDoubleClick}>
          <group quaternion={threeToRosRotation}>
            {renderObjects(objects, transforms)}
          </group>
        </group>
      </TransformControls>
      <CameraControls
        ref={controlsRef}
        makeDefault
        smoothTime={0}
        draggingSmoothTime={0}
        mouseButtons={{
          left: CameraActions.ROTATE,
          // Offset the camera instead of trucking it to keep the controls'
          // center of rotation the same regardless of how the user pans.
          right: CameraActions.OFFSET,
          middle: CameraActions.DOLLY,
          wheel: CameraActions.DOLLY,
        }}
        touches={{
          one: CameraActions.TOUCH_ROTATE,
          // Offset the camera instead of trucking it to keep the controls'
          // center of rotation the same regardless of how the user pans.
          two: CameraActions.TOUCH_DOLLY_OFFSET,
          three: CameraActions.NONE,
        }}
      />
    </>
  );
}

function renderObjects(
  objects: ReadonlyArray<ThreeDObject>,
  transforms: ReadonlyArray<TransformNode> | null,
) {
  if (transforms == null) {
    return objects.map(renderObject);
  } else {
    const renderedObjects = new Set<ThreeDObject>();

    const frames = transforms.map((node) =>
      renderFrame(node, objects, renderedObjects),
    );

    return (
      <>
        {frames}
        {objects.flatMap((object) => {
          if (renderedObjects.has(object)) {
            return [];
          } else {
            return renderObject(object);
          }
        })}
      </>
    );
  }
}

function renderObject(object: ThreeDObject) {
  return (
    <points key={object.id} geometry={object.data.data}>
      <pointsMaterial vertexColors size={object.size} />
    </points>
  );
}

function renderFrame(
  node: TransformNode,
  objects: ReadonlyArray<ThreeDObject>,
  renderedObjects: Set<ThreeDObject>,
) {
  const frameObject = objects.find(
    (object) => object.data.frameId === node.frameId,
  );

  if (frameObject != null) {
    renderedObjects.add(frameObject);
  }

  return (
    <group
      key={node.frameId}
      quaternion={[
        node.rotation.x,
        node.rotation.y,
        node.rotation.z,
        node.rotation.w,
      ]}
      position={[node.translation.x, node.translation.y, node.translation.z]}
    >
      {frameObject != null && renderObject(frameObject)}
      {node.children.map((child) =>
        renderFrame(child, objects, renderedObjects),
      )}
    </group>
  );
}
