import React from "react";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils/assertions";
import { usePlayerActions } from "../../actions";
import { SourceTopicSelect, TopicSelect } from "../../components";
import { useLoadedPlayerTopics } from "../../hooks";
import type { MapPanel } from "../../panels";
import { usePairedTopics } from "../../panels";
import { useMapSubVisualization } from "./use-map-sub-visualization";
import { filterSupplementaryTopics } from "./utils";

export function MapVisualizationControls({ panel }: { panel: MapPanel }) {
  const subVisualization = useMapSubVisualization(panel);

  switch (subVisualization) {
    case "gps": {
      return <GpsVisualizationControls panel={panel} />;
    }
    case "cartesian": {
      return <CartesianVisualizationControls panel={panel} />;
    }
    default: {
      assertNever(subVisualization);
    }
  }
}

function GpsVisualizationControls({ panel }: { panel: MapPanel }) {
  const playerActions = usePlayerActions();

  const topics = useLoadedPlayerTopics();

  const pairedTopics = usePairedTopics(panel);
  const primaryPair = pairedTopics[0];
  const comparisonPair = pairedTopics[1];

  const supplementaryTopics = filterSupplementaryTopics(
    primaryPair.topic,
    topics,
  );

  function handleSupplementaryTopicChange(newValue: Topic | null): void {
    if (newValue === null) {
      playerActions.removeSupplementaryMapTopic(panel);
    } else {
      playerActions.addSupplementaryMapTopic(panel, newValue);
    }
  }

  return (
    <>
      <SourceTopicSelect
        panel={panel}
        topic={primaryPair.topic}
        sx={{ marginBlockEnd: 2 }}
      />
      <TopicSelect
        inputLabel="Comparison topic"
        value={comparisonPair?.topic ?? null}
        topics={supplementaryTopics}
        onChange={handleSupplementaryTopicChange}
      />
    </>
  );
}

function CartesianVisualizationControls({ panel }: { panel: MapPanel }) {
  const pairedTopics = usePairedTopics(panel);
  const primaryPair = pairedTopics[0];

  return (
    <SourceTopicSelect
      panel={panel}
      topic={primaryPair.topic}
      sx={{ marginBlockEnd: 2 }}
    />
  );
}
