import { invariant } from "~/lib/invariant";

export function assertNever(value: never): never {
  throw new Error(`Value isn't of type 'never': "${value}"`);
}

export function assertNonNullish<TValue extends NonNullable<unknown>>(
  value: TValue | null | undefined,
  message = "Assertion failed: value is nullish",
): asserts value is TValue {
  invariant(value != null, message);
}

export function requireNonNullish<TValue extends NonNullable<unknown>>(
  value: TValue | null | undefined,
  message?: string,
): TValue {
  assertNonNullish(value, message);

  return value;
}
