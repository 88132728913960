import type React from "react";
import type { Control } from "react-hook-form";
import type { StrictOmit } from "ts-essentials";
import type {
  EditResourceFormProps,
  ForeignKeyFormFieldDescriptor,
  NewResourceFormProps,
} from "~/components/Form";
import { EditResourceForm, NewResourceForm } from "~/components/Form";
import { UserSelect } from "~/domain/common";
import { assertNever } from "~/utils/assertions";
import { useDsmVersion } from "./versioning";

export type DsmForeignKeyFormFieldType = "user";

interface EditDsmResourceFormProps<
  TResource extends object,
  TRequest extends object,
> extends StrictOmit<
    EditResourceFormProps<TResource, TRequest, DsmForeignKeyFormFieldType>,
    "apiVersion" | "deprecationPolicy" | "renderForeignKeyFormField"
  > {}

export function EditDsmResourceForm<
  TResource extends object,
  TRequest extends object,
>(props: EditDsmResourceFormProps<TResource, TRequest>) {
  const dsmVersion = useDsmVersion();

  return (
    <EditResourceForm
      {...props}
      apiVersion={dsmVersion}
      deprecationPolicy="hide"
      renderForeignKeyFormField={renderDsmForeignKeyFormField}
    />
  );
}

interface NewDsmResourceFormProps<
  TRequest extends object,
  TResponse extends object,
> extends StrictOmit<
    NewResourceFormProps<TRequest, TResponse, DsmForeignKeyFormFieldType>,
    "apiVersion" | "deprecationPolicy" | "renderForeignKeyFormField"
  > {}

export function NewDsmResourceForm<
  TRequest extends object,
  TResponse extends object,
>(props: NewDsmResourceFormProps<TRequest, TResponse>) {
  const dsmVersion = useDsmVersion();

  return (
    <NewResourceForm
      {...props}
      apiVersion={dsmVersion}
      deprecationPolicy="hide"
      renderForeignKeyFormField={renderDsmForeignKeyFormField}
    />
  );
}

export function renderDsmForeignKeyFormField(
  control: Control<any>,
  descriptor: ForeignKeyFormFieldDescriptor<any, DsmForeignKeyFormFieldType>,
  deprecated: boolean,
): React.JSX.Element {
  const { name, label, required } = descriptor;
  switch (descriptor.resourceType) {
    case "user": {
      return (
        <UserSelect
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    default: {
      assertNever(descriptor);
    }
  }
}
