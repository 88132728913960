import { toNanoseconds } from "~/lib/dates";

const SECONDS_DURATION_REGEXP = /^(?<s>\d{1,2})\.(?<ss>\d)$/;

const MINUTES_DURATION_REGEXP = /^(?<m>\d{1,2}):(?<s>\d{2})\.(?<ss>\d)$/;

const HOURS_DURATION_REGEXP =
  /^(?<h>\d{1,2}):(?<m>\d{2}):(?<s>\d{2})\.(?<ss>\d)$/;

const DAYS_DURATION_REGEXP =
  /^(?<d>\d):(?<h>\d{2}):(?<m>\d{2}):(?<s>\d{2})\.(?<ss>\d)$/;

export function parseDuration(input: string): bigint | null {
  let match = null;
  for (const pattern of [
    SECONDS_DURATION_REGEXP,
    MINUTES_DURATION_REGEXP,
    HOURS_DURATION_REGEXP,
    DAYS_DURATION_REGEXP,
  ]) {
    match = input.match(pattern);

    if (match != null) {
      break;
    }
  }

  if (match?.groups == null) {
    return null;
  }

  const { groups } = match;

  const days = extractGroup(groups.d, 0, 6);
  const hours = extractGroup(groups.h, 0, 23);
  const minutes = extractGroup(groups.m, 0, 59);
  const seconds = extractGroup(groups.s, 0, 59);
  const subseconds = extractGroup(groups.ss, 0, 9);

  if (
    days == null ||
    hours == null ||
    minutes == null ||
    seconds == null ||
    subseconds == null
  ) {
    return null;
  }

  return toNanoseconds({
    days,
    hours,
    minutes,
    seconds,
    milliseconds: subseconds * 100,
  });
}

function extractGroup(
  group: string | undefined,
  min: number,
  max: number,
): number | null {
  if (group == null) {
    return 0;
  }

  const parsed = Number.parseInt(group);

  if (Number.isNaN(parsed)) {
    return null;
  }

  if (!(min <= parsed && parsed <= max)) {
    return null;
  }

  return parsed;
}
