import prettyMilliseconds from "pretty-ms";
import {
  millisecondsToNanoseconds,
  nanosecondsToMilliseconds,
  secondsToNanoseconds,
} from "~/lib/dates";
import { floor } from "~/lib/std";
import type { Log, Topic } from "~/lqs";
import type { TimeRange } from "~/types";
import type { BoundedLog, BoundedTopic, TimestepValue } from "./types";
import { Timestep } from "./types";

export interface FormatTimestampOptions extends prettyMilliseconds.Options {
  precision?: number;
  relativeTo?: bigint;
}

export function formatTimestamp(
  timestamp: bigint,
  {
    precision,
    relativeTo = 0n,
    colonNotation = true,
    keepDecimalsOnWholeSeconds = true,
    ...prettyMsOptions
  }: FormatTimestampOptions = {},
): string {
  let relativeStampMs = nanosecondsToMilliseconds(timestamp - relativeTo);

  if (precision !== undefined) {
    relativeStampMs = floor(relativeStampMs, precision);
  }

  return prettyMilliseconds(relativeStampMs, {
    colonNotation,
    keepDecimalsOnWholeSeconds,
    ...prettyMsOptions,
  });
}

export function floorTimestampForTimestep(
  timestamp: bigint,
  timestep: TimestepValue,
): bigint {
  const divisor = {
    [Timestep.Second]: secondsToNanoseconds(1),
    [Timestep.HalfSecond]: millisecondsToNanoseconds(500),
    [Timestep.Decisecond]: millisecondsToNanoseconds(100),
  }[timestep];

  // We want to floor the timestamp to the most recent multiple of the
  // timestep. Since we're dealing with bigints here which implicitly do floor
  // division, dividing by the multiple which the timestep represents and
  // multiplying again gets us to the correct value.
  return (timestamp / divisor) * divisor;
}

export function logIsBounded(log: Log): log is BoundedLog {
  return log.startTime !== null && log.endTime !== null;
}

export function logIsTooLong(log: BoundedLog): boolean {
  const NS_IN_1_WEEK = secondsToNanoseconds(
    86_400 /* seconds/day */ * 7 /* days/week */,
  );

  return log.endTime - log.startTime >= NS_IN_1_WEEK;
}

export function checkIsBoundedTopic(topic: Topic): topic is BoundedTopic {
  return topic.startTime != null && topic.endTime != null;
}

export function timeRangeToDomain(timeRange: TimeRange): [number, number] {
  return [
    nanosecondsToMilliseconds(timeRange.startTime),
    nanosecondsToMilliseconds(timeRange.endTime),
  ];
}

/**
 * Calculate a panel's viewing window within the player bounds using the
 * current timestamp and a desired window size.
 *
 * A window is best understood in terms of the chart visualizations. Rather
 * than displaying a chart spanning the entire topic, the user can view a
 * "window" which shows a narrower time range based around the current
 * timestamp. The following explains the relationship between a window and the
 * topic it's derived from:
 *
 *                     +---------+
 *                     |--       |
 *          Window --> |  \      |
 *                     |   ------|
 *                     +---------+
 *      10 seconds --> [---------]
 * +--------------------------------------+
 * |  /\    --------------                |
 * | /  \  /              \               |
 * |/    \/                ---------------|
 * +--------------------------------------+
 * 0:00                     0:45          1:00
 *                         Current
 *                        timestamp
 * [--------------------------------------]
 *        Player bounds (60 seconds)
 *
 * The window spans 10 seconds and is centered around the current timestamp
 * (0:45). The window is showing a slice of the chart in the time range
 * [0:35, 0:55]. If the current timestamp were incremented to 0:46, the window
 * would then show only the slice of the chart in the range [0:36, 0:56].
 *
 * When the player is playing, the constantly-changing timestamp will give the
 * appearance of the window sliding along. However, the window will always be
 * contained entirely within the player bounds. The window essentially stops
 * "sliding" once its end time reaches the player's end time. Though the
 * timestamp can continue increasing until it too reaches the player's end time,
 * the window will remain static and, importantly, still honor the provided
 * window size. To use the example above, if the current timestamp were 0:59,
 * the calculated window would be [0:50, 1:00]; the window would no longer be
 * centered around the current timestamp as doing so would overflow the player
 * bounds.
 *
 * The only situation in which the calculated window's size is less than the
 * desired window size is if the player's duration is less than the window size.
 */
export function calculateRecordWindow(
  windowSize: bigint,
  timestamp: bigint,
  bounds: TimeRange,
): TimeRange {
  if (windowSize >= bounds.endTime - bounds.startTime) {
    // Window can only equal the player bounds
    return bounds;
  }

  const halfWindowSize = windowSize / 2n;

  if (timestamp <= bounds.startTime + halfWindowSize) {
    // Centering the window around the timestamp would cause the lower half
    // of the window to overflow the player's starting bound
    return {
      startTime: bounds.startTime,
      endTime: bounds.startTime + windowSize,
    };
  }

  if (timestamp >= bounds.endTime - halfWindowSize) {
    // Centering the window around the timestamp would cause the upper half
    // of the window to overflow the player's ending bound
    return {
      startTime: bounds.endTime - windowSize,
      endTime: bounds.endTime,
    };
  }

  // The window is centered around the current timestamp. There's no danger here
  // of either window bound overflowing the player's bounds since the conditions
  // above guarantee the difference between the timestamp and both player
  // bounds is > `halfWindowSize`
  return {
    startTime: timestamp - halfWindowSize,
    endTime: timestamp + halfWindowSize,
  };
}
