import React, { useCallback, useMemo, useState } from "react";
import { useImmerReducer } from "use-immer";
import { createSafeContext } from "~/contexts";
import { nanosecondsToSeconds, relativeToUtcNanoseconds } from "~/lib/dates";
import { invariant } from "~/lib/invariant";
import type { Maybe, TimeRange } from "~/types";
import { assertNever, bigintClamp } from "~/utils";
import { usePlayerConfig } from "../config";
import { usePlayerLog, usePlayerParams } from "../hooks";
import type { PlaybackSpeedValue, TimestepValue } from "../types";
import {
  floorTimestampForTimestep,
  formatTimestamp,
  logIsBounded,
  logIsTooLong,
} from "../utils";
import type {
  BasePlaybackSource,
  LoadedPlaybackSource,
  PlaybackSource,
} from "./playbackReducer";
import { initialState, makeReducer } from "./playbackReducer";

export type DisplayFormat = "elapsed" | "original";

export interface PlaybackSettings {
  displayFormat: DisplayFormat;
  setDisplayFormat: (displayFormat: DisplayFormat) => void;
  speed: PlaybackSpeedValue;
  setSpeed: (speed: PlaybackSpeedValue) => void;
  timestep: TimestepValue;
  setTimestep: (timestep: TimestepValue) => void;
}

export const [usePlaybackSettings, PlaybackSettingsContext] =
  createSafeContext<PlaybackSettings>("PlaybackSettings");

export interface PlaybackSettingsProviderProps {
  children: React.ReactNode;
}

export function PlaybackSettingsProvider({
  children,
}: PlaybackSettingsProviderProps) {
  const { defaultPlaybackSpeed, defaultPlaybackTimestep } = usePlayerConfig();

  const [displayFormat, setDisplayFormat] = useState<DisplayFormat>("elapsed");
  const [speed, setSpeed] = useState<PlaybackSpeedValue>(defaultPlaybackSpeed);
  const [timestep, setTimestep] = useState<TimestepValue>(
    defaultPlaybackTimestep,
  );

  const contextValue: PlaybackSettings = useMemo(
    () => ({
      displayFormat,
      setDisplayFormat,
      speed,
      setSpeed,
      timestep,
      setTimestep,
    }),
    [displayFormat, speed, timestep],
  );

  return (
    <PlaybackSettingsContext.Provider value={contextValue}>
      {children}
    </PlaybackSettingsContext.Provider>
  );
}

export const [usePlaybackSource, PlaybackSourceContext] =
  createSafeContext<PlaybackSource>("PlaybackSource");

export function useFormatPlaybackTimestamp() {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = usePlaybackSource();

  const playbackStartTime = playbackSource.bounds?.startTime;

  return useCallback(
    // If `timestamp` is a number, it's assumed to represent the number of
    // nanoseconds since the playback start time; otherwise, it's assumed
    // to represent UTC nanoseconds.
    (timestamp: bigint | number, format = playbackSettings.displayFormat) => {
      switch (format) {
        case "elapsed": {
          return formatTimestamp(BigInt(timestamp), {
            precision: 1,
            ...(typeof timestamp === "bigint" && {
              relativeTo: playbackStartTime,
            }),
          });
        }
        case "original": {
          return nanosecondsToSeconds(
            typeof timestamp === "number"
              ? relativeToUtcNanoseconds(timestamp, playbackStartTime ?? 0n)
              : timestamp,
          ).toFixed(1);
        }
        default: {
          assertNever(format);
        }
      }
    },
    [playbackSettings.displayFormat, playbackStartTime],
  );
}

export function useLoadedPlaybackSource(): LoadedPlaybackSource {
  const playbackSource = usePlaybackSource();

  invariant(!playbackSource.isLoading, "Expected playback source to be loaded");

  return playbackSource;
}

export function useCalculateFrameTimestamp() {
  const playbackSettings = usePlaybackSettings();

  return useCallback(
    (timestamp: bigint, timestep = playbackSettings.timestep) => {
      return floorTimestampForTimestep(timestamp, timestep);
    },
    [playbackSettings.timestep],
  );
}

export interface PlaybackSourceProviderProps {
  /**
   * UTC timestamps representing the upper and lower playback bounds in
   * nanoseconds. All timestamps will be clamped to within these bounds.
   * Conceptually, the lower bound can be considered t = 0 and can be used for
   * displaying UTC timestamps relative to how long after this time they
   * occurred. Set this value to undefined if it needs to be fetched
   * asynchronously. Trying to perform playback operations while this is
   * undefined will result in an error.
   */
  bounds?: TimeRange;
  /**
   * A UTC timestamp in nanoseconds representing the default time at which
   * playback should start. Useful if loading a time from an external source
   * like a URL query param. If not given the default time will be the
   * lower playback bound. Will be clamped within `bounds` before being
   * passed through the context
   */
  initialTime?: Maybe<bigint>;
  children: React.ReactNode;
}

export function PlaybackSourceProvider({
  bounds,
  initialTime,
  children,
}: PlaybackSourceProviderProps) {
  const clampedInitialTime =
    bounds !== undefined
      ? // Since initial time comes from an outside source like a URL, there's
        // no guarantee it's within playback bounds
        bigintClamp(
          initialTime ?? bounds.startTime,
          bounds.startTime,
          bounds.endTime,
        )
      : undefined;

  const playbackSettings = usePlaybackSettings();

  const [playbackSourceState, dispatch] = useImmerReducer(
    makeReducer(bounds, clampedInitialTime, playbackSettings.timestep),
    initialState,
  );

  const {
    timestamp: effectiveTimestamp = clampedInitialTime,
    range: effectiveRange = bounds,
  } = playbackSourceState;

  const isLoading = bounds === undefined || effectiveTimestamp === undefined;

  const value: BasePlaybackSource = {
    isLoading,
    bounds: isLoading ? undefined : bounds,
    mode: playbackSourceState.status === "range-mode" ? "range" : "single",
    inRangeMode: playbackSourceState.status === "range-mode",
    isPlaying: playbackSourceState.status === "playing",
    range: isLoading ? undefined : effectiveRange,
    timestamp: isLoading ? undefined : effectiveTimestamp,
    dispatch,
  };

  return (
    <PlaybackSourceContext.Provider value={value as PlaybackSource}>
      {children}
    </PlaybackSourceContext.Provider>
  );
}

export function LogPlaybackSourceProvider({
  children,
}: PlaybackSourceProviderProps) {
  const { logId, initialTime } = usePlayerParams();
  const playerLogQuery = usePlayerLog();

  let bounds: TimeRange | undefined = undefined;
  if (
    playerLogQuery.isSuccess &&
    logIsBounded(playerLogQuery.data) &&
    // Treat a log that's too long like a log with no bounds so downstream
    // context consumers won't be able to use the bounds
    !logIsTooLong(playerLogQuery.data)
  ) {
    bounds = {
      startTime: playerLogQuery.data.startTime,
      endTime: playerLogQuery.data.endTime,
    };
  }

  return (
    <PlaybackSourceProvider
      key={logId}
      bounds={bounds}
      initialTime={initialTime}
    >
      {children}
    </PlaybackSourceProvider>
  );
}
