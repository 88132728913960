import * as z from "zod";
import { v } from "~/domain/versioning";
import { FlipDirection, VisualizationType } from "../constants";

export const topicNameSchema = z.string();

export const topicTypeNameSchema = z.string().nullable();

export const visualizationTypeSchema = z.nativeEnum(VisualizationType);

export const imageRotationSchema = z.number();

export const imageFlipDirectionSchema = z.nativeEnum(FlipDirection);

export const layoutProfileVersion = v(0, 2, 0);
