import React from "react";
import { Button, styled, Tooltip, Typography } from "@mui/material";
import { TimerSand } from "mdi-material-ui";
import { Center } from "~/components/Center";
import { ErrorMessage } from "~/components/error-message";
import { useBlobSource } from "~/hooks";
import { usePlayerActions } from "../../actions";
import { LoadingFeedback, PanelLayout } from "../../components";
import { InferenceError, InferenceResultsVisualization } from "../../inference";
import type { ImagePanel } from "../../panels";
import {
  useCalculateFrameTimestamp,
  useFormatPlaybackTimestamp,
  useLoadedPlaybackSource,
} from "../../playback";
import { ColorMap, createColorMapFilterId } from "./color-map";
import { useImageFrames } from "./use-image-frames";
import { useImageSliderController } from "./use-image-slider-controller";
import { getTransformProps } from "./utils";

const ImageContainer = styled("div")({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  containerType: "size",
  "& [data-content]": {
    display: "block",
    width: "100cqw",
    height: "100cqh",
    position: "absolute",
    top: "50%",
    left: "50%",
    '&[data-sideways="true"]': {
      // `object-fit: contain` is applied prior to transformations, so rotating
      // an image sideways doesn't give the desired effect. Consequently, when
      // the image is rotated, it should be sized according to the container's
      // swapped dimensions (i.e. its height is the container's width) to cause
      // the browser to apply `object-fit: contain` according to the dimensions
      // it'll have when it's rotated. Thankfully container queries and the new
      // container query units exist or a resize observer would be necessary.
      width: "100cqh",
      height: "100cqw",
    },
  },
  "& img[data-content]": {
    objectFit: "contain",
  },
});

export function ImageVisualization({ panel }: { panel: ImagePanel }) {
  const [imageFramesSnapshot, isPlaceholderSnapshot] = useImageFrames({
    panel,
  });

  const playbackSource = useLoadedPlaybackSource();

  const playerActions = usePlayerActions();

  function handleClearInferenceTopic(): void {
    playerActions.setInferenceTopic(panel, null);
  }

  const calculateFrameTimestamp = useCalculateFrameTimestamp();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();
  function formatElapsedTime(timestamp: bigint): string {
    return formatPlaybackTimestamp(
      Number(
        calculateFrameTimestamp(playbackSource.timestamp) -
          calculateFrameTimestamp(timestamp),
      ),
    );
  }

  const imageFrame = imageFramesSnapshot.value?.current;

  const imgSrcRef = useBlobSource(imageFrame?.data);

  const imageSliderController = useImageSliderController({
    panel,
    imageFrame,
  });

  let rootContent;
  if (imageFramesSnapshot.status === "pending") {
    rootContent = <LoadingFeedback description="images" />;
  } else if (imageFramesSnapshot.status === "rejected") {
    if (imageFramesSnapshot.reason instanceof InferenceError) {
      rootContent = (
        <ErrorMessage disableTypography>
          <ErrorMessage.Paragraph>
            Couldn't get inference topic results
          </ErrorMessage.Paragraph>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleClearInferenceTopic}
          >
            Clear Inference Topic
          </Button>
        </ErrorMessage>
      );
    } else {
      rootContent = (
        <ErrorMessage>An error occurred. Unable to get images</ErrorMessage>
      );
    }
  } else {
    const {
      value: { current: imageFrame, nextTimestamp, config: imageConfig },
    } = imageFramesSnapshot;

    let imageContent;
    if (imageFrame === null) {
      const showFirstRecordButton = nextTimestamp !== null;

      imageContent = (
        <Center>
          <TimerSand fontSize="large" />
          <Typography variant="h5" component="p" paragraph>
            No recent image
          </Typography>
          {showFirstRecordButton && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() =>
                playerActions.seek(calculateFrameTimestamp(nextTimestamp))
              }
            >
              Skip to First Image
            </Button>
          )}
        </Center>
      );
    } else {
      const filters = new Array<string>();
      filters.push(
        `brightness(${imageConfig.brightnessPct})`,
        `contrast(${imageConfig.contrastPct})`,
      );
      if (imageConfig.colorize) {
        filters.push(`url(#${createColorMapFilterId(panel.id)})`);
      }

      const renderColorMap =
        imageConfig.colorize ||
        imageFrame?.inferenceFrames?.config.colorize === true;

      imageContent = (
        <>
          <img
            id={imageSliderController.baseImageId}
            // When listening for pointer events for the image slider feature,
            // don't let this image be dragged
            draggable={imageSliderController.enabled ? "false" : "true"}
            ref={imgSrcRef}
            {...getTransformProps({
              rotationDeg: imageConfig.rotationDeg,
              flipDirection: imageConfig.flip,
              filter: filters.join(" "),
            })}
          />
          {renderColorMap && <ColorMap panelId={panel.id} />}
          <InferenceResultsVisualization
            panel={panel}
            imageFrame={imageFrame}
          />
          {imageFrame?.isStale && (
            <Tooltip
              title={`No new image for ${formatElapsedTime(
                imageFrame.timestamp,
              )}`}
              placement="left"
            >
              <TimerSand
                sx={{
                  position: "absolute",
                  top: (theme) => theme.spacing(1),
                  right: (theme) => theme.spacing(1),
                }}
              />
            </Tooltip>
          )}
          {isPlaceholderSnapshot && <LoadingFeedback description="images" />}
        </>
      );
    }

    rootContent = (
      <ImageContainer {...imageSliderController.pointerContainerProps}>
        {imageContent}
      </ImageContainer>
    );
  }

  return <PanelLayout>{rootContent}</PanelLayout>;
}
