import type { StrictOmit } from "ts-essentials";
import * as z from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import {
  boolean,
  commonResourceFilterSchemas,
  filterBigInt,
  filterBoolean,
  filterNumber,
  filterText,
  filterUuid,
  optionalBigInt,
  optionalNumber,
  optionalObject,
  optionalText,
  optionalUuid,
  requiredBigInt,
  requiredText,
  requiredUuid,
} from "~/domain/common";
import type {
  ListRecordsRequest,
  ListTopicsRequest,
  RecordCreateRequest,
  RecordUpdateRequest,
  TopicCreateRequest,
  TopicUpdateRequest,
} from "~/lqs";
import { filterTypeEncoding, optionalTypeEncoding } from "~/lqs";

export const listTopicsSchema = z.object({
  id: filterUuid,
  logId: filterUuid,
  associatedTopicId: filterUuid,
  locked: filterBoolean,
  strict: filterBoolean,
  latched: filterBoolean,
  name: filterText,
  nameLike: filterText,
  startTimeGte: filterBigInt,
  startTimeLte: filterBigInt,
  startTimeNull: filterBoolean,
  endTimeGte: filterBigInt,
  endTimeLte: filterBigInt,
  endTimeNull: filterBoolean,
  recordSizeGte: filterNumber,
  recordSizeLte: filterNumber,
  recordCountGte: filterNumber,
  recordCountLte: filterNumber,
  typeName: filterText,
  typeNameLike: filterText,
  typeEncoding: filterTypeEncoding,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<
    ListTopicsRequest,
    | "contextFilter"
    | "typeData"
    | "typeDataLike"
    | "typeSchemaFilter"
    | "groupId"
    | "includeCount"
  >
>);

export const createTopicSchema = z.object({
  name: requiredText,
  logId: requiredUuid,
  associatedTopicId: optionalUuid,
  baseTimestamp: optionalBigInt,
  strict: boolean,
  typeName: optionalText,
  typeEncoding: optionalTypeEncoding,
  typeData: optionalText,
  typeSchema: optionalObject,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<TopicCreateRequest>);

export const editTopicSchema = z.object({
  name: requiredText,
  associatedTopicId: optionalUuid,
  baseTimestamp: optionalBigInt,
  strict: boolean,
  typeName: optionalText,
  typeEncoding: optionalTypeEncoding,
  typeData: optionalText,
  typeSchema: optionalObject,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<TopicUpdateRequest>);

export const listRecordsSchema = z.object({
  timestamp: filterBigInt,
  timestampGte: filterBigInt,
  timestampLte: filterBigInt,
  ingestionId: filterUuid,
  workflowId: filterUuid,
  workflowIdNull: filterBoolean,
  source: filterText,
  altered: filterBoolean,
  dataLengthGte: filterNumber,
  dataLengthLte: filterNumber,
  dataOffsetGte: filterNumber,
  dataOffsetLte: filterNumber,
  chunkCompression: filterText,
  chunkOffsetGte: filterNumber,
  chunkOffsetLte: filterNumber,
  chunkLengthGte: filterNumber,
  chunkLengthLte: filterNumber,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<
    ListRecordsRequest,
    | "topicId"
    | "logId"
    | "errorFilter"
    | "noteLike"
    | "queryDataFilter"
    | "includeAuxiliaryData"
    | "includeRawData"
    | "contextFilter"
    | "frequency"
    | "timestampGt"
    | "timestampLt"
    | "includeCount"
  >
>);

export const createRecordSchema = z.object({
  timestamp: requiredBigInt,
  dataOffset: optionalNumber,
  dataLength: optionalNumber,
  chunkCompression: optionalText,
  chunkOffset: optionalNumber,
  chunkLength: optionalNumber,
  source: optionalText,
  queryData: optionalObject,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<StrictOmit<RecordCreateRequest, "auxiliaryData">>);

export const editRecordSchema = z.object({
  queryData: optionalObject,
  error: optionalObject,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<StrictOmit<RecordUpdateRequest, "auxiliaryData">>);
