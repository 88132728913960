import React from "react";
import { Clear, KeyboardArrowRight } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionSummary,
  accordionSummaryClasses,
  Backdrop,
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { JsonTree } from "~/components/JsonTree";
import { ErrorMessage } from "~/components/error-message";
import { assertNever } from "~/utils/assertions";
import { usePlayerActions } from "../../actions";
import { SourceTopicSelect } from "../../components";
import type {
  ChartPanel,
  ChartTopicDescriptor,
  PairedTopic,
} from "../../panels";
import { constraints, usePairedTopics } from "../../panels";
import lookupFieldUnit from "./lookup-field-unit";
import { useSelectionRecord } from "./use-selection-record";
import { getFieldStroke } from "./utils";

export function ChartVisualizationControls({ panel }: { panel: ChartPanel }) {
  const playerActions = usePlayerActions();

  const [primaryPair] = usePairedTopics(panel);

  function handleToggleOverviewChart(
    e: React.ChangeEvent<HTMLInputElement>,
  ): void {
    playerActions.toggleOverviewChart(panel, e.target.checked);
  }

  return (
    <>
      <FormControlLabel
        sx={{
          justifyContent: "space-between",
          marginInlineStart: 0,
          marginBlockEnd: 2,
        }}
        control={
          <Switch
            checked={panel.showOverview}
            onChange={handleToggleOverviewChart}
          />
        }
        label="Show overview chart"
        labelPlacement="start"
      />
      <Typography variant="h6" component="p">
        Fields
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: "text.secondary", marginBlockEnd: 3 }}
      >
        {primaryPair.descriptor.fields.length} / {constraints.chartFields.max}{" "}
        selected
      </Typography>
      <ChartSourceControls
        key={primaryPair.topic.id}
        panel={panel}
        pair={primaryPair}
      />
    </>
  );
}

function ChartSourceControls({
  panel,
  pair,
}: {
  panel: ChartPanel;
  pair: PairedTopic<ChartTopicDescriptor>;
}) {
  const playerActions = usePlayerActions();

  const [selectionRecordSnapshot, isPlaceholderSnapshot] = useSelectionRecord({
    pair,
  });

  const { descriptor, topic } = pair;

  function createFieldRemovalHandler(field: string) {
    return function handleRemoveField(): void {
      playerActions.removeChartField(panel, field);
    };
  }

  function handleToggleFieldControls(): void {
    playerActions.toggleFieldControls(panel, descriptor.name);
  }

  let recordTree;
  if (descriptor.showFields) {
    switch (selectionRecordSnapshot.status) {
      case "pending": {
        recordTree = (
          <>
            <Typography>Loading most recent record</Typography>
            <LinearProgress />
          </>
        );

        break;
      }
      case "rejected": {
        recordTree = (
          <ErrorMessage>Unable to load most recent record</ErrorMessage>
        );

        break;
      }
      case "fulfilled": {
        const { value: chartSelection } = selectionRecordSnapshot;

        function handleAddField(field: string): void {
          playerActions.addChartField(panel, field, chartSelection.data);
        }

        function checkIsItemDisabled(
          _: unknown,
          value: unknown,
          path: string,
        ): boolean {
          return (
            typeof value !== "number" || chartSelection.fields.includes(path)
          );
        }

        recordTree = (
          <Box sx={{ position: "relative" }}>
            <JsonTree
              src={chartSelection.data}
              onSelect={handleAddField}
              itemDisabled={
                chartSelection.fields.length === constraints.chartFields.max
                  ? true
                  : checkIsItemDisabled
              }
            />
            {isPlaceholderSnapshot && (
              <Backdrop
                open
                sx={{
                  position: "absolute",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <Stack
                  sx={{
                    alignItems: "center",
                    marginBlockStart: 10,
                    padding: 1,
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <CircularProgress />
                  <Typography variant="h5" component="p">
                    Loading most recent record
                  </Typography>
                </Stack>
              </Backdrop>
            )}
          </Box>
        );

        break;
      }
      default: {
        assertNever(selectionRecordSnapshot);
      }
    }
  }

  return (
    <>
      <SourceTopicSelect panel={panel} topic={pair.topic} />
      <List>
        {descriptor.fields.length === 0 ? (
          <ListItem disablePadding>
            <ListItemText
              primary="No fields selected"
              primaryTypographyProps={{ fontStyle: "italic" }}
            />
          </ListItem>
        ) : (
          descriptor.fields.map((field, index) => {
            const unit = lookupFieldUnit(topic.typeName, field);

            return (
              <ListItem key={field} disablePadding>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography>
                      {field}
                      {unit != null && ` (${unit})`}
                    </Typography>
                  }
                  secondary={
                    <Box
                      sx={{
                        border: "4px solid",
                        borderColor: getFieldStroke(index),
                        borderRadius: 1,
                        width: 75,
                      }}
                    />
                  }
                />
                <ListItemSecondaryAction>
                  <Tooltip title="Remove">
                    <IconButton onClick={createFieldRemovalHandler(field)}>
                      <Clear color="error" />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        )}
      </List>
      <Accordion
        sx={{
          backgroundColor: "transparent",
          "&::before": {
            display: "none",
          },
          [`& .${accordionSummaryClasses.root}`]: {
            padding: 0,
          },
          [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
            {
              transform: "rotate(90deg)",
            },
          [`& .${accordionDetailsClasses.root}`]: {
            padding: 0,
          },
        }}
        elevation={0}
        disableGutters
        expanded={descriptor.showFields}
        onChange={handleToggleFieldControls}
      >
        <AccordionSummary expandIcon={<KeyboardArrowRight />}>
          <Typography>Topic Fields</Typography>
        </AccordionSummary>
        <AccordionDetails>{recordTree}</AccordionDetails>
      </Accordion>
    </>
  );
}
