import React from "react";
import { assertNever } from "~/utils";
import type { ImagePanel } from "../panels";
import type { ImageFrame } from "../visualizations/image-visualization/types";
import type { DetectionInferenceResults } from "./detections";
import { DetectionResultsVisualization } from "./detections";
import type { ImageInferenceResults } from "./images";
import { ImageResultsVisualization } from "./images";
import { ImageSegmentationsResultsVisualization } from "./segmentations";

export type InferenceResults =
  | DetectionInferenceResults
  | ImageInferenceResults;

export function InferenceResultsVisualization({
  panel,
  imageFrame,
}: {
  panel: ImagePanel;
  imageFrame: ImageFrame;
}) {
  const { inferenceFrames } = imageFrame;

  if (inferenceFrames?.current == null) {
    return null;
  }

  switch (inferenceFrames.type) {
    case "object-detection":
    case "zero-shot-object-detection": {
      return (
        <DetectionResultsVisualization
          config={inferenceFrames.config}
          results={inferenceFrames.current}
        />
      );
    }
    case "image-segmentation": {
      return (
        <ImageSegmentationsResultsVisualization
          config={inferenceFrames.config}
          results={inferenceFrames.current}
        />
      );
    }
    case "depth-estimation": {
      return (
        <ImageResultsVisualization
          panelId={panel.id}
          config={inferenceFrames.config}
          results={inferenceFrames.current}
        />
      );
    }
    default: {
      assertNever(inferenceFrames);
    }
  }
}
