import { NewLqsResourceForm, typeEncodingOptions, useCreateTopic } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createTopicSchema } from "../schemas";

export function TopicCreateForm() {
  const lqsNavigator = useLqsNavigator({ toTopicDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="topic"
      registryKey="topic"
      schema={createTopicSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        {
          name: "logId",
          type: "foreign-key",
          resourceType: "log",
          required: true,
        },
        {
          name: "associatedTopicId",
          type: "foreign-key",
          resourceType: "topic",
        },
        { name: "baseTimestamp", type: "timestamp" },
        { name: "strict", type: "boolean" },
        { name: "typeName", type: "text" },
        { name: "typeEncoding", type: "select", options: typeEncodingOptions },
        { name: "typeData", type: "text", multiline: true, monospace: true },
        { name: "typeSchema", type: "json" },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateTopic()}
      createDetailsLocation={(response) =>
        lqsNavigator.toTopicDetails({ topicId: response.data.id })
      }
    />
  );
}
