import { useLayoutEffect } from "react";
import type { Maybe } from "~/types";
import { usePlayerActions } from "../actions";
import type { InitializedPanel } from "../panels";

export function useSkipToFirstTimestamp(
  panel: InitializedPanel,
  firstTimestamp: Maybe<bigint>,
  isSuccessful: boolean,
): void {
  const { autoSkipToFirstTimestamp } = usePlayerActions();

  useLayoutEffect(() => {
    if (!isSuccessful) {
      return;
    }

    if (firstTimestamp == null) {
      return;
    }

    autoSkipToFirstTimestamp(panel, firstTimestamp);
  }, [isSuccessful, firstTimestamp, autoSkipToFirstTimestamp, panel]);
}
